import styled from 'styled-components';

import theme from '../../helpers/theme';

const ModalForm = styled.div`
	width: 600px;

	/*input[type=text], input[type=number], input[type=date], select {
		width: 100%;
		padding: 10px 10px;
		display: inline-block;
		border: 1px solid #ccc;
		border-radius: 4px;
		box-sizing: border-box;
		font-family: Proxima Nova;
	}*/

  header {
    padding:1rem 2rem 1rem 1rem;
    /*background-color: ${theme.color.bg};*/
    color: ${theme.color.headings};
		border-bottom: 1px solid ${theme.color.border};
		h2{
		text-transform: uppercase;
		margin:0;
		font-weight:600
		}
  }

  section {
    padding: 1rem 1.2rem;
    label{
    font-weight: 600;
    }
  }

  footer {
		padding:1rem 2rem 1rem 1rem;
		border-top: 1px solid ${theme.color.border};
  }
  
`;

export default ModalForm;
