import styled from 'styled-components';

import theme from '../../helpers/theme';

const Panel = styled.div`
	line-height: 1.3em;
	color: ${theme.color.text};
	img.avatar {
		height: 140px;
		width: 140px;
	}
	.panel-heading {
		padding: 1rem 0 1.3rem 0;
		border-bottom: 1px #ccc solid;

		.btn {
			padding: 0.1rem 0.4rem 0.1rem 0.3rem;
			line-height: 1.5rem;
			&:hover {
				cursor: pointer;
				padding: 0.1rem 0.4rem 0.1rem 0.3rem;
			}
			svg {
				height: 20px;
				width: 20px;
				margin-right: 5px;
				margin-top: -3px;
			}
		}
	}
	.panel-body {
		padding: 1.5rem 0 1rem;
	}
	h2 {
		margin: ${props => (props.fluid ? 0 : '1.5em 0')};
		color: ${theme.color.headings};
		text-transform: uppercase;
		font-weight: bold;
	}
	h4 {
		margin: 0 0 1rem;
		font-weight: 600;
	}
	p {
		font-weight: 400;
		font-size: 0.9em;
		margin: 0;
	}
	a {
		color: ${theme.color.info};
	}

	border-top: ${props =>
		props.separated ? `1px dashed ${theme.color.muted}` : 'none'};
	padding-bottom: ${props => (props.separated ? '1.5em' : '')};
`;

export default Panel;
