import React from 'react';
import posed from 'react-pose';
import { get, toLower } from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// react icons
import FaDashboard from 'react-icons/lib/fa/dashboard';
// import FaCalculator from 'react-icons/lib/fa/calculator';
import FaDatabase from 'react-icons/lib/fa/database';
// import FaTicket from 'react-icons/lib/fa/ticket';
import FaFilePdfO from 'react-icons/lib/fa/file-pdf-o';
import FaFileText from 'react-icons/lib/fa/file-text';
import FaLock  from 'react-icons/lib/fa/lock';
// import FaSignOut from 'react-icons/lib/fa/sign-out';
// import FaSearch from 'react-icons/lib/fa/search';

// styled components
import NavContainer from './styled/NavContainer';
import NavListItem from './styled/NavListItem';
// import Column from './styled/Column';

// Auth
// import Auth from '../services/Auth';
// const auth = new Auth();
const Box = posed.div();

const Navbar = ({
	active,
	subReport,
	setSubReport,
	workspace,
	cwt_role,
	authorization,
	customerDomain,
}) => {
	const domain =
		get(authorization, 'authorization_response_success.domain', []).find(
			auth => auth.customer_domain === customerDomain,
		) || {};

	return (
		<NavContainer>
			{cwt_role === 'cwt' ? (
				<Link to="/upload" onClick={() => setSubReport([])}>
					<NavListItem active={active === '/upload'}>
						<FaFileText />
						&nbsp;&nbsp;Upload
					</NavListItem>
				</Link>
			) : (
				<>
					<Link to="/" onClick={() => setSubReport([])}>
						<NavListItem active={active === '/'}>
							<FaDashboard />
							&nbsp;&nbsp;Dashboard
						</NavListItem>
					</Link>
					<Link to="/reporting" onClick={() => setSubReport([])}>
						<NavListItem active={active === '/reporting'}>
							<FaFileText />
							&nbsp;&nbsp;Reporting
						</NavListItem>
					</Link>
					<Link to="/upload" onClick={() => setSubReport([])}>
						<NavListItem active={active === '/upload'}>
							<FaFileText />
							&nbsp;&nbsp;Upload
						</NavListItem>
					</Link>
					{toLower(domain.type) !== 'tmc' && (
						<Link to="/files" onClick={() => setSubReport([])}>
							<NavListItem active={active === '/files'}>
								<FaFileText />
								&nbsp;&nbsp;Invoices
							</NavListItem>
						</Link>
					)}
					{/* <Link to="/payments" onClick={() => setSubReport([])}>
						<NavListItem active={active === '/payments'}>
							<FaFileText />
							&nbsp;&nbsp;Subscription
						</NavListItem>
					</Link> */}
					<Link to="/creds" onClick={() => setSubReport([])}>
						<NavListItem active={active === '/creds'}>
							<FaLock />
							&nbsp;&nbsp;IRN
						</NavListItem>
					</Link>
					{!Array.isArray(subReport) && (
						<NavListItem active>
							<div style={{ paddingLeft: 25 }}>{subReport}</div>
						</NavListItem>
					)}
				</>
			)}
		</NavContainer>
	);
};

export default connect(({ authorization, customerDomain }) => ({
	authorization,
	customerDomain,
}))(Navbar);
