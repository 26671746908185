import React from 'react';

import SectionContainer from '../containers/SectionContainer';
import StepContainer from '../containers/StepContainer';

import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';

const Section2 = ({
	stepsPending,
	estimatedTime,
	progress,
	isActive,
	isComplete,
	onSectionClick,
	stepStates,
	toggleStepDisplay,
	onStepCompletion,
	onStepSkip,
	...rest
}) => {
	return (
		<SectionContainer
			iconType="cloud-upload"
			sectionIndex={1}
			sectionTitle="Upload Data"
			stepsPending={stepsPending}
			estimatedTime={estimatedTime}
			progress={progress}
			isActive={isActive}
			isComplete={isComplete}
			onSectionClick={onSectionClick}
		>
			<StepContainer
				stepTitle="Booking Data"
				stepCounter="1"
				stepState={stepStates[0]}
				toggleStepDisplay={toggleStepDisplay(1, 0)}
				isChildActive={stepStates
					.map(state => state.isActive)
					.some(value => value === true)}
			>
				<Step2
					isInOnboarding={true}
					stepState={stepStates[0]}
					onStepCompletion={onStepCompletion(1, 0)}
					onStepSkip={onStepSkip(1, 0)}
					{...rest}
				/>
			</StepContainer>
			<StepContainer
				stepTitle="GSTR2A"
				stepCounter="2"
				stepState={stepStates[1]}
				toggleStepDisplay={toggleStepDisplay(1, 1)}
				isChildActive={stepStates
					.map(state => state.isActive)
					.some(value => value === true)}
			>
				<Step3
					isInOnboarding={true}
					stepState={stepStates[1]}
					onStepCompletion={onStepCompletion(1, 1)}
					onStepSkip={onStepSkip(1, 1)}
					{...rest}
				/>
			</StepContainer>
			<StepContainer
				stepTitle="Claimed Credit"
				stepCounter="3"
				stepState={stepStates[2]}
				toggleStepDisplay={toggleStepDisplay(1, 2)}
				isChildActive={stepStates
					.map(state => state.isActive)
					.some(value => value === true)}
			>
				<Step4
					isInOnboarding={true}
					stepState={stepStates[2]}
					onStepCompletion={onStepCompletion(1, 2)}
					onStepSkip={onStepSkip(1, 2)}
					{...rest}
				/>
			</StepContainer>
			<StepContainer
				stepTitle="PST Invoices"
				stepCounter="4"
				stepState={stepStates[3]}
				toggleStepDisplay={toggleStepDisplay(1, 3)}
				isChildActive={stepStates
					.map(state => state.isActive)
					.some(value => value === true)}
			>
				<Step5
					isInOnboarding={true}
					stepState={stepStates[3]}
					onStepCompletion={onStepCompletion(1, 3)}
					onStepSkip={onStepSkip(1, 3)}
					{...rest}
				/>
			</StepContainer>
			<StepContainer
				stepTitle="SSR Update"
				stepCounter="5"
				stepState={stepStates[4]}
				toggleStepDisplay={toggleStepDisplay(1, 4)}
				isChildActive={stepStates
					.map(state => state.isActive)
					.some(value => value === true)}
			>
				<Step6
					isInOnboarding={true}
					stepState={stepStates[4]}
					onStepCompletion={onStepCompletion(1, 4)}
					onStepSkip={onStepSkip(1, 4)}
					{...rest}
				/>
			</StepContainer>
		</SectionContainer>
	);
};

export default Section2;
