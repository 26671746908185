import { SET_CUSTOMER_DOMAIN } from '../actions';

export default function setCustomerDomain(
	state = window.localStorage.getItem('customer_domain'),
	action,
) {
	switch (action.type) {
		case SET_CUSTOMER_DOMAIN:
			return action.domain;
		default:
			return state;
	}
}
