
export const AppStyle = {
    FULL_CONTAINER    :  (params) => { return { minHeight : window.innerHeight + params  };},
    PADDING           :  (params) => { return { padding : params };},
    MARGIN            :  (params) => { return { margin : params }; },
    BCOLOR            :  (params) => { return { backgroundColor : params}; },
    CALCULATED_HEIGHT  :  (widthPercentage,margin) => {
        return { height : ((window.innerHeight * widthPercentage)/100) - margin} ;
    },
    CALCULATED_WIDTH  :  (widthPercentage,margin) => {
        return { width : ((window.innerWidth * widthPercentage)/100) - margin} ;
    },
    WINDOW_WIDTH      :  () => window.innerWidth,
    WINDOW_HEIGHT     :  () => window.innerHeight,
    WIDTH_VALUE       :  (currentWidth,widthPercentage) => ((currentWidth * widthPercentage)/100),
    HEIGHT_VALUE      :  (currentHeight,heightPercentage) => ((currentHeight * heightPercentage)/100),
    PARENT_CENTER     :  () => { return {position:'absolute',top : 0, left: 0, right: 0,bottom: 0,margin: 'auto'}},
    GRADIENT          :  (params) => { return {background: (params)}},
};

export const CSSClass = {
    APP_DEFAULT : 'app_bg',
    YELLO_OPAC  : 'color-yellow-opac-35',
    GREEN_OPAC  : 'color-green-opac-35',
    BLUE_OPAC   : 'color-blue-opac-35'
};
