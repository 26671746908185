import React, { Component } from 'react';
import axios from 'axios';

import { Row, Col, Input, Radio, Divider, Checkbox, message, Form } from 'antd';

import PropTypes from 'prop-types';

import { PlaceholderTextBlock, StepFooter } from '../common';

import Api from '../../../helpers/api';

const api = Api();

const AirIndiaCredsInputGroup = ({ getFieldDecorator, creds }) => {
	return (
		<div style={{ paddingTop: '30px', textAlign: 'left' }}>
			<Divider>Air India Credentials</Divider>
			<div
				style={{
					padding: '20px 50px 20px 50px',
				}}
			>
				<Form.Item
					hasFeedback
					label="Username"
					style={{ marginBottom: 0 }}
				>
					{getFieldDecorator('username', {
						initialValue: creds.username,
						rules: [
							{
								type: 'string',
								message: 'Please enter a valid username',
							},
							{
								required: true,
								message: 'Please enter a username',
							},
							{
								whitespace: true,
								message: 'Please enter a valid username',
							},
						],
					})(
						<Row
							type="flex"
							justify="start"
							align="middle"
							style={{ padding: '10px 0' }}
						>
							<Col span={10}>
								<Input
									size="large"
									placeholder={'Air India Username'}
									name="username"
									id="username"
								/>
							</Col>
						</Row>,
					)}
				</Form.Item>
				<Form.Item
					hasFeedback
					label="Password"
					style={{ marginBottom: 0 }}
				>
					{getFieldDecorator('password', {
						initialValue: creds.password,
						rules: [
							{
								type: 'string',
								message: 'Please enter a valid password',
							},
							{
								required: true,
								message: 'Please enter a password',
							},
							{
								whitespace: true,
								message: 'Please enter a valid password',
							},
						],
					})(
						<Row
							type="flex"
							justify="start"
							align="middle"
							style={{ padding: '10px 0' }}
						>
							<Col span={10}>
								<Input
									size="large"
									placeholder={'Air India Password'}
									name="password"
									id="password"
								/>
							</Col>
						</Row>,
					)}
				</Form.Item>
			</div>
		</div>
	);
};

const AuthorizationGroup = ({ getFieldDecorator, authorizationReceived }) => {
	return (
		<Form.Item style={{ padding: '50px 50px 20px 50px' }}>
			{getFieldDecorator('authorizationGroup', {
				valuePropName: 'checked',
				initialValue: authorizationReceived,
			})(
				<React.Fragment>
					<Checkbox
						defaultChecked={authorizationReceived}
						style={{ padding: '30px 0 50px 0' }}
					>
						<b>
							I authorize GSTInput to create an Air India login on
							my behalf.
						</b>
					</Checkbox>
					<Divider>Disclaimer</Divider>
					<PlaceholderTextBlock>
						You will be authorizing Gstinput to create an Air India
						login on your behalf.
					</PlaceholderTextBlock>
				</React.Fragment>,
			)}
		</Form.Item>
	);
};

class Step9 extends Component {
	constructor(props) {
		super(props);

		const { isInOnboarding = false, stepState = {} } = this.props;

		this.state = {
			isInOnboarding,
			isComplete: isInOnboarding ? stepState.isComplete : false,
			isSkipped: isInOnboarding ? stepState.isSkipped : false,
			isSkippable: isInOnboarding ? stepState.isSkippable : false,
			submittingForm: false,
			selectedRadioValue: '',
			airIndiaLoginCreds: {
				username: '',
				password: '',
			},
			clientAuthorizationReceived: false,
			isDataFetched: false,
		};
	}

	componentDidMount() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;

		if (!isInOnboarding || (isInOnboarding && stepState.isActive)) {
			this.fetchAirIndiaLoginDetails('Test-ID1234')
				.then(resp => {
					if (!Object.keys(resp).length) {
						this.setState({
							isDataFetched: true,
							selectedRadioValue: '',
							airIndiaLoginCreds: {
								username: '',
								password: '',
							},
							clientAuthorizationReceived: false,
						});
					}
					this.setState({
						isDataFetched: true,
						selectedRadioValue: resp.loginPresent ? 1 : 2,
						airIndiaLoginCreds: {
							username: resp.loginCreds.username,
							password: resp.loginCreds.password,
						},
						clientAuthorizationReceived: resp.authorizationReceived,
					});
					this.props.form.validateFields();
				})
				.catch(err => console.log(err));
		}
	}

	componentDidUpdate() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;
		const { isDataFetched } = this.state;

		if (isInOnboarding && stepState.isActive && !isDataFetched) {
			this.fetchAirIndiaLoginDetails('Test-ID1234')
				.then(resp => {
					if (!Object.keys(resp).length) {
						this.setState({
							isDataFetched: true,
							selectedRadioValue: '',
							airIndiaLoginCreds: {
								username: '',
								password: '',
							},
							clientAuthorizationReceived: false,
						});
					}
					this.setState({
						isDataFetched: true,
						selectedRadioValue: resp.loginPresent ? 1 : 2,
						airIndiaLoginCreds: {
							username: resp.loginCreds.username,
							password: resp.loginCreds.password,
						},
						clientAuthorizationReceived: resp.authorizationReceived,
					});
					this.props.form.validateFields();
				})
				.catch(err => console.log(err));
		}
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.isInOnboarding && nextProps.stepState.isActive !== true) {
			return false;
		}

		return true;
	}

	fetchAirIndiaLoginDetails = async organizationId => {
		const apiUrl = `${api.api}/onboarding/air-india-login?organizationId=${organizationId}`;

		const apiResponse = await axios.get(apiUrl);

		return apiResponse.data.data;
	};

	onSubmit = e => {
		e.preventDefault();

		const { domain, workspace, email } = this.props;

		this.props.form.validateFields((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
				this.setState({
					submittingForm: true,
				});

				let airIndiaLoginDetails = {};

				switch (values.radioGroup) {
					case 1:
						airIndiaLoginDetails = {
							loginPresent: values.radioGroup === 1,
							loginCreds: {
								username: values.username,
								password: values.password,
							},
							authorizationReceived: false,
						};
						break;

					case 2:
						airIndiaLoginDetails = {
							loginPresent: this.state.selectedRadioValue === 1,
							loginCreds: {
								username: '',
								password: '',
							},
							authorizationReceived: values.authorizationGroup,
						};
						break;

					default:
						break;
				}

				const apiUrl = `${api.api}/onboarding/air-india-login`;

				axios
					.put(apiUrl, {
						organizationId: 'Test-ID1234',
						lastUpdatedEmail: email,
						customerDomain: domain,
						customerWorkspace: workspace,
						airIndiaLoginDetails,
					})
					.then(resp => {
						this.setState({
							submittingForm: false,
						});
						message.success('Data Updated Successfully!');

						if (this.state.isInOnboarding) {
							this.props.onStepCompletion();
						}
					})
					.catch(err => console.log(err));
			} else {
				this.setState({ submittingForm: false });
			}
		});
	};

	render() {
		const {
			selectedRadioValue,
			submittingForm,
			isInOnboarding,
			isSkippable,
		} = this.state;
		const { onStepSkip } = this.props;

		const { getFieldDecorator, getFieldValue } = this.props.form;

		const formItemLayout = {
			labelCol: { span: 24 },
			wrapperCol: { span: 24 },
		};

		return (
			<Form
				{...formItemLayout}
				onSubmit={this.onSubmit}
				layout="vertical"
			>
				<Form.Item>
					{getFieldDecorator('radioGroup', {
						initialValue: selectedRadioValue,
						rules: [
							{
								required: true,
								message: 'Please select',
							},
						],
					})(
						<Radio.Group name="radiogroup" size="large">
							<Radio value={1}>
								<b>We have an Existing Air India Login</b>
							</Radio>
							<Radio value={2}>
								<b>
									We do not have an Existing Air India login
								</b>
							</Radio>
						</Radio.Group>,
					)}
				</Form.Item>
				{getFieldValue('radioGroup') === 1 && (
					<AirIndiaCredsInputGroup
						getFieldDecorator={getFieldDecorator}
						creds={this.state.airIndiaLoginCreds}
					/>
				)}
				{getFieldValue('radioGroup') === 2 && (
					<AuthorizationGroup
						getFieldDecorator={getFieldDecorator}
						authorizationReceived={
							this.state.clientAuthorizationReceived
						}
					/>
				)}
				<StepFooter
					isInOnboarding={isInOnboarding}
					isSkippable={isSkippable}
					isSubmittable={true}
					isLoading={submittingForm}
					onSkip={onStepSkip}
				/>
			</Form>
		);
	}
}

Step9.propTypes = {
	isInOnboarding: PropTypes.bool,
	stepState: PropTypes.object,
	onStepSkip: PropTypes.func,
	onStepCompletion: PropTypes.func,
	domain: PropTypes.string.isRequired,
	workspace: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};

export default Form.create({ name: 'air-india-login' })(Step9);
