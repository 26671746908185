import React from 'react';
import styled from 'styled-components';

const MainContainer = styled.div`
	width: 100vw;
	min-height: 100vh;
	max-height: 100vh;

	overflow-y: scroll;

	display: flex;
	justify-content: center;
	align-items: flex-start;

	background-color: #eee;

	&::-webkit-scrollbar {
		width: 0 !important;
	}
`;

const SectionsWrapper = styled.div`
	min-width: 968px;
	width: 60vw;
	max-width: 1600px;
	margin-top: 20vh;
	margin-bottom: 20vh;
`;

const OnboardingContainer = ({ children }) => {
	return (
		<MainContainer>
			<SectionsWrapper>{children}</SectionsWrapper>
		</MainContainer>
	);
};

export default OnboardingContainer;
