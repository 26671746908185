import React, { Component } from 'react';
import axios from 'axios';

import { Form, Icon, Input, message } from 'antd';

import PropTypes from 'prop-types';

import { StepFooter } from '../common';

import Api from '../../../helpers/api';

const api = Api();

class Step8a extends Component {
	constructor(props) {
		super(props);

		const { isInOnboarding = false, stepState = {} } = this.props;

		this.state = {
			isInOnboarding,
			isComplete: isInOnboarding ? stepState.isComplete : false,
			isSkipped: isInOnboarding ? stepState.isSkipped : false,
			isSkippable: isInOnboarding ? stepState.isSkippable : false,
			submittingForm: false,
			emailToSendMailsFrom: '',
			isDataFetched: false,
		};
	}

	componentDidMount() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;

		if (!isInOnboarding || (isInOnboarding && stepState.isActive)) {
			this.fetchAirlineAuthorizationInfo('Test-ID1234')
				.then(resp => {
					if (Object.keys(resp).includes('emailToSendMailsFrom')) {
						this.setState({
							isDataFetched: true,
							emailToSendMailsFrom: resp.emailToSendMailsFrom,
						});
						this.props.form.validateFields();
					}
				})
				.catch(err => console.log(err));
		}
	}

	componentDidUpdate() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;
		const { isDataFetched } = this.state;

		if (isInOnboarding && stepState.isActive && !isDataFetched) {
			this.fetchAirlineAuthorizationInfo('Test-ID1234')
				.then(resp => {
					if (Object.keys(resp).includes('emailToSendMailsFrom')) {
						this.setState({
							isDataFetched: true,
							emailToSendMailsFrom: resp.emailToSendMailsFrom,
						});
						this.props.form.validateFields();
					}
				})
				.catch(err => console.log(err));
		}
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.isInOnboarding && nextProps.stepState.isActive !== true) {
			return false;
		}

		return true;
	}

	fetchAirlineAuthorizationInfo = async organizationId => {
		const apiUrl = `${api.api}/onboarding/airline-authorization?organizationId=${organizationId}`;

		const apiResponse = await axios.get(apiUrl);

		return apiResponse.data.data;
	};

	onSubmit = e => {
		e.preventDefault();

		const { domain, workspace, email } = this.props;

		this.setState({ submittingForm: true });

		const apiUrl = `${api.api}/onboarding/airline-authorization`;

		this.props.form.validateFields((err, values) => {
			if (!err) {
				console.log('Form Values:', values);
				axios
					.put(apiUrl, {
						organizationId: 'Test-ID1234',
						lastUpdatedEmail: email,
						customerDomain: domain,
						customerWorkspace: workspace,
						emailToSendMailsFrom: values.email,
					})
					.then(resp => {
						this.setState({ submittingForm: false });
						message.success('Data Successfully Updated!');

						if (this.props.isInOnboarding) {
							this.props.onStepCompletion();
						}
					})
					.catch(err => console.log(err));
			} else {
				this.setState({ submittingForm: false });
			}
		});
	};

	render() {
		const { submittingForm, isInOnboarding, isSkippable } = this.state;
		const { onStepSkip } = this.props;

		const { getFieldDecorator } = this.props.form;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 8, offset: 1 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 12, offset: 1 },
			},
		};

		return (
			<div style={{ border: '1px dashed #eee', marginBottom: '5px' }}>
				<Form
					onSubmit={this.onSubmit}
					layout="horizontal"
					{...formItemLayout}
				>
					<Form.Item label="Email Id to send mails from:" hasFeedback>
						{getFieldDecorator('email', {
							initialValue: this.state.emailToSendMailsFrom,
							rules: [
								{
									type: 'email',
									message: 'Please input a valid email id.',
								},
								{
									required: true,
									message: 'Email Id cannot be blank.',
								},
								{
									whitespace: true,
									message: 'Please input a valid email id.',
								},
							],
						})(
							<Input
								prefix={
									<Icon
										type="global"
										style={{ color: 'rgba(0,0,0,.25)' }}
									/>
								}
								placeholder="Email Id"
							/>,
						)}
					</Form.Item>
					<StepFooter
						isInOnboarding={isInOnboarding}
						isSkippable={isSkippable}
						isSubmittable={true}
						isLoading={submittingForm}
						onSkip={onStepSkip}
					/>
				</Form>
			</div>
		);
	}
}

Step8a.propTypes = {
	isInOnboarding: PropTypes.bool,
	stepState: PropTypes.object,
	onStepSkip: PropTypes.func,
	onStepCompletion: PropTypes.func,
	domain: PropTypes.string.isRequired,
	workspace: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};

export default Form.create({ name: 'airline-authorization' })(Step8a);
