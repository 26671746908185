import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { Table, Modal, Button } from 'antd';
import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.css';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';

// Rest of your component code remains unchanged

const headers = {
	'Content-Type': 'application/json',
	'ngrok-skip-browser-warning': 40,
};
const main_url = 'https://b516-3-108-63-90.ngrok-free.app/'

const CredContainer = (props) => {
	const [creds_data, setCreds_data] = useState([]);
	useEffect(() => {
		// Fetch creds_data using an API call
		fetch(main_url + 'credentials/' + props.customerDomain, {
			method: 'GET',
			headers: headers, // Pass the custom headers here
		})
			.then((response) => response.json())
			.then((data) => {
				// Check if data length is greater than 0 before setting the state
				if (data.length > 0) {
					setCreds_data(data);
				}
			})
			.catch((error) => {
				console.error('Error fetching creds_data:', error);
			});
	}, [props.customerDomain]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [csvData, setCsvData] = useState(null);
	const [csvFileInfo, setCsvFileInfo] = useState(null);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		Papa.parse(file, {
			complete: (result) => {
				const headers = result.data[0];
				const isValidFormat =
					headers.length === 3 && headers[0] === 'gstin' && headers[1] === 'username' && headers[2] === 'password';
				if (isValidFormat) {
					const parsedData = result.data.slice(1); // Exclude headers row
					const transformedData = parsedData.map((row) => {
						return {
							customer_domain: props.customerDomain,
							gstin:row[0],
							username: row[1],
							password: row[2],
						};
					});

					console.log('Transformed Data:', transformedData);
					// Now you can use transformedData for further processing or set it in your state variable
					setCsvData(transformedData);
					setCsvFileInfo({ name: file.name, size: file.size });
				} else {
					toast.error('Invalid CSV format');
				}
			},
		});
	};

	const columns = [
		{
			title: 'GSTIN',
			dataIndex: 'gstin',
			key: 'gstin',
		},
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
		},
		{
			title: 'Password',
			dataIndex: 'password',
			key: 'password',
			render: (text, record) => (
				<span>********</span>
			)
		},
	];

	const creds_columns = [
		{
			title: 'GST',
			dataIndex: 'gst',
			key: 'gst',
			render: (text, record) => {
				return (
					<span style={{fontWeight: 'bold', color: '#2d373c' }}>
						{record.gst}
					</span>
				);
			}
		},
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
			render: (text, record) => {
				return (
					<span style={{fontWeight: 'bold', color: '#2d373c' }}>
						{record.username}
					</span>
				);
			}
		},
		{
			title: 'Password',
			dataIndex: 'password',
			key: 'password',
		},
		{
			title: 'Business Name',
			dataIndex: 'business_name',
			key: 'business_name',
		},
		{
			title: 'Eligibility Date',
			dataIndex: 'eligibilitydate',
			key: 'eligibilitydate',
		},
		{
			title: 'Agg Turn-Over',
			dataIndex: 'aggturnover',
			key: 'aggturnover',
		},
		{
			title: 'Reg Type',
			dataIndex: 'regtype',
			key: 'regtype',
		},
		{
			title: 'Auth Status',
			dataIndex: 'authstatus',
			key: 'authstatus',
			render: (text, record) => {
				let icon = null;
				let statusText = '';
				switch (record.authstatus) {
					case 'ACTIVE':
						icon = <CheckCircleOutlined style={{ color: 'green' }} />;
						statusText = 'Active';
						break;
					case 'INACTIVE':
						icon = <CloseCircleOutlined style={{ color: 'red' }} />;
						statusText = 'Inactive';
						break;
					default:
						icon = null;
						statusText = 'Unknown';
						break;
				}

				return (
					<span style={{fontWeight: 'bold', color: '#2d373c' }}>
						{icon} {statusText}
					</span>
				);
			}
		},
		{
			title: 'E-invoice Status',
			dataIndex: 'einvoicestatus',
			key: 'einvoicestatus',
			render: (text, record) => {
				let icon = null;
				let statusText = '';
				switch (record.einvoicestatus) {
					case 'ACTIVE':
						icon = <CheckCircleOutlined style={{ color: 'green' }} />;
						statusText = 'Active';
						break;
					case 'INACTIVE':
						icon = <CloseCircleOutlined style={{ color: 'red' }} />;
						statusText = 'Inactive';
						break;
					default:
						icon = null;
						statusText = 'Unknown';
						break;
				}

				return (
					<span style={{fontWeight: 'bold', color: '#2d373c' }}>
						{icon} {statusText}
					</span>
				);
			}
		},
		{
			title: 'Integration Status',
			dataIndex: 'status',
			key: 'status',
			render: (text, record) => {
				let icon = null;
				let statusText = '';
				switch (record.status) {
					case 'PENDING':
						icon = <LoadingOutlined style={{ color: '#e2ac08' }} />;
						statusText = 'Pending';
						break;
					case 'ACTIVE':
						icon = <CheckCircleOutlined style={{ color: 'green' }} />;
						statusText = 'Active';
						break;
					case 'INVALID':
						icon = <CloseCircleOutlined style={{ color: 'red' }} />;
						statusText = 'Incative';
						break;
					default:
						icon = null;
						statusText = 'Unknown';
						break;
				}

				return (
					<span style={{fontWeight: 'bold', color: '#2d373c' }}>
						{icon} {statusText}
					</span>
				);
			},
		},
	];

	const handleUpdateClick = () => {
		fetch(main_url + 'credentials', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(csvData)
		})
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				throw new Error('Network response was not ok.');
			})
			.then(data => {
				setIsModalVisible(false);
				fetch(main_url + 'credentials/' + props.customerDomain, {
					method: 'GET',
					headers: headers, // Pass the custom headers here
				})
					.then((response) => response.json())
					.then((data) => {
						// Check if data length is greater than 0 before setting the state
						if (data.length > 0) {
							toast.info('Creds updated Successfully');
							setCreds_data(data);
						}
					})
					.catch((error) => {
						toast.error('Error fetching creds_data:', error);
					});
			})
			.catch(error => {
				// Handle errors here
				toast.error('Error Uploading: Dublicate Data', error);
			});
	};


	return (
		<div style={{ marginTop: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginBottom: '20px' }}>
				<Button style={{backgroundColor:'#2d373c'}} type="primary" onClick={showModal}>
					Upload Credentials
				</Button>
			</div>
			<div style={{ marginTop: '20px', width: '100%' }}>
				<Modal title="Upload CSV File" visible={isModalVisible} onCancel={handleCancel} footer={null} width={600}>
					<input type="file" name="myfile" onChange={handleFileChange} style={{ marginBottom: '20px' }} />
					{csvData && csvFileInfo && (
						<div style={{ marginBottom: '20px' }}>
							<h2>File Details:</h2>
							<p><strong>Name:</strong> {csvFileInfo.name}</p>
							<p><strong>Size:</strong> {Math.round(csvFileInfo.size / 1024)} KB</p>
							<h2>CSV Data:</h2>
							<div style={{ maxHeight: '300px', overflowY: 'auto' }}>
								<Table dataSource={csvData} columns={columns} pagination={false} />
							</div>
							<Button type="primary" style={{ marginTop: '20px' }} onClick={handleUpdateClick}>
								Update
							</Button>
						</div>
					)}
				</Modal>
				<div style={{ marginBottom: '20px' }}>
				<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#2d373c' }}> GST CREDENTIALS</h2>
					<div style={{ maxHeight: '300px', overflowY: 'auto' }}>
						<Table dataSource={creds_data} columns={creds_columns} pagination={false} />
					</div>
				</div>
			</div>
			<ToastContainer />
		</div>
	);
};

export default CredContainer;
