import React from 'react';

import SectionContainer from '../containers/SectionContainer';
import StepContainer from '../containers/StepContainer';

import Step1 from './Step1';

const Section1 = ({
	stepsPending,
	estimatedTime,
	progress,
	isActive,
	isComplete,
	onSectionClick,
	stepStates,
	toggleStepDisplay,
	onStepCompletion,
	onStepSkip,
	...rest
}) => {
	return (
		<SectionContainer
			iconType="apartment"
			sectionIndex={0}
			sectionTitle="Organization Information"
			stepsPending={stepsPending}
			estimatedTime={estimatedTime}
			progress={progress}
			isActive={isActive}
			isComplete={isComplete}
			onSectionClick={onSectionClick}
			isChildActive={stepStates
				.map(state => state.isActive)
				.some(value => value === true)}
		>
			<StepContainer
				stepTitle="Organization GSTINs"
				stepCounter="1"
				stepState={stepStates[0]}
				toggleStepDisplay={toggleStepDisplay(0, 0)}
			>
				<Step1
					isInOnboarding={true}
					stepState={stepStates[0]}
					onStepCompletion={onStepCompletion(0, 0)}
					onStepSkip={onStepSkip(0, 0)}
					{...rest}
				/>
			</StepContainer>
		</SectionContainer>
	);
};

export default Section1;
