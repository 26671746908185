import LOGO  from '../public/images/logo.png';
import SETTINGS from '../public/images/settings.png';
import COMMENTS from '../public/images/comments.png';
import ATTACHMENT from '../public/images/attachment.png';
import LOGIN_LOGO from '../public/images/login-head.png';
import LOGO_SM from '../public/images/gst-input-logo-alone.png';
import PURCHASE_HISTORY from '../public/images/purchase_history.png';

import AIRLINE_INVOICE from '../public/images/airline_invoice.png';
import HOTEL_INVOICE from '../public/images/hotel_invoice.png';
import INVOICE_AMOUNT from '../public/images/invoice_amount.png';
import INVOICE_TICKET from '../public/images/invoice_ticket.png';
import LOOKUP_DATA from '../public/images/lookup_data.png';
import OTHER_INVOICE from '../public/images/other_invoice.png';
import PAYMENTS from '../public/images/payments.png';
import R2A_REFUND from '../public/images/r2a_refund.png';
import TICKET from '../public/images/ticket.png';
import TRAVEL_AGENCY_INVOICE from '../public/images/travel_agency_invoice.png';
import FILE_UPLOADE from '../public/images/file.png';
import CLOUD_DOWNLOADE from '../public/images/cloud_download.png';
import CLOUD_DOWNLOADE_WHITE from '../public/images/cloud_download_white.png';
import EXCEL from "../public/images/excel.png";
import JSON_FILE from '../public/images/json_file.png';
import PHONE from '../public/images/phone.png';
import MESSAGE from '../public/images/message.png';
import BOOK from '../public/images/book.png';
import CHECK from '../public/images/circleCheck.png';
import LAYER from '../public/images/layer.png';
import MENU from '../public/images/menu.png';
import RECEIPT from '../public/images/receipt.png';
import NOTIFICATION from '../public/images/notification.png';
import WEB from '../public/images/web.png';

export const IMAGES_URL = {
    LOGO                           : LOGO,
    SETTINGS                       : SETTINGS,
    COMMENTS                       : COMMENTS,
    ATTACHMENT                     : ATTACHMENT,
    LOGIN_LOGO                     : LOGIN_LOGO,
    LOGO_SM                        : LOGO_SM,
    PHONE                          : PHONE,
    MESSAGE                        : MESSAGE,
    PURCHASE_HISTORY               : PURCHASE_HISTORY,
    AIRLINE_INVOICE                : AIRLINE_INVOICE,
    HOTEL_INVOICE                  : HOTEL_INVOICE,
    INVOICE_AMOUNT                 : INVOICE_AMOUNT,
    INVOICE_TICKET                 : INVOICE_TICKET,
    LOOKUP_DATA                    : LOOKUP_DATA,
    OTHER_INVOICE                  : OTHER_INVOICE,
    PAYMENTS                       : PAYMENTS,
    R2A_REFUND                     : R2A_REFUND,
    TICKET                         : TICKET,
    TRAVEL_AGENCY_INVOICE          : TRAVEL_AGENCY_INVOICE,
    FILE_UPLOADE                   : FILE_UPLOADE,
    CLOUD_DOWNLOADE                : CLOUD_DOWNLOADE,
    EXCEL                          : EXCEL,
    CLOUD_DOWNLOADE_WHITE          : CLOUD_DOWNLOADE_WHITE,
    JSON_FILE                      : JSON_FILE,
    BOOK                           : BOOK,
    CHECK                          : CHECK,
    LAYER                          : LAYER,
    MENU                           : MENU,
    NOTIFICATION                   : NOTIFICATION,
    WEB                            : WEB,
    RECEIPT                        : RECEIPT,
};
