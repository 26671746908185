import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DropzoneComponent extends Component {
	render() {
		return (
			<div>
				{this.props.imagePreview.length ? (
					<div>
						{this.props.imagePreview.map((data, key) => (
							<div key={key}>
								{data.type.split('/')[0] === 'image' ? (
									<div>
										<img
											style={{
												width: 'auto',
												height: '35vh',
											}}
											alt="img"
											src={data.preview}
										/>
									</div>
								) : (
									<div>
										<img
											alt="img"
											src={require('../assets/file_icon.png')}
										/>
										<br />
										<br />
										<h2>
											{data.name.substring(0, 6) +
												'... .' +
												data.type.split('/')[1]}
										</h2>
									</div>
								)}
							</div>
						))}
					</div>
				) : (
					<img
						alt="img"
						src={require('../assets/drop_logo.png')}
						style={{
							width: '75%',
							opacity: '0.5',
						}}
					/>
				)}
			</div>
		);
	}
}

DropzoneComponent.propTypes = {
	imagePreview: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			preview: PropTypes.any,
			name: PropTypes.string,
		}),
	).isRequired,
};

export default DropzoneComponent;
