import React from 'react';
import { Row, Col, Icon } from 'antd';

import styled, { css } from 'styled-components';

const Step = styled.div`
	border: 1px solid #fff;
	border-radius: 5px;

	transition: all 0.1s ease-in-out;

	&:hover {
		border: 1px solid dodgerblue;
	}
`;

const StepNumber = ({ stepCounter }) => {
	return (
		<div
			style={{
				width: '30px',
				height: '30px',
				borderRadius: '50%',
				border: '2px solid #555',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				color: '#555',
			}}
		>
			<div style={{ fontWeight: 'bold' }}>{stepCounter}</div>
		</div>
	);
};

const StepCompletedIndicator = () => {
	return (
		<div
			style={{
				width: '30px',
				height: '30px',
				borderRadius: '50%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				color: '#fff',
				textAlign: 'center',
				backgroundColor: '#7d7',
			}}
		>
			<Icon type="check" />
		</div>
	);
};

const StepTitle = styled.h4`
	font-size: 16px;
	font-weight: bold;

	text-align: left;
	margin: 0;

	${props =>
		props.isComplete &&
		css`
			color: #bbb;
		`}
`;

const StepHeader = ({
	stepTitle,
	stepCounter,
	estimatedTime = 0,
	isActive = false,
	isComplete = false,
	onHeaderClick,
}) => {
	return (
		<Row
			type="flex"
			justify="center"
			align="middle"
			style={{
				padding: isActive ? '30px' : '20px 30px',
				width: '100%',
				cursor: 'pointer',
				transition: 'padding 0.15s ease-in-out',
			}}
			onClick={onHeaderClick}
		>
			<Col span={2}>
				{!isComplete ? (
					<StepNumber stepCounter={stepCounter} />
				) : (
					<StepCompletedIndicator />
				)}
			</Col>
			<Col span={11}>
				<StepTitle isComplete={isComplete}>{stepTitle}</StepTitle>
			</Col>
			<Col
				span={11}
				style={{
					textAlign: 'right',
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'center',
				}}
			>
				{!isComplete && (
					<p
						style={{
							fontSize: '12px',
							display: 'inline-block',
							marginRight: '20px',
							marginBottom: '0',
						}}
					>
						About {estimatedTime} minutes
					</p>
				)}
				<Icon
					type="right"
					style={{
						color: isActive ? 'dodgerblue' : '#000',
						transition: 'all 0.25s linear',
						transform: isActive ? 'rotate(90deg)' : 'rotate(0deg)',
					}}
				/>
			</Col>
		</Row>
	);
};

const StepBody = ({ id, isActive, children }) => {
	return (
		<div key={id}>
			<Row
				type="flex"
				justify="center"
				align="middle"
				style={{
					padding: '0px',
					width: '100%',
				}}
			>
				<Col
					span={24}
					style={{
						display: isActive ? 'block' : 'none',
						padding: '20px 50px',
						maxHeight: isActive ? '100000px' : 0,
						opacity: isActive ? '100%' : 0,
						visibility: isActive ? 'visible' : 'hidden',
						transition: 'all 0.15s linear',
					}}
				>
					{children}
				</Col>
			</Row>
		</div>
	);
};

const StepContainer = ({
	stepTitle,
	stepCounter,
	stepState,
	toggleStepDisplay,
	children,
}) => {
	return (
		<Step
			style={{
				margin: '5px 0',
				boxShadow: stepState.isActive
					? '0 0px 20px rgba(0,0,0,0.19), 0 0px 6px rgba(0,0,0,0.23)'
					: 'none',
			}}
		>
			<span
				style={{
					position: 'fixed',
					left: 0,
					right: 0,
					display: 'block',
				}}
			></span>
			<StepHeader
				stepTitle={stepTitle}
				stepCounter={stepCounter}
				estimatedTime={stepState.estimatedTime}
				isActive={stepState.isActive}
				isComplete={stepState.isComplete}
				onHeaderClick={toggleStepDisplay}
			/>
			<StepBody
				id={stepTitle + stepCounter}
				isActive={stepState.isActive}
			>
				{children}
			</StepBody>
		</Step>
	);
};

export default StepContainer;
