import React, { Component } from 'react';

import { Row, Col, Button, message } from 'antd';
import Auth from '../../../services/Auth';

import PropTypes from 'prop-types';

const auth = new Auth();

class PasswordReset extends Component {
	render() {
		return (
			<Row type="flex" justify="center" align="middle">
				<Col span={12} style={{ padding: 30 }}>
					<p style={{ paddingBottom: 10, fontWeight: 'bold' }}>
						Click the button below to reset your account's password
					</p>
					<Button
						type="danger"
						size="large"
						onClick={() =>
							auth.createAccount(this.props.email, res => {
								message.success(
									'Reset Password link has been sent to your email.',
								);
							})
						}
					>
						Reset Password
					</Button>
				</Col>
			</Row>
		);
	}
}

PasswordReset.propTypes = {
	isInOnboarding: PropTypes.bool,
	stepState: PropTypes.object,
	onStepSkip: PropTypes.func,
	onStepCompletion: PropTypes.func,
	domain: PropTypes.string.isRequired,
	workspace: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};

export default PasswordReset;
