import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { Form, message } from 'antd';
import { DetailsGroup, StepFooter } from '../common';
import Api from '../../../helpers/api';

const api = Api();

class Step15 extends Component {
	constructor(props) {
		super(props);

		const { isInOnboarding = false, stepState = {} } = this.props;

		this.state = {
			isInOnboarding,
			isComplete: isInOnboarding ? stepState.isComplete : false,
			isSkipped: isInOnboarding ? stepState.isSkipped : false,
			isSkippable: isInOnboarding ? stepState.isSkippable : false,
			submittingForm: false,
			travelTeamInfo: {
				spoc: {
					firstName: '',
					lastName: '',
					designation: '',
					department: '',
					email: '',
					mobile: '',
				},
				escalation1: {
					firstName: '',
					lastName: '',
					designation: '',
					department: '',
					email: '',
					mobile: '',
				},
				escalation2: {
					firstName: '',
					lastName: '',
					designation: '',
					department: '',
					email: '',
					mobile: '',
				},
			},
			isDataFetched: false,
		};
	}

	componentDidMount() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;

		if (!isInOnboarding || (isInOnboarding && stepState.isActive)) {
			this.fetchSpocInfo('Test-ID1234')
				.then(resp => {
					if (Object.keys(resp).length) {
						this.setState({
							isDataFetched: true,
							travelTeamInfo: { ...resp },
						});
						this.props.form.validateFields();
					}
				})
				.catch(err => console.log(err));
		}
	}

	componentDidUpdate() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;
		const { isDataFetched } = this.state;

		if (isInOnboarding && stepState.isActive && !isDataFetched) {
			this.fetchSpocInfo('Test-ID1234')
				.then(resp => {
					if (Object.keys(resp).length) {
						this.setState({
							isDataFetched: true,
							travelTeamInfo: { ...resp },
						});
						this.props.form.validateFields();
					}
				})
				.catch(err => console.log(err));
		}
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.isInOnboarding && nextProps.stepState.isActive !== true) {
			return false;
		}
		return true;
	}

	fetchSpocInfo = async organizationId => {
		const apiUrl = `${api.api}/onboarding/travel-team?organizationId=${organizationId}`;
		const apiResponse = await axios.get(apiUrl);
		return apiResponse.data.data;
	};

	onSubmit = e => {
		e.preventDefault();
		const { domain, workspace, email } = this.props;

		this.setState({ submittingForm: true });

		const apiUrl = `${api.api}/onboarding/travel-team`;

		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
				axios
					.put(apiUrl, {
						organizationId: 'Test-ID1234',
						lastUpdatedEmail: email,
						customerDomain: domain,
						customerWorkspace: workspace,
						travelTeamInfo: this.state.travelTeamInfo,
					})
					.then(resp => {
						this.setState({ submittingForm: false });
						message.success('Data Successfully Updated!');

						if (this.state.isInOnboarding) {
							this.props.onStepCompletion();
						}
					})
					.catch(err => console.log(err));
			} else {
				this.setState({ submittingForm: false });
			}
		});
	};

	render() {
		const {
			submittingForm,
			isInOnboarding,
			isSkippable,
			travelTeamInfo,
		} = this.state;
		const { onStepSkip } = this.props;

		const { getFieldDecorator } = this.props.form;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
			},
			wrapperCol: {
				xs: { span: 24 },
			},
		};

		return (
			<Form
				{...formItemLayout}
				layout="vertical"
				onSubmit={this.onSubmit}
			>
				<DetailsGroup
					groupHeading="SPOC Details"
					groupType="spoc"
					details={travelTeamInfo.spoc}
					getFieldDecorator={getFieldDecorator}
				/>
				<DetailsGroup
					groupHeading="Escalation 1 Details"
					groupType="escalation1"
					details={travelTeamInfo.escalation1}
					getFieldDecorator={getFieldDecorator}
				/>
				<DetailsGroup
					groupHeading="Escalation 2 Details"
					groupType="escalation2"
					details={travelTeamInfo.escalation2}
					getFieldDecorator={getFieldDecorator}
				/>
				<StepFooter
					isInOnboarding={isInOnboarding}
					isSkippable={isSkippable}
					isSubmittable={true}
					isLoading={submittingForm}
					onSkip={onStepSkip}
				/>
			</Form>
		);
	}
}

Step15.propTypes = {
	isInOnboarding: PropTypes.bool,
	stepState: PropTypes.object,
	onStepSkip: PropTypes.func,
	onStepCompletion: PropTypes.func,
	domain: PropTypes.string.isRequired,
	workspace: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};

export default Form.create({ name: 'travel-team' })(Step15);
