import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Select } from 'antd';
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap';
import { connect } from 'react-redux';
import FaEnvelopeO from 'react-icons/lib/fa/envelope-o';

import Auth from '../services/Auth';
import {
	fetchUserInfo,
	setSubReport,
	setCWTRole,
	authorize,
	getViews,
} from '../actions';

// import logo from '../assets/gstinput.png';
import finkraftLogo from '../assets/finkraftLogo.svg';

import Navbar from '../components/Navbar?v1';

const { Option } = Select;

const auth = new Auth();

const mapStateToProps = (state, ownProps) => ({
	user: state.user,
	active: ownProps.location.pathname,
	reports: state.reports,
	workspace: state.workspace,
	cwt_role: state.cwt_role,
	authorization: state.authorization,
	views: state.views,
	customer_domain: state.customerDomain,
});

const mapDispatchToProps = dispatch => ({
	fetchUserInfo: contactId => dispatch(fetchUserInfo(contactId)),
	setSubReport: subReport => dispatch(setSubReport(subReport)),
	setCWTRole: val => dispatch(setCWTRole(val)),
	authorize: val => dispatch(authorize()),
	getViewsFunc: val => dispatch(getViews(val)),
});

class NavbarContainer extends React.Component {
	constructor(props) {
		super(props);
		this.handleMouseHover = this.handleMouseHover.bind(this);
		this.state = {
			dropdownOpen: false,
			adminDropdownOpen: false,
			customerView: '',
			isHovering: false,
		};
		// Dropdown Menu
		this.toggle = this.toggle.bind(this);
		this.adminToggle = this.adminToggle.bind(this);
		this.changeValue = this.changeValue.bind(this);
		this.updateUserData = this.updateUserData.bind(this);
	}

	handleMouseHover() {
		this.setState(this.toggleHoverState);
	}

	toggleHoverState(state) {
		return {
			isHovering: !state.isHovering,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.user &&
			Object.keys(nextProps.user) &&
			nextProps.user.contact_name !== this.props.user.contact_name
		) {
			this.updateUserData(nextProps);
		}

		if (
			this &&
			this.getMainReport &&
			nextProps.customer_domain &&
			nextProps.customer_domain !== this.props.customer_domain
		) {
			// TODO: Replace with new views call. It was used to update the views of currently logged in user.
			this.getMainReport(nextProps, nextProps.customer_domain);
		}
	}

	componentDidUpdate() {
		if (
			this.props.cwt_role === 'cwt' &&
			this.props.history.location.pathname !== '/upload'
		) {
			console.log('history: ', this.props.history);
			this.props.history.push('/upload');
		}
	}

	componentDidMount() {
		this.props.fetchUserInfo(auth.getContactId());
		this.props.authorize();
		this.updateUserData();

		this.props.setCustomerDomain(this.props.customer_domain);
	}

	updateUserData = (props = this.props) => {
		return;
		const { user } = props;
		let contact = {
			first_name: '',
			last_name: '',
			email: '',
			photo_url: '',
			mobile: '',
		};
		if (user.contact_persons) {
			contact = user.contact_persons[0];
			const setStuff = customer_domain => {
				if (!window.location.pathname.startsWith('/reporting/')) {
					props.setCustomerDomain(customer_domain);
				}
				props.setUser(contact.email);
				// TODO: Replace with new views call. It was used to update the views of currently logged in user.
				this.getMainReport(props, customer_domain);
			};
			setStuff();
		}
	};

	toggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen,
		});
	}

	menuClick() {
		this.setState({
			isMenuClicked: !this.state.isMenuClicked,
		});
	}

	adminToggle() {
		this.setState({
			adminDropdownOpen: !this.state.adminDropdownOpen,
		});
	}

	changeValue(e) {
		this.setState({
			customerView: e.currentTarget.textContent,
		});
		this.props.setCustomerDomain(e.currentTarget.textContent);
	}

	render() {
		const { user, authorization, views, customer_domain } = this.props;

		let contact = {
			first_name: '',
			last_name: '',
			email: '',
			photo_url: '',
			mobile: '',
			contact_person_id: '',
		};
		if (user.contact_persons) {
			contact = user.contact_persons[0];
		}
		var domains = [];
		if (authorization.authorization_response_success) {
			var authorizationRespSuccess =
				authorization.authorization_response_success;
			var tempDomain = authorizationRespSuccess.domain
				? authorizationRespSuccess.domain
				: null;
			tempDomain = [...new Set(tempDomain.map(p => p.customer_domain))];
			tempDomain.map((customer_domain, i) => {
				let obj = {};
				obj.text = customer_domain;
				obj.key = i;
				domains.push(obj);
				return null;
			});
		}
		return (
			<div>
				<div className={'headerTop'}>
					<div className="d-flex">
						<div className="brand mr-auto">
							{/* <Icon onClick={()=>{this.menuClick()}} style={{ display: 'flex', alignItems: 'center', fontSize: '21px', position:'relative'}} type="menu" /> */}
							<Link className="d-flex align-items-center" to="/">
								<img
									style={{
										backgroundColor: '#fff',
										padding: 5,
									}}
									// src={logo}
									src={finkraftLogo}
									alt="Avatar"
									className="img-fluid"
								/>{' '}
							</Link>
							{/* {views.views_response_success &&
								views.views_response_success.dashboard.length >
									0 && ( */}
							<Navbar
								firstname={contact.first_name}
								lastname={contact.last_name}
								email={contact.email}
								image={contact.photo_url}
								active={this.props.active}
								mobile={contact.mobile}
								subReport={this.props.reports}
								setSubReport={this.props.setSubReport}
								workspace={this.props.workspace}
								cwt_role={this.props.cwt_role}
							/>
							{/* )} */}
						</div>
						<div className="d-flex align-items-center">
							{domains.length > 0 && (
								<Select
									showSearch
									style={{ width: 200 }}
									placeholder="Select a domain"
									optionFilterProp="children"
									defaultActiveFirstOption
									value={customer_domain}
									onChange={data => {
										console.log(data);
										this.props.setCustomerDomain(data);
										this.props.getViewsFunc(data);
									}}
									filterOption={(input, option) =>
										option.props.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
								>
									{domains.map((data, i) => (
										<Option key={i} value={data.text}>
											{data.text}
										</Option>
									))}
								</Select>
							)}
						</div>
						<div className="app-notification d-flex align-items-center">
							<div className="app-notification-icon">
								<a
									// href="mailto:info@gstinput.com"
									// title="info@gstinput.com"
									href="mailto:info@finkraft.ai"
									title="info@finkraft.ai"
								>
									{' '}
									<FaEnvelopeO />{' '}
								</a>
							</div>
							<div />
						</div>
						<div className="d-flex align-items-center">
							<Dropdown
								isOpen={this.state.dropdownOpen}
								toggle={this.toggle}
							>
								<div className="avatar">
									<img
										src={contact.photo_url}
										alt="Avatar"
										width="55"
										className="img-fluid rounded-circle"
									/>
								</div>
								<DropdownToggle caret className="dropdown-btn">
									{contact.first_name} {contact.last_name}
								</DropdownToggle>
								<DropdownMenu className="top-dropdown">
									<DropdownItem>
										<Link to="/myaccount">My Account</Link>
									</DropdownItem>
									<DropdownItem>
										<span onClick={() => auth.logout()}>
											<Link to="/">Logout</Link>
										</span>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(NavbarContainer));
