import { FETCH_VIEWS_DATA, FETCH_VIEWS_SUCCESS, FETCH_VIEWS_FAIL } from '../actions';

const initialState = {
    loading: false,
    views_response_success: null,
    views_response_fail: null
}

export default function contactId(state = initialState, action) {
	switch (action.type) {
		case FETCH_VIEWS_DATA:
            return Object.assign({}, state, {
                loading: true, views_response_success: null, views_response_fail: null
            })
        case FETCH_VIEWS_SUCCESS: {
            const payload = { ...action.payload };
            const { dashboard } = payload;
            const summeryIndex = dashboard.findIndex(it => it['Dashboard Name'].toLowerCase() === 'summary');
            if (summeryIndex !== -1) {
                const newDashboard = dashboard.slice();
                const summeryItem = newDashboard.splice(summeryIndex, 1);
                payload.dashboard = [...summeryItem, ...newDashboard];
            }
            return Object.assign({}, state, {
                loading: false, views_response_success: payload, views_response_fail: null
            })
        }
        case FETCH_VIEWS_FAIL:
            return Object.assign({}, state, {
                loading: true, views_response_success: null, views_response_fail: action.payload
            })
		default:
			return state;
	}
}
