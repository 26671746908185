import React, { Component } from 'react';
import { Icon, Spin } from 'antd';
import axios from 'axios';
import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import { IntlProvider } from 'react-intl';
import { ContentPreview } from 'box-ui-elements';
import 'box-ui-elements/dist/preview.css';

import Flex from '../styled/Flex';
import api from '../../helpers/api';

addLocaleData(enLocaleData);

class BoxFiles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFetchingToken: false,
			token: null,
			error: null,
		};
		this.fetchToken = this.fetchToken.bind(this);
	}

	componentDidMount() {
		this.fetchToken();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.customerDomain !== this.props.customerDomain) {
			this.fetchToken();
		}
	}

	async fetchToken() {
		this.setState({
			isFetchingToken: true,
		});
		try {
			const res = await axios.get(`${api().api}/box/token`, {
				headers: {
					'x-domain': this.props.customerDomain,
					'ngrok-skip-browser-warning': 40, 
				},
			});
			const data = await res.data;
			this.setState({
				token: data.token,
				isFetchingToken: false,
				error: null,
			});
		} catch (err) {
			this.setState({
				isFetchingToken: false,
				error: JSON.stringify(err),
			});
		}
	}

	render() {
		const { isFetchingToken, token, error } = this.state;
		const { match } = this.props;
		const fileId = match.params.fileId;
		return (
			<>
				{isFetchingToken && fileId && (
					<Flex
						justifyContent="center"
						alignItems="center"
						style={{ height: '80vh' }}
					>
						<Spin />
					</Flex>
				)}

				{!isFetchingToken && error && (
					<Flex
						justifyContent="center"
						alignItems="center"
						style={{ height: '80vh' }}
						i
						flexDirection="column"
					>
						<Icon
							type="frown"
							theme="outlined"
							style={{ fontSize: 32, marginBottom: 20 }}
						/>
						<p>Workspace not found for this domain</p>
					</Flex>
				)}

				{!isFetchingToken && !error && !fileId && (
					<Flex
						justifyContent="center"
						alignItems="center"
						style={{ height: '80vh' }}
						i
						flexDirection="column"
					>
						<Icon
							type="frown"
							theme="outlined"
							style={{ fontSize: 32, marginBottom: 20 }}
						/>
						<p>File not found</p>
					</Flex>
				)}

				{!isFetchingToken && !error && fileId && (
					<div
						style={{
							border: '1px solid #e5e5e5',
							borderRadius: 3,
							height: '100vh',
						}}
					>
						<IntlProvider
							locale="en"
							textComponent={React.Fragment}
						>
							<ContentPreview token={token} fileId={fileId} />
						</IntlProvider>
					</div>
				)}
			</>
		);
	}
}

export default BoxFiles;
