import { FETCH_USER_INFO_SUCCESS } from '../actions';

export default function user(state = {}, action) {
	switch (action.type) {
		case FETCH_USER_INFO_SUCCESS:
			return action.user.contact;
		default:
			return state;
	}
}
