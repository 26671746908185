import React, { Component } from 'react';

import UploadUIContainer from './containers/UploadUIContainer';

export class NewUploadUI extends Component {
	render() {
		return (
			<React.Fragment>
				<UploadUIContainer {...this.props} />
			</React.Fragment>
		);
	}
}

export default NewUploadUI;
