import { SET_AGGREGATIONS } from '../actions';

export default function(state = {}, action) {
	switch (action.type) {
		case SET_AGGREGATIONS:
			return action.aggregations;
		default:
			return state;
	}
}
