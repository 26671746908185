import React from 'react';

import SectionContainer from '../containers/SectionContainer';
import StepContainer from '../containers/StepContainer';

import Step12 from '../components/Step12';
import Step13 from '../components/Step13';
import Step14 from '../components/Step14';
import Step15 from '../components/Step15';
import Step16 from '../components/Step16';

const Section5 = ({
	stepsPending,
	estimatedTime,
	progress,
	isActive,
	isComplete,
	onSectionClick,
	stepStates,
	toggleStepDisplay,
	onStepCompletion,
	onStepSkip,
	...rest
}) => {
	return (
		<SectionContainer
			iconType="usergroup-add"
			sectionIndex={4}
			sectionTitle="Team and More"
			stepsPending={stepsPending}
			estimatedTime={estimatedTime}
			progress={progress}
			isActive={isActive}
			isComplete={isComplete}
			onSectionClick={onSectionClick}
		>
			<StepContainer
				stepTitle="Add Team"
				stepCounter="1"
				stepState={stepStates[0]}
				toggleStepDisplay={toggleStepDisplay(4, 0)}
				isChildActive={stepStates
					.map(state => state.isActive)
					.some(value => value === true)}
			>
				<Step12
					isInOnboarding={true}
					stepState={stepStates[0]}
					onStepCompletion={onStepCompletion(4, 0)}
					onStepSkip={onStepSkip(4, 0)}
					{...rest}
				/>
			</StepContainer>
			<StepContainer
				stepTitle="SPOC and Escalations"
				stepCounter="2"
				stepState={stepStates[1]}
				toggleStepDisplay={toggleStepDisplay(4, 1)}
				isChildActive={stepStates
					.map(state => state.isActive)
					.some(value => value === true)}
			>
				<Step13
					isInOnboarding={true}
					stepState={stepStates[1]}
					onStepCompletion={onStepCompletion(4, 1)}
					onStepSkip={onStepSkip(4, 1)}
					{...rest}
				/>
			</StepContainer>
			<StepContainer
				stepTitle="TMC SPOC Details"
				stepCounter="3"
				stepState={stepStates[2]}
				toggleStepDisplay={toggleStepDisplay(4, 2)}
				isChildActive={stepStates
					.map(state => state.isActive)
					.some(value => value === true)}
			>
				<Step14
					isInOnboarding={true}
					stepState={stepStates[2]}
					onStepCompletion={onStepCompletion(4, 2)}
					onStepSkip={onStepSkip(4, 2)}
					{...rest}
				/>
			</StepContainer>
			<StepContainer
				stepTitle="Travel Team Details"
				stepCounter="4"
				stepState={stepStates[3]}
				toggleStepDisplay={toggleStepDisplay(4, 3)}
				isChildActive={stepStates
					.map(state => state.isActive)
					.some(value => value === true)}
			>
				<Step15
					isInOnboarding={true}
					stepState={stepStates[3]}
					onStepCompletion={onStepCompletion(4, 3)}
					onStepSkip={onStepSkip(4, 3)}
					{...rest}
				/>
			</StepContainer>
			<StepContainer
				stepTitle="Tax Contact Details"
				stepCounter="5"
				stepState={stepStates[4]}
				toggleStepDisplay={toggleStepDisplay(4, 4)}
				isChildActive={stepStates
					.map(state => state.isActive)
					.some(value => value === true)}
			>
				<Step16
					isInOnboarding={true}
					stepState={stepStates[4]}
					onStepCompletion={onStepCompletion(4, 4)}
					onStepSkip={onStepSkip(4, 4)}
					{...rest}
				/>
			</StepContainer>
		</SectionContainer>
	);
};

export default Section5;
