import React from 'react';

import SectionContainer from '../containers/SectionContainer';
import StepContainer from '../containers/StepContainer';

import Step10 from '../components/Step10';
import Step11 from '../components/Step11';

const Section4 = ({
	stepsPending,
	estimatedTime,
	progress,
	isActive,
	isComplete,
	onSectionClick,
	stepStates,
	toggleStepDisplay,
	onStepCompletion,
	onStepSkip,
	...rest
}) => {
	return (
		<SectionContainer
			iconType="credit-card"
			sectionIndex={3}
			sectionTitle="Payments Information"
			stepsPending={stepsPending}
			estimatedTime={estimatedTime}
			progress={progress}
			isActive={isActive}
			isComplete={isComplete}
			onSectionClick={onSectionClick}
		>
			<StepContainer
				stepTitle="Payment Contact"
				stepCounter="1"
				stepState={stepStates[0]}
				toggleStepDisplay={toggleStepDisplay(3, 0)}
				isChildActive={stepStates
					.map(state => state.isActive)
					.some(value => value === true)}
			>
				<Step10
					isInOnboarding={true}
					stepState={stepStates[0]}
					onStepCompletion={onStepCompletion(3, 0)}
					onStepSkip={onStepSkip(3, 0)}
					{...rest}
				/>
			</StepContainer>
			<StepContainer
				stepTitle="Payments UI"
				stepCounter="2"
				stepState={stepStates[1]}
				toggleStepDisplay={toggleStepDisplay(3, 1)}
				isChildActive={stepStates
					.map(state => state.isActive)
					.some(value => value === true)}
			>
				<Step11
					isInOnboarding={true}
					stepState={stepStates[1]}
					onStepCompletion={onStepCompletion(3, 1)}
					onStepSkip={onStepSkip(3, 1)}
					{...rest}
				/>
			</StepContainer>
		</SectionContainer>
	);
};

export default Section4;
