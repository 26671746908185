import React from 'react';
import Appbase from 'appbase-js';
import { message } from 'antd';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { get, defaultTo } from 'lodash';

const esRef = new Appbase({
	app: 'shopelect-dashbord-reports',
	credentials: 'T8RJMNL6n:0a4d11cd-f77b-4410-8186-0897eb795846',
	url:
		'https://elasticsearch-eaas-siddharthappbaseio-shopelect-cluster.searchbase.io',
});

const cookies = new Cookies();
class ReportTab extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			url: null,
		};
	}

	componentDidMount() {
		this.getReport();
		this.interval = setInterval(() => {
			if(cookies.get('emailGen')){
				message.success(`An Email will be sent once the file is ready. Please check your inbox`,1);
				cookies.remove('emailGen')
			}
		}, 2000);
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.reports &&
			nextProps.reports !== this.props.reports
		) {
			console.log('setting customer domain: ', nextProps.reports);
			this.getReport(nextProps.reports);
		}
	}

	getReport = (_reports) => {
		const { reports , match } = this.props;
		const { reportNumber } = match.params;

		console.log(reports);
		const report = defaultTo(_reports, reports).find(rp => {
			console.log(rp);
			return rp['Report Number'] === reportNumber
		});
		console.log(report);
		if (report) {
			this.setState({ url: report.embedurl });
		}
	};

	render() {
		return (
			this.state.url && (
				<div className="iframe-container">
					<iframe
						title="Summary"
						className="iframeD"
						onContextMenu={() => {
							return false;
						}}
						frameBorder="0"
						width="100%"
						height="100%"
						src={this.state.url}
					/>
				</div>
			)
		);
	}
}

const mapStateToProps = state => {
	console.log('views', get(state, 'views', []));
	console.log(get(state, 'views.views_response_success.reporting', []));
	return ({
		reports: get(state, 'views.views_response_success.reporting', []),
	});
};

export default connect(mapStateToProps)(ReportTab);
