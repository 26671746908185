import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Switch,
} from 'react-router-dom';
import { Provider } from 'react-redux';
// import Intercom from 'react-intercom';
import { Tabs, Spin } from 'antd';
import 'antd/dist/antd.css';

import history from './helpers/history';
import { setCustomerDomain, setWorkspace } from './actions';
import Auth from './services/Auth';
import store from './store';
import TopbarContainer from './containers/TopbarContainer';
import MainContainer from './components/styled/MainContainer';
import MainView from './components/styled/MainView';
import GlobalDashboard from './components/GlobalDashboard';
import MyAccountContainer from './containers/MyAccountContainer.js?v4';
import LoginContainer from './containers/LoginContainer.js?v3';
import ReportTableContainer from './containers/ReportTableContainer';
import DMSContainer from './containers/DMSContainer';
import UploadFileContainer from './containers/UploadFileContainer';
import BoxFileManagerContainer from './containers/BoxFileManagerContainer';
import BoxFiles from './components/BoxFiles';
import Export from './components/ExportRedirect';
import BoxFilesPreview from './components/BoxFilePreview';
import ReportTab from './containers/ReportTab';
import Callback from './components/Callback';
import Flex from './components/styled/Flex';
import CredContainer from './containers/CredContainer';
import { getCurrentWorkspace } from './apis/workspace';
import './App.css';
import PaymentsPage, { PaymentsCallback } from './components/PaymentsPage';

import AccountsUI from './components/NewOnboarding/AccountsUI';
import Onboarding from './components/NewOnboarding/Onboarding';

import NewUploadUI from './components/NewUploadUI/NewUploadUI';

const TabPane = Tabs.TabPane;

const auth = new Auth();

const requireAuth = () => {
	if (auth.loggedIn()) {
		return true;
	}
	return false;
};

export const airlineRoutes = ['/', '/file-preview/:fileId'];

const isAllowedPath = (path, filterCol) => {
	if (path === '/myaccount' || path.indexOf('/file-preview/') > -1) {
		return true;
	}

	let res = true;

	if (airlineRoutes.indexOf(path) === -1 && filterCol === 'Airline') {
		res = false;
	}

	return res;
};

const handleAuthentication = (nextState, replace) => {
	if (/access_token|id_token|error/.test(nextState.location.hash)) {
		auth.handleAuthentication();
	}
};

class App extends React.Component {
	state = {
		loggedin_user: localStorage.getItem('email') || '',
		customer_domain: localStorage.getItem('customer_domain') || '',
		dropdown_options:
			JSON.parse(localStorage.getItem('dropdown_options')) || [],
		main_report: '',
		dashboardData: [],
		zoho_workspace: '',
		filterCol: null,
		isFetchingWorkspace: false,
	};

	componentDidMount() {
		store.dispatch(setCustomerDomain(this.state.customer_domain));
		// store.dispatch(setCWTRole('cwt'));
		// console.log('set cwt role to cwt');
	}

	setWorkspace = async workspace => {
		store.dispatch(setWorkspace(workspace));
		this.setState({ zoho_workspace: workspace });
	};

	setUser = loggedin_user => {
		this.setState({ loggedin_user });
	};

	setCustomerDomain = domain => {
		const customer_domain = domain;
		this.setState({ customer_domain });
		store.dispatch(setCustomerDomain(customer_domain));
		localStorage.setItem('customer_domain', customer_domain);
	};

	setDropdownOptions = dropdown_options => {
		this.setState({ dropdown_options });
		localStorage.setItem(
			'dropdown_options',
			JSON.stringify(dropdown_options),
		);
	};

	setMainReport = data => {
		const { dashboardData } = this.state;
		if (data.length > 0 && dashboardData.length == 0)
			this.setState({ dashboardData: data });
	};

	render() {
		const { filterCol, isFetchingWorkspace } = this.state;
		return (
			<Provider store={store}>
				<div className="App">
					{/* <Intercom appID="eq2eg25u" /> */}
					{/* <div> */}
					<Router history={history}>
						<section className="App-holder">
							<Switch>
								<Route
									exact
									path="/login"
									component={LoginContainer}
								/>
								<Route
									exact
									path="/callback"
									render={props => {
										handleAuthentication(props);
										return <Callback {...props} />;
									}}
								/>
								<MainContainer>
									<Route
										path="/"
										render={props =>
											requireAuth() ? (
												<TopbarContainer
													{...props}
													customer_domain={
														this.state
															.customer_domain
													}
													dropdown_options={
														this.state
															.dropdown_options
													}
													setUser={this.setUser}
													// setWorkspace={
													// 	this.setWorkspace
													// }
													setMainReport={
														this.setMainReport
													}
													setSideReports={
														this.setSideReports
													}
													setCustomerDomain={
														this.setCustomerDomain
													}
													setDropdownOptions={
														this.setDropdownOptions
													}
													loggedin_user={
														this.state.loggedin_user
													}
												/>
											) : (
												<Redirect to="/login" />
											)
										}
									/>
									<MainView>
										{isFetchingWorkspace ? (
											<Flex
												alignItem="center"
												justifyContent="center"
												style={{
													paddingTop: 200,
												}}
											>
												<Spin />
											</Flex>
										) : (
											// <div className="container-fluid">
											<div>
												<Route
													exact
													path="/"
													render={() =>
														isAllowedPath(
															'/',
															filterCol,
														) ? (
															<GlobalDashboard />
														) : (
															<Redirect to="/reports/invoice-not-received-but-in-2a-6a" />
														)
													}
												/>

												{/* <Route
													exact
													path="/myaccount"
													render={props =>
														isAllowedPath(
															'/myaccount',
															filterCol,
														) ? (
															<AccountsUI
																{...props}
																domain={
																	this.state
																		.customer_domain
																}
																email={
																	this.state
																		.loggedin_user
																}
																workspace={
																	this.state
																		.zoho_workspace
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/> */}
												{/* <Route
													exact
													path="/onboarding"
													render={props =>
														requireAuth() ? (
															<Onboarding
																{...props}
																domain={
																	this.state
																		.customer_domain
																}
																email={
																	this.state
																		.loggedin_user
																}
																workspace={
																	this.state
																		.zoho_workspace
																}
															/>
														) : (
															<Redirect to="/login" />
														)
													}
												/> */}

												<Route
													exact
													path="/myaccount"
													render={() =>
														isAllowedPath(
															'/myaccount',
															filterCol,
														) ? (
															<MyAccountContainer />
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													exact
													path="/reports/export/:workspace/:table"
													render={props =>
														isAllowedPath(
															'/reports/export/:workspace/:table',
															filterCol,
														) ? (
															<Export
																{...props}
																customer_domain={
																	this.state
																		.customer_domain
																}
																loggedin_user={
																	this.state
																		.loggedin_user
																}
																workspace={
																	this.state
																		.zoho_workspace
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													exact
													path="/dms"
													render={props =>
														isAllowedPath(
															'/dms',
															filterCol,
														) ? (
															<DMSContainer
																customer_domain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													exact
													path="/reporting"
													render={props =>
														isAllowedPath(
															'/reporting',
															filterCol,
														) ? (
															<ReportTableContainer
																customer_domain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													exact
													path="/upload"
													render={props =>
														isAllowedPath(
															'/upload',
															filterCol,
														) ? (
															<NewUploadUI
																domain={
																	this.state
																		.customer_domain
																}
																email={
																	this.state
																		.loggedin_user
																}
																workspace={
																	this.state
																		.zoho_workspace
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													exact
													path="/box"
													render={props =>
														isAllowedPath(
															'/box',
															filterCol,
														) ? (
															<BoxFileManagerContainer
																customer_domain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													exact
													path="/reporting/:reportNumber"
													render={props =>
														isAllowedPath(
															'/reporting/:reportNumber',
															filterCol,
														) ? (
															<ReportTab
																{...props}
																customer_domain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												{/* <Route
													path="/"
													render={() =>
														isAllowedPath(
															'/',
															filterCol,
														) ? (
															<AirlineReports
																customerDomain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/> */}
												<Route
													path="/files"
													render={() =>
														isAllowedPath(
															'/files',
															filterCol,
														) ? (
															<BoxFiles
																customerDomain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													path="/creds"
													render={() =>
														isAllowedPath(
															'/creds',
															filterCol,
														) ? (
															<CredContainer
																customerDomain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													path="/file-preview/:fileId"
													render={props =>
														isAllowedPath(
															'/file-preview/:fileId',
															filterCol,
														) ? (
															<BoxFilesPreview
																{...props}
																customerDomain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												{/* <Switch>
													<Route
														path="/payments/callback"
														render={props => (
															<PaymentsCallback
																setCustomerDomain={
																	this
																		.setCustomerDomain
																}
																customer_domain={
																	this.state
																		.customer_domain
																}
																{...props}
															/>
														)}
													/>
													<Route
														path="/payments"
														exact
														render={props => (
															<PaymentsPage
																customer_domain={
																	this.state
																		.customer_domain
																}
																{...props}
															/>
														)}
													/>
												</Switch> */}
											</div>
										)}
									</MainView>
								</MainContainer>
							</Switch>
						</section>
					</Router>
					{/* </div> */}
				</div>
			</Provider>
		);
	}
}

export default App;
