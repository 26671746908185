import { FETCH_AUTHORIZATION_DATA, FETCH_AUTHORIZATION_SUCCESS, FETCH_AUTHORIZATION_FAIL } from '../actions';

const initialState = {
    loading: false,
    authorization_response_success: null,
    authorization_response_fail: null
}

export default function contactId(state = initialState, action) {
	switch (action.type) {
		case FETCH_AUTHORIZATION_DATA:
            return Object.assign({}, state, {
                loading: true, authorization_response_success: null, authorization_response_fail: null
            })
        case FETCH_AUTHORIZATION_SUCCESS:
            return Object.assign({}, state, {
                loading: false, authorization_response_success: action.payload, authorization_response_fail: null
            })
        case FETCH_AUTHORIZATION_FAIL:
            return Object.assign({}, state, {
                loading: true, authorization_response_success: null, authorization_response_fail: action.payload
            })
		default:
			return state;
	}
}
