import React, { Component } from 'react';
// import loading from './loading.svg';

class Loading extends Component {
	style = {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		height: '100vh',
		width: '100vw',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: 'white',
	};

	render() {
		return (
			<div style={this.style}>
				<img src='/loading.svg' alt="loading" />
			</div>
		);
	}
}

export default Loading;
