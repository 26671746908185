import styled from 'styled-components';

const FormView = styled.form`
	input[type=submit]:hover {
		background-color: #45a049;
	}

	fieldset {
		border: none;
		padding: 2em 0;
		width: ${props => (props.compact ? '60%' : '100%')};

		/*input[type=text], input[type=number], input[type=date], select {
			width: 100%;
			padding: 12px 20px;
			margin: 8px 0;
			display: inline-block;
			border: 1px solid #ccc;
			border-radius: 4px;
			box-sizing: border-box;

			&:disabled {
				background-color: #ebebe4;
				cursor: not-allowed;
			}
		}
	}*/

	input[type=submit] {
		width: 100px;
		background-color: #4CAF50;
		color: white;
		padding: 14px 20px;
		margin: 8px 0;
		border: none;
		border-radius: 4px;
		cursor: pointer;
	}

`;

export default FormView;
