import React from 'react';
import Spinner from 'react-spinkit';

import CallbackView from './styled/CallbackView';
import Row from './styled/Row';

const Callback = () => (
	<CallbackView>
		<Row full center horizontallyCenter>
			<Spinner name="three-bounce" />
		</Row>
	</CallbackView>
);

export default Callback;
