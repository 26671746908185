import React from 'react';

import styled from 'styled-components';

import BookingUpload from '../components/BookingUpload';
import HmprUpload from '../components/HmprUpload';
import InvoiceUpload from '../components/InvoiceUpload';

import UploadUIStepWrapper from './UploadUIStepWrapper';

const Main = styled.section`
	min-height: 100vh;
	max-height: 100%;
	width: 100%;

	padding: 20px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: row nowrap;

	background-color: #eee;
`;

export const UploadUIStepContainer = ({ currentStep = 1, ...rest }) => {
	return (
		<Main>
			{currentStep === 1 && (
				<UploadUIStepWrapper title="Booking Data">
					<BookingUpload {...rest} />
				</UploadUIStepWrapper>
			)}
			{currentStep === 2 && (
				<UploadUIStepWrapper title="HMPR Data">
					<HmprUpload {...rest} />
				</UploadUIStepWrapper>
			)}
			{currentStep === 3 && (
				<UploadUIStepWrapper title="Invoices">
					<InvoiceUpload {...rest} />
				</UploadUIStepWrapper>
			)}
		</Main>
	);
};
