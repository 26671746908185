import React, { Component } from 'react';
import '../../../public/css/app.css';
import { AppStyle } from '../../../constants/AppStyle';
import { CarouselView } from './carousel/CarouselView';
import Auth from '../../../services/Auth';
import Api from '../../../helpers/api';
import { IMAGES_URL } from '../../../constants/Images';
import { Row, Col, Button, Input, Form, message, Modal } from 'antd';
import LogoAbbott from '../../../assets/logo_1.png';
import LogoAcg from '../../../assets/logo_2.png';
import LogoBcg from '../../../assets/logo_3.png';
import LogoCap from '../../../assets/logo_4.png';
import './login.css';
import { listDomain } from './validatorArray';
import finkraftLogo from '../../../assets/finkraftLogo.svg';

const FormItem = Form.Item;

export const getHeaders = () => {
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'ngrok-skip-browser-warning': 40, 
	};
	return headers;
};


const StylesReg = {
	LOGO_HEAD: {
		// backgroundImage: `url(${IMAGES_URL.LOGIN_LOGO})`,
		backgroundSize: '100% 100%',
	},
};

const auth = new Auth();
const api = Api();

const Styles = {
	GRADIENT: [
		{ background: `linear-gradient(135deg, #0053FF 0%, #00D7FF 100%)` },
		{ background: `linear-gradient(315deg, #1ABC9C 0%, #00D7FF 100%` },
		{ background: `linear-gradient(135deg, #0053FF 0%, #00D7FF 100%)` },
		{ background: `linear-gradient(315deg, #1ABC9C 0%, #00D7FF 100%` },
	],
};

export class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			verified: false,
			email: '',
			mobile: '',
			code: '',
			authFlow: '',
			setCarouselPosition: 0,
			flag: true,
			emailStatus: false,
			id: '',
			otp: null,
			id_placeHolder: '',
			type: '',
			otpOrPass: 'Enter OTP',
			otpEntered: false,
			loading: false,
			phone_number: { value: '' },
			visibleTerms: false,
			visiblePrivacy: false,
		};
		this.CarouselPosition = this.CarouselPosition.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.setAuthFlow = this.setAuthFlow.bind(this);
		this.getID = this.getID.bind(this);
		this.cardClicked = this.cardClicked.bind(this);
		this.requestOTP = this.requestOTP.bind(this);
	}

	showModalTerms = () => {
		this.setState({
			visibleTerms: true,
		});
	};

	handleOkTerms = e => {
		this.setState({
			visibleTerms: false,
		});
	};

	handleCancelTerms = e => {
		this.setState({
			visibleTerms: false,
		});
	};

	showModalPrivacy = () => {
		this.setState({
			visiblePrivacy: true,
		});
	};

	handleOkPrivacy = e => {
		this.setState({
			visiblePrivacy: false,
		});
	};

	handleCancelPrivacy = e => {
		this.setState({
			visiblePrivacy: false,
		});
	};

	cardClicked(value) {
		this.setState({ flag: !this.state.flag });
		if (value === 'email') {
			this.setState({
				id: 'Email Address',
				id_placeHolder: 'example@abc.xyz',
				type: 'phone number',
			});
		} else {
			this.setState({
				id: 'Phone Number',
				id_placeHolder: '9876543210',
				type: 'Email',
			});
		}
	}

	requestOTP(basic) {
		if (this.validateEmail(this.state.email.toLowerCase())) {
			if (process.env.NODE_ENV != 'development') {
				if (
					this.validateProductionEmail(this.state.email.toLowerCase())
				) {
					this.handleSubmit(basic);
				} else {
					message.error(
						'Please enter a valid corporate e-mail address.',
					);
				}
			} else {
				this.handleSubmit(basic);
			}
		} else {
			message.error('Please enter a valid email address');
		}
	}

	getID(value, type) {
		if (type === 'email') {
			this.setState({ email: value });
		} else {
			this.setState({ mobile: value });
		}
	}

	switchLoginType() {
		if (this.state.type === 'phone number') {
			this.setState({
				id: 'Phone Number',
				authFlow: 'phone',
				id_placeHolder: '9876543210',
				type: 'Email',
				email: '',
				mobile: '',
				otp: null,
				otpEntered: false,
				loading: false,
			});
		} else {
			this.setState({
				id: 'Email Address',
				authFlow: 'email',
				id_placeHolder: 'example@abc.xyz',
				type: 'phone number',
				email: '',
				mobile: '',
				otp: null,
				otpEntered: false,
				loading: false,
			});
		}
	}

	setAuthFlow(flow) {
		this.setState({
			authFlow: flow,
		});
	}

	showError(e) {
		message.error(e);
	}

	handleChange(e) {
		const { name, value } = e.target;
		if (name === 'mobile' && !value.match(/^$|^\d+$/)) {
			message.error(
				'Mobile number should be valid and contain the country code',
			);
		} else {
			this.setState({
				[name]: value,
			});
		}
	}

	validateEmail(email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	validateProductionEmail(email) {
		// var domain=email.split("@");
		// return listDomain.includes(domain[1])
		return true;
	}

	handleSubmit(basic) {
		if (!this.state.verified) {
			if (this.state.authFlow === 'email') {
				fetch(
					`${api.api}/login?email=${this.state.email.toLowerCase()}`,{
						headers: getHeaders()
					}
				)
					.then(res => res.json())
					.then(json => {
						if (json.success) {
							if (!basic) {
								message.success(json.message);
								auth.sendMail(this.state.email);
								this.setState({
									otpEntered: true,
									loading: true,
								});
								this.setState({
									otpOrPass: 'Enter OTP',
									verified: true,
									otpEntered: true,
									loading: true,
								});
							} else {
								this.setState({
									otpOrPass: 'Enter Password',
									verified: true,
									otpEntered: true,
									loading: true,
								});
							}
							auth.setContactId(json.contactId);
						} else {
							message.error(json.message);
						}
					});
			} else {
				fetch(`${api.api}/login?mobile=${this.state.mobile}`, {
					headers: getHeaders()
				})
					.then(res => res.json())
					.then(json => {
						if (json.success) {
							this.setState({
								verified: true,
							});
							message.success(json.message);
							auth.sendSms(this.state.mobile);
							auth.setContactId(json.contactId);
						} else {
							message.error(json.message);
						}
					});
			}
		} else {
			if (this.state.authFlow === 'email') {
				auth.login(this.state.email, this.state.otp, this.showError);
			} else {
				auth.loginWithSms(
					this.state.mobile,
					this.state.otp,
					this.showError,
				);
			}
		}
	}

	CarouselPosition(to) {
		this.setState({ setCarouselPosition: to });
	}

	render() {
		const { email, phone_number } = this.state;
		return (
			<React.Fragment>
				<div
					style={{
						...Styles.GRADIENT[this.state.setCarouselPosition],
						...AppStyle.FULL_CONTAINER(0),
					}}
				>
					<div class="main--header">
						{/* <h2>Claim GST Input for FY 2018-19</h2>
						<p>
							Retrieve Airline Invoices from Qatar Airways, Jet
							Airways, Indigo and more now.
						</p> */}
						<h2 style={{ padding: 50 }}></h2>
						<p></p>
					</div>
					<div className="login-container center-flex">
						<div className="column">
							<CarouselView
								CarouselPosition={this.CarouselPosition}
							/>
						</div>
						<div className="white-card-main">
							<div className="white-card">
								<Row className="login-logo">
									<div
										className="horizontal-center"
										style={StylesReg.LOGO_HEAD}
									>
										<img
											className="logo-lg marginTop-10"
											// src={IMAGES_URL.LOGO_SM}
											src={finkraftLogo}
										></img>
									</div>
								</Row>
								{this.state.flag ? (
									<div className="marginTop-20">
										<Row className="login-text1">
											<label>
												Login via Corporate Email ID
											</label>
										</Row>
										<Row
											className="center-flex"
											type="flex"
										>
											<Col>
												<Row
													className="img-card"
													onClick={() => {
														this.cardClicked(
															'email',
														);
														this.setAuthFlow(
															'email',
														);
													}}
												>
													<Col>
														<img
															src={
																IMAGES_URL.MESSAGE
															}
															className="img-card-icon"
														/>
													</Col>
													<Col className="img-card-text">
														Email
													</Col>
												</Row>
											</Col>
											{/* <Col offset={1}>
                                        <Row style={{ backgroundColor: 'gray', opacity: 0.8 }} className="img-card" onClick={() => { message.info('This feature will be available soon.'); }}>
                                            <Col><img src={IMAGES_URL.PHONE} className="img-card-icon" /></Col>
                                            <Col className="img-card-text">Phone Number </Col>
                                        </Row>
                                    </Col> */}
										</Row>
									</div>
								) : (
									<div className="marginTop padding-24">
										<Row>
											<Col>{this.state.id}</Col>
											{this.state.id ===
											'Email Address' ? (
												<Col className="marginTop-10">
													<FormItem
														validateStatus={
															email.validateStatus
														}
														help={email.errorMsg}
													>
														<Input
															placeholder={
																this.state
																	.id_placeHolder
															}
															value={
																this.state.email
															}
															onChange={e => {
																this.getID(
																	e.target
																		.value,
																	'email',
																);
															}}
														/>
													</FormItem>
												</Col>
											) : (
												<Col className="marginTop-10">
													<FormItem
														validateStatus={
															phone_number.validateStatus
														}
														help={
															phone_number.errorMsg
														}
													>
														<Input
															placeholder={
																this.state
																	.id_placeHolder
															}
															addonBefore={'+91'}
															value={
																this.state
																	.mobile
															}
															onChange={e =>
																this.getID(
																	e.target
																		.value,
																	'mobile',
																)
															}
														/>
													</FormItem>
												</Col>
											)}

											{this.state.otpEntered && (
												<div>
													<Col className="marginTop-10">
														<Input
															placeholder={
																this.state
																	.otpOrPass
															}
															type="password"
															value={
																this.state.otp
															}
															onChange={e =>
																this.setState({
																	otp:
																		e.target
																			.value,
																})
															}
														/>{' '}
													</Col>
													<Col>
														<Button
															type="primary"
															disabled={
																this.state
																	.email ||
																(phone_number.value &&
																	this.state
																		.otp)
																	? false
																	: true
															}
															className="requeest-otp-btn"
															onClick={() =>
																this.handleSubmit()
															}
														>
															Login
														</Button>
													</Col>
												</div>
											)}

											{!this.state.otpEntered && (
												<Col>
													<a
														onClick={() =>
															this.requestOTP(
																true,
															)
														}
													>
														<u>
															Login with Password
														</u>
													</a>
													<Button
														type="primary"
														loading={
															this.state.loading
														}
														disabled={
															this.state.verified
														}
														className="requeest-otp-btn"
														onClick={() =>
															this.requestOTP()
														}
													>
														{this.state.loading
															? ''
															: 'REQUEST OTP'}
													</Button>
												</Col>
											)}
											{/* <Col className="register-here" onClick={() => { this.switchLoginType(); }} >Login via {this.state.type} instead</Col> */}
										</Row>
									</div>
								)}
							</div>
						</div>
					</div>
					<div class="main--footer" style={{ paddingTop: 120 }}>
						{/* <div className="main--footer-top">
							<h2>You're in good company</h2>
							<div className="company-flex">
								<img src={LogoAbbott} />
								<img src={LogoAcg} />
								<img src={LogoBcg} />
								<img src={LogoCap} />
							</div>
						</div> */}
						<div className="main--footer-bottom">
							<a
								className="btn learnMore-btn"
								href="https://finkraft.ai/"
							>
								Learn More about finkraft.ai{' '}
							</a>
							<p>
								<a href="#" onClick={this.showModalTerms}>
									Terms &amp; Conditions
								</a>
								<a href="#" onClick={this.showModalPrivacy}>
									Privacy Policy
								</a>
							</p>
						</div>
					</div>
				</div>

				<Modal
					title="Terms &amp; Conditions"
					visible={this.state.visibleTerms}
					onOk={this.handleOkTerms}
					onCancel={this.handleCancelTerms}
					bodyStyle={{ height: '500px', overflowY: 'auto' }}
				>
					Finkraft Online Subscription agreement:
					<p>
						This Finkraft Online Subscription The agreement is
						between the entity you represent, or if you do not
						designate an entity in connection with a Subscription
						purchase or renewal, you individually (“Client”), and
						Shop Elect Private Limited (" finkraft.ai ", "we", "us",
						or "our"). It consists of the terms and conditions
						below, for your Subscription or renewal (together, the
						"agreement"). It is effective on the date of your
						Subscription or the date on which your Subscription is
						renewed, as applicable. Key terms are defined below.
					</p>
					Introduction:
					<ul>
						<li>
							www.finkraft.ai is an internet-based portal operated
							by Shop Elect Private Limited, a company
							incorporated under the laws of India.
						</li>
						<li>
							Use of the Website is offered to you conditioned on
							acceptance without modification of all the terms,
							conditions and notices contained in these Terms, as
							may be posted on The website from time to time.
							finkraft.ai, at its sole discretion reserves the
							right not to accept a User from registering on the
							Website without assigning any reason thereof.
						</li>
						<li>
							The engagement will start effective from the date of
							subscription. This the agreement will remain in
							effect until the expiration, termination, or renewal
							of your Subscription, whichever is earliest.
						</li>
						<li>
							You may terminate a Subscription at any time during
							its Term; however, you must pay all amounts due and
							owing before the termination is effective.
						</li>
					</ul>
					NDA:
					<ul>
						<li>
							It has been mutually agreed that finkraft.ai agrees
							and confirms that it shall not use, share, and
							reveal any Confidential Information provided by the
							CLIENT for any purpose other than for rendering
							Services under this Agreement and provide it only on
							a need to know the basis to its Personnel (including
							subcontractors wherever applicable) for provision of
							Services under this Agreement with prior written
							permission from CLIENT.
						</li>
					</ul>
					Limited Liability:
					<ul>
						<li>
							CLIENT acknowledges and accepts that finkraft.ai is
							a solution provider of helping CLIENT to calculate
							the GST Input Credit Data reconciliation and
							supporting’s for availing the Credit and the
							Services which finkraft.ai provides are dependent on
							external parties like Travel Management Companies,
							Airlines, CLIENT Data, and hence finkraft.ai will
							not be held responsible for any loss incurred by the
							CLIENT. Either party will not be liable for any
							direct or indirect loss occurring on account of this
							agreement.
						</li>
					</ul>
					Payment:
					<ul>
						<li>
							CLIENT would pay all the payments due to finkraft.ai
							according to the offer details of their
							subscription.
						</li>
					</ul>
					Representation:
					<ul>
						<li>
							CLIENT authorizes finkraft.ai to represent the
							CLIENT on its behalf to Travel Management Companies,
							Airlines, for purposes required in the services
							offered by finkraft.ai.
						</li>
						<li>
							finkraft.ai to use clients' email id to send mails
							to the airlines/tmc/others on the client's behalf to
							followup for the invoices or any other purposes as
							deemed necessary by finkraft.ai for the purposes of
							its services.
						</li>
					</ul>
				</Modal>

				<Modal
					visible={this.state.visiblePrivacy}
					onOk={this.handleOkPrivacy}
					onCancel={this.handleCancelPrivacy}
					bodyStyle={{ height: '500px' }}
				>
					<iframe src="https://www.iubenda.com/privacy-policy/18028004" />
				</Modal>
			</React.Fragment>
		);
	}
}

export default LoginPage;
