import React, { Component } from 'react';
import axios from 'axios';
import { cloneDeep } from 'lodash';

import OnboardingContainer from './containers/OnboardingContainer';

import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Section4 from './components/Section4';
import Section5 from './components/Section5';
import { Spin, Row, Col } from 'antd';

import Api from '../../helpers/api';
import { InitialScreen } from './common';

const api = Api();

let initialState = {
	isStateFetched: false,
	onboardingInfo: [
		{
			section: 1,
			isActive: false,
			steps: [
				{
					step: 1,
					isActive: false,
					isSkipped: false,
					isSkippable: false,
					isComplete: false,
					estimatedTime: 3,
				},
			],
		},
		{
			section: 2,
			isActive: false,
			steps: [
				{
					step: 1,
					isActive: false,
					isSkipped: false,
					isSkippable: false,
					isComplete: false,
					estimatedTime: 4,
				},
				{
					step: 2,
					isActive: false,
					isSkipped: false,
					isSkippable: false,
					isComplete: false,
					estimatedTime: 3,
				},
				{
					step: 3,
					isActive: false,
					isSkipped: false,
					isSkippable: true,
					isComplete: false,
					estimatedTime: 10,
				},
				{
					step: 4,
					isActive: false,
					isSkipped: false,
					isSkippable: true,
					isComplete: false,
					estimatedTime: 3,
				},
				{
					step: 5,
					isActive: false,
					isSkipped: false,
					isSkippable: false,
					isComplete: false,
					estimatedTime: 10,
				},
			],
		},
		{
			section: 3,
			isActive: false,
			steps: [
				{
					step: 1,
					isActive: false,
					isSkipped: false,
					isSkippable: true,
					isComplete: false,
					estimatedTime: 10,
				},
				{
					step: 2,
					isActive: false,
					isSkipped: false,
					isSkippable: false,
					isComplete: false,
					estimatedTime: 2,
				},
				{
					step: 3,
					isActive: false,
					isSkipped: false,
					isSkippable: false,
					isComplete: false,
					estimatedTime: 20,
				},
				{
					step: 4,
					isActive: false,
					isSkipped: false,
					isSkippable: false,
					isComplete: false,
					estimatedTime: 15,
				},
			],
		},
		{
			section: 4,
			isActive: false,
			steps: [
				{
					step: 1,
					isActive: false,
					isSkipped: false,
					isSkippable: true,
					isComplete: false,
					estimatedTime: 10,
				},
				{
					step: 2,
					isActive: false,
					isSkipped: false,
					isSkippable: true,
					isComplete: false,
					estimatedTime: 20,
				},
			],
		},
		{
			section: 5,
			isActive: false,
			steps: [
				{
					step: 1,
					isActive: false,
					isSkipped: false,
					isSkippable: false,
					isComplete: false,
					estimatedTime: 10,
				},
				{
					step: 2,
					isActive: false,
					isSkipped: false,
					isSkippable: false,
					isComplete: false,
					estimatedTime: 20,
				},
				{
					step: 3,
					isActive: false,
					isSkipped: false,
					isSkippable: false,
					isComplete: false,
					estimatedTime: 15,
				},
				{
					step: 4,
					isActive: false,
					isSkipped: false,
					isSkippable: false,
					isComplete: false,
					estimatedTime: 25,
				},
				{
					step: 5,
					isActive: false,
					isSkipped: false,
					isSkippable: false,
					isComplete: false,
					estimatedTime: 5,
				},
			],
		},
	],
};

class Onboarding extends Component {
	constructor(props) {
		super(props);

		this.state = { ...initialState };
	}

	componentDidMount() {
		if (this.props.workspace !== '') {
			this.getGlobalStateFromApi().then(resp => {
				const currentOnboardingState = resp.data;

				if (currentOnboardingState.length) {
					this.setState({
						onboardingInfo: [...currentOnboardingState],
						isStateFetched: true,
					});
				} else {
					this.putGlobalStateToApi()
						.then(resp => console.log(resp))
						.catch(err => console.log(err));
				}
			});
		}
	}

	componentDidUpdate() {
		if (this.state.isStateFetched) {
			this.putGlobalStateToApi()
				.then(resp => console.log(resp))
				.catch(err => console.log(err));
		}
	}

	getGlobalStateFromApi = async () => {
		const organizationId = 'Test-ID1234';

		const apiUrl = `${api.api}/onboarding/status?organizationId=${organizationId}`;

		const apiResponse = await axios.get(apiUrl);

		return apiResponse.data;
	};

	putGlobalStateToApi = () => {
		const { domain, workspace, email } = this.props;

		const organizationId = 'Test-ID1234';

		const apiUrl = `${api.api}/onboarding/status`;

		return axios.put(apiUrl, {
			organizationId: organizationId,
			lastUpdatedEmail: email,
			customerDomain: domain,
			customerWorkspace: workspace,
			onboardingInfo: this.state.onboardingInfo,
		});
	};

	isSectionComplete = sectionIndex => {
		return this.state.onboardingInfo[sectionIndex].steps.every(
			step => step.isComplete,
		);
	};

	sectionProgress = sectionIndex => {
		const steps = this.state.onboardingInfo[sectionIndex].steps;

		return (
			(steps.reduce((acc, step) => {
				return step.isComplete ? (acc += 1) : acc;
			}, 0) /
				steps.length) *
			100
		);
	};

	stepsPending = sectionIndex => {
		const steps = this.state.onboardingInfo[sectionIndex].steps;

		return steps.reduce(
			(acc, step) => (step.isComplete ? (acc -= 1) : acc),
			steps.length,
		);
	};

	estimatedTime = sectionIndex => {
		const steps = this.state.onboardingInfo[sectionIndex].steps;

		return steps.reduce((acc, step) => {
			return !step.isComplete ? (acc += step.estimatedTime) : acc;
		}, 0);
	};

	onStepCompletion = (sectionIndex, stepIndex) => () => {
		const { onboardingInfo } = this.state;
		const clonedOnboardingInfo = cloneDeep(onboardingInfo);
		const section = clonedOnboardingInfo[sectionIndex];
		const sectionSteps = section.steps;
		sectionSteps[stepIndex].isComplete = true;

		this.setState({
			onboardingInfo: clonedOnboardingInfo,
		});

		setTimeout(() => {
			this.setState(prevState => {
				const onboardingInfo = cloneDeep(prevState.onboardingInfo);

				const currentSection = onboardingInfo[sectionIndex];

				const steps = currentSection.steps;

				steps[stepIndex].isActive = false;

				if (stepIndex < steps.length - 1 && sectionIndex < 4) {
					const nextSectionIndex = sectionIndex;
					const nextStepIndex = stepIndex + 1;

					const currentSection = onboardingInfo[nextSectionIndex];

					currentSection.isActive = true;

					const steps = currentSection.steps;

					steps[nextStepIndex].isActive = true;
				} else if (steps.length - 1 === stepIndex && sectionIndex < 4) {
					const nextSectionIndex = sectionIndex + 1;
					const nextStepIndex = 0;

					const currentSection = onboardingInfo[nextSectionIndex];

					currentSection.isActive = true;

					const steps = currentSection.steps;

					steps[nextStepIndex].isActive = true;
				} else if (stepIndex < steps.length - 1 && sectionIndex === 4) {
					const nextSectionIndex = sectionIndex;
					const nextStepIndex = stepIndex + 1;

					const currentSection = onboardingInfo[nextSectionIndex];

					currentSection.isActive = true;

					const steps = currentSection.steps;

					steps[nextStepIndex].isActive = true;
				}

				return { onboardingInfo: [...onboardingInfo] };
			});
		}, 2000);
	};

	onStepSkip = (sectionIndex, stepIndex) => e => {
		this.setState(prevState => {
			const onboardingInfo = cloneDeep(prevState.onboardingInfo);

			const currentSection = onboardingInfo[sectionIndex];

			const steps = currentSection.steps;

			steps[stepIndex].isSkipped = true;
			steps[stepIndex].isActive = false;

			if (stepIndex < steps.length - 1 && sectionIndex < 4) {
				const nextSectionIndex = sectionIndex;
				const nextStepIndex = stepIndex + 1;

				const currentSection = onboardingInfo[nextSectionIndex];

				currentSection.isActive = true;

				const steps = currentSection.steps;

				steps[nextStepIndex].isActive = true;
			} else if (steps.length - 1 === stepIndex && sectionIndex < 4) {
				const nextSectionIndex = sectionIndex + 1;
				const nextStepIndex = 0;

				const currentSection = onboardingInfo[nextSectionIndex];

				currentSection.isActive = true;

				const steps = currentSection.steps;

				steps[nextStepIndex].isActive = true;
			} else if (stepIndex < steps.length - 1 && sectionIndex === 4) {
				const nextSectionIndex = sectionIndex;
				const nextStepIndex = stepIndex + 1;

				const currentSection = onboardingInfo[nextSectionIndex];

				currentSection.isActive = true;

				const steps = currentSection.steps;

				steps[nextStepIndex].isActive = true;
			}

			return { onboardingInfo: [...onboardingInfo] };
		});
	};

	onSectionClick = sectionIndex => e => {
		if ([0, 1, 2, 3, 4].includes(sectionIndex)) {
			this.setState(prevState => {
				const onboardingInfo = cloneDeep(prevState.onboardingInfo);

				onboardingInfo.forEach((section, i) => {
					if (sectionIndex !== i) {
						section.isActive = false;
					}
					section.steps.forEach(step => {
						step.isActive = false;
					});
				});
				onboardingInfo[sectionIndex].isActive = !onboardingInfo[
					sectionIndex
				].isActive;

				return {
					onboardingInfo: [...onboardingInfo],
				};
			});
		}
	};

	toggleStepDisplay = (sectionIndex, stepIndex) => e => {
		this.setState(prevState => {
			const onboardingInfo = cloneDeep(prevState.onboardingInfo);

			onboardingInfo.forEach((section, secIndex) => {
				section.steps.forEach((step, index) => {
					if (secIndex === sectionIndex && index === stepIndex) {
						step.isActive = !step.isActive;
					} else {
						step.isActive = false;
					}
				});
			});

			return {
				onboardingInfo: [...onboardingInfo],
			};
		});
	};

	render() {
		const { isStateFetched } = this.state;

		return (
			<React.Fragment>
				{!isStateFetched ? (
					<Row
						type="flex"
						justify="center"
						align="middle"
						style={{ width: '100vw', height: '100vh' }}
					>
						<Col span={24}>
							<Spin />
						</Col>
					</Row>
				) : (
					<OnboardingContainer>
						<Section1
							{...this.props}
							stepsPending={this.stepsPending(0)}
							estimatedTime={this.estimatedTime(0)}
							progress={this.sectionProgress(0)}
							isActive={
								this.state.onboardingInfo.filter(
									obj => obj.section === 1,
								)[0].isActive
							}
							isComplete={this.isSectionComplete(0)}
							onSectionClick={this.onSectionClick(0)}
							stepStates={
								this.state.onboardingInfo.filter(
									obj => obj.section === 1,
								)[0].steps
							}
							toggleStepDisplay={this.toggleStepDisplay}
							onStepCompletion={this.onStepCompletion}
							onStepSkip={this.onStepSkip}
						/>
						<Section2
							{...this.props}
							stepsPending={this.stepsPending(1)}
							estimatedTime={this.estimatedTime(1)}
							progress={this.sectionProgress(1)}
							isActive={
								this.state.onboardingInfo.filter(
									obj => obj.section === 2,
								)[0].isActive
							}
							isComplete={this.isSectionComplete(1)}
							onSectionClick={this.onSectionClick(1)}
							stepStates={
								this.state.onboardingInfo.filter(
									obj => obj.section === 2,
								)[0].steps
							}
							toggleStepDisplay={this.toggleStepDisplay}
							onStepCompletion={this.onStepCompletion}
							onStepSkip={this.onStepSkip}
						/>
						<Section3
							{...this.props}
							stepsPending={this.stepsPending(2)}
							estimatedTime={this.estimatedTime(2)}
							progress={this.sectionProgress(2)}
							isActive={
								this.state.onboardingInfo.filter(
									obj => obj.section === 3,
								)[0].isActive
							}
							isComplete={this.isSectionComplete(2)}
							onSectionClick={this.onSectionClick(2)}
							stepStates={
								this.state.onboardingInfo.filter(
									obj => obj.section === 3,
								)[0].steps
							}
							toggleStepDisplay={this.toggleStepDisplay}
							onStepCompletion={this.onStepCompletion}
							onStepSkip={this.onStepSkip}
						/>
						<Section4
							{...this.props}
							stepsPending={this.stepsPending(3)}
							estimatedTime={this.estimatedTime(3)}
							progress={this.sectionProgress(3)}
							isActive={
								this.state.onboardingInfo.filter(
									obj => obj.section === 4,
								)[0].isActive
							}
							isComplete={this.isSectionComplete(3)}
							onSectionClick={this.onSectionClick(3)}
							stepStates={
								this.state.onboardingInfo.filter(
									obj => obj.section === 4,
								)[0].steps
							}
							toggleStepDisplay={this.toggleStepDisplay}
							onStepCompletion={this.onStepCompletion}
							onStepSkip={this.onStepSkip}
						/>
						<Section5
							{...this.props}
							stepsPending={this.stepsPending(4)}
							estimatedTime={this.estimatedTime(4)}
							progress={this.sectionProgress(4)}
							isActive={
								this.state.onboardingInfo.filter(
									obj => obj.section === 5,
								)[0].isActive
							}
							isComplete={this.isSectionComplete(4)}
							onSectionClick={this.onSectionClick(4)}
							stepStates={
								this.state.onboardingInfo.filter(
									obj => obj.section === 5,
								)[0].steps
							}
							toggleStepDisplay={this.toggleStepDisplay}
							onStepCompletion={this.onStepCompletion}
							onStepSkip={this.onStepSkip}
						/>
					</OnboardingContainer>
				)}
			</React.Fragment>
		);
	}
}

export default Onboarding;
