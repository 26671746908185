import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import * as Sentry from '@sentry/browser';
import App from './App.js?v9';
// import { unregister } from './registerServiceWorker';

Sentry.init({
	dsn: 'https://61e4e6b3a45c4329899c7fe2c2911356@sentry.io/4657973',
});

ReactDOM.render(<App />, document.getElementById('root'));
// if (process.env.NODE_ENV !== 'development') {
// 	unregister();
// }

document.oncontextmenu = function() {
	return false;
};

if (module.hot) {
	module.hot.accept('./App', () => {
		const Hot = require('./App').default; // eslint-disable-line global-require
		ReactDOM.render(<Hot />, document.getElementById('root'));
	});
}
