import React from 'react';
import './index.css';

function FullPageMessage({ children }) {
  return (
    <div className="full-page-message">
      { children }
    </div>
  )
}

export default FullPageMessage;

