import React, { Component } from 'react';
import axios from 'axios';
import { cloneDeep } from 'lodash';

import { Form, Input, Row, Col, message } from 'antd';

import PropTypes from 'prop-types';

import { AddButton, DeleteButton, StepFooter, rowStyle } from '../common';

import Api from '../../../helpers/api';

const api = Api();

const AirlinePoCInfoGroup = ({
	uniqueKey,
	data,
	getFieldDecorator,
	onDeleteAirlinePoC,
}) => {
	return (
		<div
			style={{
				border: '1px dashed #eee',
				marginBottom: '5px',
				textAlign: 'left',
			}}
		>
			<Row
				type="flex"
				justify="end"
				align="middle"
				style={{ ...rowStyle, padding: '5px' }}
			>
				<DeleteButton
					uniqueKey={uniqueKey}
					onDelete={onDeleteAirlinePoC}
				/>
			</Row>
			<Row
				type="flex"
				justify="start"
				align="middle"
				style={{ ...rowStyle, padding: '5px' }}
			>
				<Col span={8} offset={2}>
					<Form.Item
						hasFeedback
						label="Airline Name"
						style={{ marginBottom: 0 }}
					>
						{getFieldDecorator(`airlineName${uniqueKey}`, {
							initialValue: data.airlineName,
							rules: [
								{
									required: true,
									message:
										'Please enter a valid airline name',
								},
								{
									whitespace: true,
									message:
										'Please enter a valid airline name',
								},
								{
									type: 'string',
									message:
										'Please enter a valid airline name',
								},
							],
						})(
							<Input
								id={`airlineName${uniqueKey}`}
								placeholder="Airline Name"
							/>,
						)}
					</Form.Item>
				</Col>
			</Row>
			<Row
				type="flex"
				justify="start"
				align="middle"
				style={{ ...rowStyle, padding: '5px' }}
			>
				<Col span={8} offset={2}>
					<Form.Item
						hasFeedback
						label="First Name"
						style={{ marginBottom: 0 }}
					>
						{getFieldDecorator(`firstName${uniqueKey}`, {
							initialValue: data.firstName,
							rules: [
								{
									required: true,
									message: 'Please enter a valid first name',
								},
								{
									whitespace: true,
									message: 'Please enter a valid first name',
								},
								{
									type: 'string',
									message: 'Please enter a valid first name',
								},
							],
						})(
							<Input
								id={`firstName${uniqueKey}`}
								placeholder="First Name"
							/>,
						)}
					</Form.Item>
				</Col>
				<Col span={8} offset={4}>
					<Form.Item
						hasFeedback
						label="Last Name"
						style={{ marginBottom: 0 }}
					>
						{getFieldDecorator(`lastName${uniqueKey}`, {
							initialValue: data.lastName,
							rules: [
								{
									required: true,
									message: 'Please enter a valid last name',
								},
								{
									whitespace: true,
									message: 'Please enter a valid last name',
								},
								{
									type: 'string',
									message: 'Please enter a valid last name',
								},
							],
						})(
							<Input
								id={`lastName${uniqueKey}`}
								placeholder="Last Name"
							/>,
						)}
					</Form.Item>
				</Col>
			</Row>
			<Row
				type="flex"
				justify="start"
				align="middle"
				style={{ ...rowStyle, padding: '5px' }}
			>
				<Col span={8} offset={2}>
					<Form.Item
						hasFeedback
						label="PoC Contact Email"
						style={{ marginBottom: 0 }}
					>
						{getFieldDecorator(`email${uniqueKey}`, {
							initialValue: data.email,
							rules: [
								{
									required: true,
									message:
										'Please enter a valid email address',
								},
								{
									whitespace: true,
									message:
										'Please enter a valid email address',
								},
								{
									type: 'email',
									message:
										'Please enter a valid email address',
								},
							],
						})(
							<Input
								id={`email${uniqueKey}`}
								placeholder="PoC Contact Email"
							/>,
						)}
					</Form.Item>
				</Col>
				<Col span={8} offset={4}>
					<Form.Item
						hasFeedback
						label="PoC Contact Number"
						style={{ marginBottom: 0 }}
					>
						{getFieldDecorator(`mobile${uniqueKey}`, {
							initialValue: data.mobile,
							rules: [
								{
									required: true,
									message:
										'Please enter a valid mobile number',
								},
								{
									whitespace: true,
									message:
										'Please enter a valid mobile number',
								},
								{
									type: 'string',
									len: 10,
									message:
										'Please enter a valid mobile number',
								},
							],
						})(
							<Input
								id={`mobile${uniqueKey}`}
								placeholder="PoC Contact Number"
							/>,
						)}
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};

class step8b extends Component {
	constructor(props) {
		super(props);

		const { isInOnboarding = false, stepState = {} } = this.props;

		this.state = {
			isInOnboarding,
			isComplete: isInOnboarding ? stepState.isComplete : false,
			isSkipped: isInOnboarding ? stepState.isSkipped : false,
			isSkippable: isInOnboarding ? stepState.isSkippable : false,
			submittingForm: false,
			airlinePoCInfo: [],
			isDataFetched: false,
		};
	}

	componentDidMount() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;

		if (!isInOnboarding || (isInOnboarding && stepState.isActive)) {
			this.fetchAirlinePoCInfo('Test-ID1234')
				.then(resp => {
					if (!resp.length) {
						this.setState({
							isDataFetched: true,
							airlinePoCInfo: [
								{
									airlineName: '',
									firstName: '',
									lastName: '',
									email: '',
									mobile: '',
								},
							],
						});
					}

					this.setState(prevState => ({
						isDataFetched: true,
						airlinePoCInfo: [...prevState.airlinePoCInfo, ...resp],
					}));
					this.props.form.validateFields();
				})
				.catch(err => console.log(err));
		}
	}

	componentDidUpdate() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;
		const { isDataFetched, airlinePoCInfo } = this.state;

		if (isInOnboarding && stepState.isActive && !isDataFetched) {
			this.fetchAirlinePoCInfo('Test-ID1234')
				.then(resp => {
					if (!resp.length) {
						this.setState({
							isDataFetched: true,
							airlinePoCInfo: [
								...airlinePoCInfo,
								{
									airlineName: '',
									firstName: '',
									lastName: '',
									email: '',
									mobile: '',
								},
							],
						});
					}

					this.setState({
						isDataFetched: true,
						airlinePoCInfo: [...airlinePoCInfo, ...resp],
					});
					this.props.form.validateFields();
				})
				.catch(err => console.log(err));
		}
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.isInOnboarding && nextProps.stepState.isActive !== true) {
			return false;
		}

		return true;
	}

	fetchAirlinePoCInfo = async organizationId => {
		const apiUrl = `${api.api}/onboarding/airline-details?organizationId=${organizationId}`;

		const apiResponse = await axios.get(apiUrl);

		return apiResponse.data.data;
	};

	// Method to add new entity.
	onAddAirlinePoCInfoGroup = e => {
		this.setState(prevState => ({
			airlinePoCInfo: [
				...prevState.airlinePoCInfo,
				{
					airlineName: '',
					firstName: '',
					lastName: '',
					email: '',
					mobile: '',
				},
			],
		}));
	};

	onDeleteAirlinePoCInfoGroup = index => e => {
		this.setState(prevState => {
			const airlinePoCInfo = cloneDeep(prevState.airlinePoCInfo);
			airlinePoCInfo.splice(index, 1);

			return {
				airlinePoCInfo: [...airlinePoCInfo],
			};
		});
	};

	onSubmit = e => {
		e.preventDefault();

		const { domain, workspace, email } = this.props;

		this.setState({ submittingForm: true });

		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
				const apiUrl = `${api.api}/onboarding/airline-details`;

				const airlineDetails = this.state.airlinePoCInfo.map(
					(_, i) => ({
						airlineName: values[`airlineName${i}`],
						firstName: values[`firstName${i}`],
						lastName: values[`lastName${i}`],
						email: values[`email${i}`],
						mobile: values[`mobile${i}`],
					}),
				);

				axios
					.put(apiUrl, {
						organizationId: 'Test-ID1234',
						lastUpdatedEmail: email,
						customerDomain: domain,
						customerWorkspace: workspace,
						airlineDetails,
					})
					.then(resp => {
						this.setState({ submittingForm: false });
						message.success('Data Successfully Updated!');

						if (this.props.isInOnboarding) {
							this.props.onStepCompletion();
						}
					})
					.catch(err => console.log(err));
			} else {
				this.setState({ submittingForm: false });
			}
		});
	};

	render() {
		const {
			airlinePoCInfo,
			submittingForm,
			isInOnboarding,
			isSkippable,
		} = this.state;
		const { onStepSkip } = this.props;

		const { getFieldDecorator } = this.props.form;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
			},
			wrapperCol: {
				xs: { span: 24 },
			},
		};

		return (
			<Form
				{...formItemLayout}
				layout="vertical"
				onSubmit={this.onSubmit}
			>
				{airlinePoCInfo.length !== 0 &&
					airlinePoCInfo.map((poc, i) => {
						return (
							<AirlinePoCInfoGroup
								key={i}
								uniqueKey={i}
								data={poc}
								getFieldDecorator={getFieldDecorator}
								onDeleteAirlinePoC={
									this.onDeleteAirlinePoCInfoGroup
								}
							/>
						);
					})}
				<Row
					type="flex"
					justify="start"
					align="middle"
					style={{ width: '100%', marginTop: '5px' }}
				>
					<Col>
						<AddButton
							buttonText="Add Contact"
							onClick={this.onAddAirlinePoCInfoGroup}
						/>
					</Col>
				</Row>
				<StepFooter
					isInOnboarding={isInOnboarding}
					isSkippable={isSkippable}
					isSubmittable={true}
					isLoading={submittingForm}
					onSkip={onStepSkip}
				/>
			</Form>
		);
	}
}

step8b.propTypes = {
	isInOnboarding: PropTypes.bool,
	stepState: PropTypes.object,
	onStepSkip: PropTypes.func,
	onStepCompletion: PropTypes.func,
	domain: PropTypes.string.isRequired,
	workspace: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};

export default Form.create({ name: 'airline-contact' })(step8b);
