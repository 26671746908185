import React, { Component } from 'react';
import { Form, Input, Row, Col, message } from 'antd';
import { cloneDeep } from 'lodash';

import axios from 'axios';

import PropTypes from 'prop-types';

import { DetailsGroup, AddButton, StepFooter, validateGSTN } from '../common';

import Api from '../../../helpers/api';

const api = Api();

class Step10 extends Component {
	constructor(props) {
		super(props);

		const { isInOnboarding = false, stepState = {} } = this.props;

		this.state = {
			isInOnboarding,
			isComplete: isInOnboarding ? stepState.isComplete : false,
			isSkipped: isInOnboarding ? stepState.isSkipped : false,
			isSkippable: isInOnboarding ? stepState.isSkippable : false,
			submittingForm: false,
			billToGstinNumber: '',
			paymentsInfo: [
				{
					firstName: '',
					lastName: '',
					designation: '',
					department: '',
					email: '',
					mobile: '',
				},
			],
			isDataFetched: false,
		};
	}

	componentDidMount() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;

		if (!isInOnboarding || (isInOnboarding && stepState.isActive)) {
			this.fetchPaymentsInfo('Test-ID1234')
				.then(resp => {
					if (resp.length) {
						this.setState({
							isDataFetched: true,
							billToGstinNumber: resp[0].billToGstinNumber,
							paymentsInfo: [...resp],
						});
						this.props.form.validateFields();
					}
				})
				.catch(err => console.log(err));
		}
	}

	componentDidUpdate() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;
		const { isDataFetched } = this.state;

		if (isInOnboarding && stepState.isActive && !isDataFetched) {
			this.fetchPaymentsInfo('Test-ID1234')
				.then(resp => {
					if (resp.length) {
						this.setState(prevState => ({
							isDataFetched: true,
							billToGstinNumber: resp[0].billToGstinNumber,
							paymentsInfo: [...resp],
						}));
						this.props.form.validateFields();
					}
				})
				.catch(err => console.log(err));
		}
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.isInOnboarding && nextProps.stepState.isActive !== true) {
			return false;
		}

		return true;
	}

	fetchPaymentsInfo = async organizationId => {
		const apiUrl = `${api.api}/onboarding/payments-info?organizationId=${organizationId}`;

		const apiResponse = await axios.get(apiUrl);

		return apiResponse.data.data;
	};

	onAddPaymentsGroup = () => {
		this.setState(prevState => ({
			paymentsInfo: [
				...prevState.paymentsInfo,
				{
					firstName: '',
					lastName: '',
					designation: '',
					department: '',
					email: '',
					mobile: '',
				},
			],
		}));
	};

	onDeletePaymentsGroup = userIndex => e => {
		this.setState(prevState => {
			const paymentsInfo = cloneDeep(prevState.paymentsInfo);
			paymentsInfo.splice(userIndex, 1);

			return { paymentsInfo: [...prevState.paymentsInfo] };
		});
	};

	validateGSTN = (rule, value, callback) => {
		const { isValid, message } = validateGSTN(value);

		if (!isValid) {
			callback(message);
		} else {
			callback();
		}
	};

	onSubmit = e => {
		e.preventDefault();

		const { domain, workspace, email } = this.props;

		this.setState({ submittingForm: true });

		const apiUrl = `${api.api}/onboarding/payments-info`;

		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);

				const paymentsInfo = this.state.paymentsInfo.map((_, i) => ({
					firstName: values[`firstName${i}`],
					lastName: values[`lastName${i}`],
					designation: values[`designation${i}`],
					department: values[`department${i}`],
					email: values[`email${i}`],
					mobile: values[`mobile${i}`],
				}));

				axios
					.put(apiUrl, {
						organizationId: 'Test-ID1234',
						lastUpdatedEmail: email,
						customerDomain: domain,
						customerWorkspace: workspace,
						billToGstinNumber: values.billToGstinNumber,
						paymentsInfo,
					})
					.then(resp => {
						this.setState({ submittingForm: false });
						message.success('Data Successfully Updated!');

						if (this.state.isInOnboarding) {
							this.props.onStepCompletion();
						}
					})
					.catch(err => console.log(err));
			} else {
				this.setState({ submittingForm: false });
			}
		});
	};

	render() {
		const {
			submittingForm,
			isInOnboarding,
			isSkippable,
			billToGstinNumber,
			paymentsInfo,
		} = this.state;
		const { onStepSkip } = this.props;

		const { getFieldDecorator } = this.props.form;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
			},
			wrapperCol: {
				xs: { span: 24 },
			},
		};

		return (
			<Form
				{...formItemLayout}
				layout="vertical"
				onSubmit={this.onSubmit}
			>
				<Row
					type="flex"
					justify="start"
					align="middle"
					style={{ width: '100%', padding: '10px 0' }}
				>
					<Col span={12}>
						<Form.Item
							label="Bill To GSTIN Number"
							style={{ marginBottom: 0 }}
							hasFeedback
						>
							{getFieldDecorator('billToGstinNumber', {
								initialValue: billToGstinNumber,
								rules: [
									{
										required: true,
										message:
											'Please enter a valid GSTIN number',
									},
									{
										type: 'string',
										whitespace: false,
										message:
											'Please enter a valid GSTIN number',
									},
									{
										validator: this.validateGSTN,
									},
								],
							})(
								<Input
									id="billToGstinNumber"
									placeholder="Bill To GSTIN Number"
								/>,
							)}
						</Form.Item>
					</Col>
				</Row>
				{paymentsInfo &&
					paymentsInfo.map((user, i) => (
						<DetailsGroup
							key={i}
							uniqueKey={i}
							groupHeading={`User ${i + 1}`}
							groupType=""
							details={user}
							getFieldDecorator={getFieldDecorator}
							onDelete={this.onDeletePaymentsGroup}
						/>
					))}
				<Row
					type="flex"
					justify="start"
					align="middle"
					style={{ width: '100%' }}
				>
					<Col>
						<AddButton
							buttonText="Add Member"
							onClick={this.onAddPaymentsGroup}
						/>
					</Col>
				</Row>
				<StepFooter
					isInOnboarding={isInOnboarding}
					isSkippable={isSkippable}
					isSubmittable={true}
					isLoading={submittingForm}
					onSkip={onStepSkip}
				/>
			</Form>
		);
	}
}

Step10.propTypes = {
	isInOnboarding: PropTypes.bool,
	stepState: PropTypes.object,
	onStepSkip: PropTypes.func,
	onStepCompletion: PropTypes.func,
	domain: PropTypes.string.isRequired,
	workspace: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};

export default Form.create({ name: 'payments-info' })(Step10);
