import React from 'react';

import { Menu, Icon } from 'antd';

// const { SubMenu } = Menu;

export const AccountsUIMenuContainer = ({
	openKeys,
	onSubMenuClick,
	onMenuItemClick,
}) => {
	return (
		<Menu
			theme="light"
			mode="inline"
			defaultSelectedKeys={['1']}
			openKeys={openKeys}
			style={{ textAlign: 'left' }}
		>
			<Menu.Item key="1" onClick={onMenuItemClick(1)}>
				<Icon type="security-scan" style={{ textAlign: 'left' }} />
				<span style={{ fontWeight: 'bold' }}>Password Reset</span>
			</Menu.Item>
			{/* <Menu.Item key="1" onClick={onMenuItemClick(1)}>
				<Icon type="apartment" style={{ textAlign: 'left' }} />
				<span style={{ fontWeight: 'bold' }}>Organization GSTINs</span>
			</Menu.Item>
			<SubMenu
				key="sub1"
				style={{
					padding: '0 !important',
					textAlign: 'left',
				}}
				title={
					<span style={{ fontWeight: 'bold' }}>
						<Icon type="cloud-upload" />
						<span>Upload Data</span>
					</span>
				}
				onTitleClick={onSubMenuClick('sub1')}
			>
				<Menu.Item key="2" onClick={onMenuItemClick(2)}>
					Booking Data
				</Menu.Item>
				<Menu.Item key="3" onClick={onMenuItemClick(3)}>
					GSTR2A
				</Menu.Item>
				<Menu.Item key="4" onClick={onMenuItemClick(4)}>
					Claimed Credit
				</Menu.Item>
				<Menu.Item key="5" onClick={onMenuItemClick(5)}>
					PST Invoices
				</Menu.Item>
				<Menu.Item key="6" onClick={onMenuItemClick(6)}>
					SSR Update
				</Menu.Item>
			</SubMenu>
			<SubMenu
				key="sub2"
				title={
					<span style={{ fontWeight: 'bold' }}>
						<Icon type="audit" />
						<span>Airlines Info</span>
					</span>
				}
				onTitleClick={onSubMenuClick('sub2')}
			>
				<Menu.Item key="7" onClick={onMenuItemClick(7)}>
					TMC UI
				</Menu.Item>
				<Menu.Item key="8a" onClick={onMenuItemClick('8a')}>
					Airline Authorization
				</Menu.Item>
				<Menu.Item key="8b" onClick={onMenuItemClick('8b')}>
					Airline Contact
				</Menu.Item>
				<Menu.Item key="9" onClick={onMenuItemClick(9)}>
					Air India Login
				</Menu.Item>
			</SubMenu>
			<SubMenu
				key="sub3"
				title={
					<span style={{ fontWeight: 'bold' }}>
						<Icon type="credit-card" />
						<span>Payment Details</span>
					</span>
				}
				onTitleClick={onSubMenuClick('sub3')}
			>
				<Menu.Item key="10" onClick={onMenuItemClick(10)}>
					Payments Info
				</Menu.Item>
				<Menu.Item key="11" onClick={onMenuItemClick(11)}>
					Payment UI
				</Menu.Item>
			</SubMenu>
			<SubMenu
				key="sub4"
				title={
					<span style={{ fontWeight: 'bold' }}>
						<Icon type="usergroup-add" />
						<span>Team And More</span>
					</span>
				}
				onTitleClick={onSubMenuClick('sub4')}
			>
				<Menu.Item key="12" onClick={onMenuItemClick(12)}>
					Add Team
				</Menu.Item>
				<Menu.Item key="13" onClick={onMenuItemClick(13)}>
					SPOC and Escalations
				</Menu.Item>
				<Menu.Item key="14" onClick={onMenuItemClick(14)}>
					TMC SPOC Details
				</Menu.Item>
				<Menu.Item key="15" onClick={onMenuItemClick(15)}>
					Travel Team Details
				</Menu.Item>
				<Menu.Item key="16" onClick={onMenuItemClick(16)}>
					Tax Contact Details
				</Menu.Item>
			</SubMenu> */}
		</Menu>
	);
};
