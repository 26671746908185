import React from 'react';

import { Menu, Icon } from 'antd';

const openGst2a = (workspace, email) => {
	const url = `${window.location.origin}/2a-recon/excelJson/${
		workspace ? workspace + '/' : ''
	}${email}/`;
	const win = window.open(url, '_blank');
	win.focus();
};

export const UploadUIMenuContainer = ({
	onMenuItemClick,
	workspace,
	email,
}) => {
	return (
		<Menu
			theme="light"
			mode="inline"
			defaultSelectedKeys={['1']}
			style={{ textAlign: 'left' }}
		>
			<Menu.Item key="1" onClick={onMenuItemClick(1)}>
				<Icon type="apartment" style={{ textAlign: 'left' }} />
				<span style={{ fontWeight: 'bold' }}>Booking Data</span>
			</Menu.Item>
			<Menu.Item key="2" onClick={onMenuItemClick(2)}>
				<Icon type="apartment" style={{ textAlign: 'left' }} />
				<span style={{ fontWeight: 'bold' }}>HMPR Data</span>
			</Menu.Item>
			<Menu.Item key="3" onClick={onMenuItemClick(3)}>
				<Icon type="apartment" style={{ textAlign: 'left' }} />
				<span style={{ fontWeight: 'bold' }}>Invoices</span>
			</Menu.Item>
			<Menu.Item onClick={() => openGst2a(workspace, email)}>
				<Icon type="apartment" style={{ textAlign: 'left' }} />
				<span style={{ fontWeight: 'bold' }}>GSTR2a</span>
			</Menu.Item>
		</Menu>
	);
};
