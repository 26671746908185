import { FETCH_ALL_PURCHASE_ORDERS_SUCCESS } from '../actions';

export default function purchaseorders(state = [], action) {
	switch (action.type) {
		case FETCH_ALL_PURCHASE_ORDERS_SUCCESS:
			return action.purchaseorders.purchaseorders;
		default:
			return state;
	}
}
