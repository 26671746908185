import { FETCH_DOC_TYPES } from '../actions';

const initialState = {
  docs: [],
  isLoaded: false
};

export default function doctypes(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOC_TYPES:
      return { docs: action.payload, isLoaded: true };
    default:
      return state;
  }
}
