import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import {
	Form,
	// Row,
	// Col,
	message,
} from 'antd';
import { DetailsGroup, StepFooter } from '../common';
import Api from '../../../helpers/api';
// import Video from './Video';
const api = Api();

class Step16 extends Component {
	constructor(props) {
		super(props);

		const { isInOnboarding = false, stepState = {} } = this.props;

		this.state = {
			isInOnboarding,
			isComplete: isInOnboarding ? stepState.isComplete : false,
			isSkipped: isInOnboarding ? stepState.isSkipped : false,
			isSkippable: isInOnboarding ? stepState.isSkippable : false,
			submittingForm: false,
			taxContactInfo: {
				spoc: {
					firstName: '',
					lastName: '',
					designation: '',
					department: '',
					email: '',
					mobile: '',
				},
				escalation1: {
					firstName: '',
					lastName: '',
					designation: '',
					department: '',
					email: '',
					mobile: '',
				},
				escalation2: {
					firstName: '',
					lastName: '',
					designation: '',
					department: '',
					email: '',
					mobile: '',
				},
			},
			isModalVisible: false,
			isDataFetched: false,
		};
	}

	componentDidMount() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;

		if (!isInOnboarding || (isInOnboarding && stepState.isActive)) {
			this.fetchSpocInfo('Test-ID1234')
				.then(resp => {
					if (Object.keys(resp).length) {
						this.setState({
							isDataFetched: true,
							taxContactInfo: { ...resp },
						});
						this.props.form.validateFields();
					}
				})
				.catch(err => console.log(err));
		}
	}

	componentDidUpdate() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;
		const { isDataFetched } = this.state;

		if (isInOnboarding && stepState.isActive && !isDataFetched) {
			this.fetchSpocInfo('Test-ID1234')
				.then(resp => {
					if (Object.keys(resp).length) {
						this.setState({
							isDataFetched: true,
							taxContactInfo: { ...resp },
						});
						this.props.form.validateFields();
					}
				})
				.catch(err => console.log(err));
		}
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.isInOnboarding && nextProps.stepState.isActive !== true) {
			return false;
		}
		return true;
	}

	fetchSpocInfo = async organizationId => {
		const apiUrl = `${api.api}/onboarding/tax-contact?organizationId=${organizationId}`;
		const apiResponse = await axios.get(apiUrl);
		return apiResponse.data.data;
	};

	onSubmit = e => {
		e.preventDefault();
		const { domain, workspace, email } = this.props;
		this.setState({ submittingForm: true });
		const apiUrl = `${api.api}/onboarding/tax-contact`;

		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
				axios
					.put(apiUrl, {
						organizationId: 'Test-ID1234',
						lastUpdatedEmail: email,
						customerDomain: domain,
						customerWorkspace: workspace,
						taxContactInfo: this.state.taxContactInfo,
					})
					.then(resp => {
						this.setState({ submittingForm: false });
						message.success('Data Successfully Updated!');

						if (this.state.isInOnboarding) {
							this.props.onStepCompletion();
						}
					})
					.catch(err => console.log(err));
			} else {
				this.setState({ submittingForm: false });
			}
		});
	};

	toggleModal = () => {
		this.setState(prevState => ({
			isModalVisible: !prevState.isModalVisible,
		}));
	};

	render() {
		const {
			submittingForm,
			isInOnboarding,
			isSkippable,
			taxContactInfo,
		} = this.state;
		const { onStepSkip } = this.props;

		const { getFieldDecorator } = this.props.form;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
			},
			wrapperCol: {
				xs: { span: 24 },
			},
		};

		return (
			<Form
				{...formItemLayout}
				layout="vertical"
				onSubmit={this.onSubmit}
			>
				{/* <Row style={{ width: '100%' }}>
					<Col span={12}></Col>
					<Col
						span={12}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center',
						}}
					>
						<Video
							thumbnail={
								'https://i.picsum.photos/id/770/320/180.jpg'
							}
							videoFileName="placeholder-video"
							modalTitle="Test Tutorial Video"
							isModalVisible={this.state.isModalVisible}
							onPlay={this.toggleModal}
							onModalCancel={this.toggleModal}
						/>
					</Col>
				</Row> */}

				<DetailsGroup
					groupHeading="SPOC Details"
					groupType="spoc"
					details={taxContactInfo.spoc}
					getFieldDecorator={getFieldDecorator}
				/>
				<DetailsGroup
					groupHeading="Escalation 1 Details"
					groupType="escalation1"
					details={taxContactInfo.escalation1}
					getFieldDecorator={getFieldDecorator}
				/>
				<DetailsGroup
					groupHeading="Escalation 2 Details"
					groupType="escalation2"
					details={taxContactInfo.escalation2}
					getFieldDecorator={getFieldDecorator}
				/>
				<StepFooter
					isInOnboarding={isInOnboarding}
					isSkippable={isSkippable}
					isSubmittable={true}
					isLoading={submittingForm}
					onSkip={onStepSkip}
				/>
			</Form>
		);
	}
}

Step16.propTypes = {
	isInOnboarding: PropTypes.bool,
	stepState: PropTypes.object,
	onStepSkip: PropTypes.func,
	onStepCompletion: PropTypes.func,
	domain: PropTypes.string.isRequired,
	workspace: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};

export default Form.create({ name: 'tax-contact' })(Step16);
