export const admins = [
  "nikitaajwani@kgrp.in",
  "support@shopelect.com",
  "rohit@kgrp.in",
  "harsh.chatterji@kgrp.in",
  "jrishabh55@gmail.com",
  "sids.aquarius@gmail.com"
];

export default admins;
