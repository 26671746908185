import { SET_WORKSPACE } from '../actions';

export default function setCustomerDomain(state = {}, action) {
	switch (action.type) {
		case SET_WORKSPACE:
			return { ...action.workspace };
		default:
			return state;
	}
}
