import React from 'react';
import { Form, Row, Col, Button, Icon, Input } from 'antd';

export const InitialScreen = () => {
	return (
		<Row
			type="flex"
			justify="center"
			align="middle"
			style={{
				...rowStyle,
				marginTop: '75px',
				height: 'calc(100vh - 75px)',
				transition: 'none',
			}}
		>
			<Col span={24}>
				<h2
					style={{
						margin: 0,
						padding: 0,
					}}
				>
					Please select a workspace.
				</h2>
			</Col>
		</Row>
	);
};

export const InputElement = ({
	type = 'text',
	placeholder = '',
	name = '',
	label = '',
	uniqueKey = '',
	value = '',
	onInput = () => {},
}) => {
	return (
		<div style={{ width: '100%' }}>
			<label htmlFor={name + uniqueKey} style={labelStyle}>
				{label ? label : placeholder}
				<MandatoryMarker />
			</label>
			<Input
				placeholder={placeholder}
				type={type}
				name={uniqueKey !== '' ? name + '_' + uniqueKey : name}
				id={name}
				value={value}
				onInput={uniqueKey !== '' ? onInput(uniqueKey) : onInput}
				required
			/>
		</div>
	);
};

export const MandatoryMarker = () => {
	return <span style={{ color: 'red' }}>*</span>;
};

export const PlaceholderTextBlock = props => {
	return (
		<Row
			style={{
				padding: '10px 50px 10px 50px',
			}}
		>
			<p
				style={{
					backgroundColor: '#eee5',
					padding: '10px 20px',
					lineHeight: '24px',
				}}
			>
				{props.children}
			</p>
		</Row>
	);
};

export const DownloadFormatButton = ({
	link = '',
	fileName = '',
	btnText = 'Download Format',
}) => {
	return (
		<Row>
			<a href={link} download={fileName}>
				<Button size="large" type="default" icon="download">
					{btnText}
				</Button>
			</a>
		</Row>
	);
};

export const SkipBtn = ({
	icon = 'forward',
	btnText = 'Skip',
	onStepSkip,
	disabled = false,
	visible = true,
}) => {
	return (
		<Button
			size="large"
			type="default"
			onClick={onStepSkip}
			style={{
				backgroundColor: '#ddd',
				color: '#000',
				display: visible ? 'inline-block' : 'none',
				marginLeft: '2rem',
			}}
			disabled={disabled}
		>
			{btnText}
			<Icon type={icon} />
		</Button>
	);
};

export const SubmitBtn = ({
	icon = 'check',
	loading = 'false',
	btnText = 'Submit',
	disabled = false,
}) => {
	return (
		<Button
			size="large"
			type="default"
			icon={icon}
			loading={loading}
			htmlType="submit"
			style={{
				backgroundColor: '#77dd77',
				color: '#fff',
			}}
			disabled={disabled}
		>
			{btnText}
		</Button>
	);
};

export const labelStyle = {
	fontWeight: 'bold',
	marginBottom: '10px',
	textAlign: 'left',
	width: '100%',
	textTransform: 'capitalize',
	color: '#999',
};

export const rowStyle = {
	width: '100%',
	margin: 0, // Do not remove.
	transition: 'all 1s ease-in-out',
};

export const AddButton = ({ buttonText = '', onClick }) => {
	return (
		<Button
			size="default"
			type="dashed"
			icon="plus"
			onClick={onClick}
			title="Adds new Contact."
		>
			{buttonText}
		</Button>
	);
};

export const DeleteButton = ({
	uniqueKey,
	additionalFuncArgument,
	onDelete = () => {},
}) => {
	return (
		<Button
			size="default"
			type="danger"
			icon="delete"
			onClick={
				additionalFuncArgument || additionalFuncArgument === 0
					? onDelete(additionalFuncArgument, uniqueKey)
					: onDelete(uniqueKey)
			}
			id={uniqueKey}
			title="Delete Item"
			style={{
				float: uniqueKey !== 0 ? 'right' : 'none',
				marginLeft: '15px',
				visibility: uniqueKey !== 0 ? 'visible' : 'hidden',
			}}
		/>
	);
};

export const DetailsGroup = ({
	uniqueKey = '',
	groupHeading = '',
	groupType = '',
	details = {},
	getFieldDecorator,
	onDelete,
}) => {
	return (
		<div
			style={{
				border: '1px dashed #eee',
				marginTop: '5px',
				marginBottom: '5px',
			}}
		>
			<Row
				type="flex"
				justify="start"
				align="middle"
				style={{
					width: '100%',
					margin: '10px',
				}}
			>
				<Col span={uniqueKey !== 0 && uniqueKey !== '' ? 12 : 24}>
					<h2
						style={{
							textAlign: 'left',
							fontWeight: 'bold',
							margin: 0,
						}}
					>
						{groupHeading}:
					</h2>
				</Col>
				{uniqueKey !== 0 && uniqueKey !== '' && (
					<Col
						span={12}
						style={{
							paddingRight: '15px',
						}}
					>
						<DeleteButton
							uniqueKey={uniqueKey}
							onDelete={onDelete}
						/>
					</Col>
				)}
			</Row>
			<Row
				type="flex"
				justify="center"
				align="middle"
				style={{
					width: '100%',
				}}
			>
				<Row
					type="flex"
					justify="start"
					align="middle"
					style={{
						width: '100%',
					}}
				>
					<Col span={8} style={{ padding: '10px' }} offset={2}>
						<Form.Item
							hasFeedback
							label="First Name"
							style={{ marginBottom: 0 }}
						>
							{getFieldDecorator(
								groupType
									? `${groupType}.firstName${uniqueKey}`
									: `firstName${uniqueKey}`,
								{
									initialValue: details.firstName,
									rules: [
										{
											required: true,
											message:
												'Please enter a valid first name',
										},
										{
											whitespace: true,
											message:
												'Please enter a valid first name',
										},
										{
											type: 'string',
											message:
												'Please enter a valid first name',
										},
									],
								},
							)(
								<Input
									id={`firstName${uniqueKey}`}
									placeholder="First Name"
								/>,
							)}
						</Form.Item>
					</Col>
					<Col span={8} style={{ padding: '10px' }} offset={4}>
						<Form.Item
							hasFeedback
							label="Last Name"
							style={{ marginBottom: 0 }}
						>
							{getFieldDecorator(
								groupType
									? `${groupType}.lastName${uniqueKey}`
									: `lastName${uniqueKey}`,
								{
									initialValue: details.lastName,
									rules: [
										{
											required: true,
											message:
												'Please enter a valid last name',
										},
										{
											whitespace: true,
											message:
												'Please enter a valid last name',
										},
										{
											type: 'string',
											message:
												'Please enter a valid last name',
										},
									],
								},
							)(
								<Input
									id={`lastName${uniqueKey}`}
									placeholder="Last Name"
								/>,
							)}
						</Form.Item>
					</Col>
				</Row>
				<Row
					type="flex"
					justify="start"
					align="middle"
					style={{
						width: '100%',
					}}
				>
					<Col span={8} style={{ padding: '10px' }} offset={2}>
						<Form.Item
							hasFeedback
							label="Designation"
							style={{ marginBottom: 0 }}
						>
							{getFieldDecorator(
								groupType
									? `${groupType}.designation${uniqueKey}`
									: `designation${uniqueKey}`,
								{
									initialValue: details.designation,
									rules: [
										{
											required: true,
											message:
												'Please enter a valid designation',
										},
										{
											whitespace: true,
											message:
												'Please enter a valid designation',
										},
										{
											type: 'string',
											message:
												'Please enter a valid designation',
										},
									],
								},
							)(
								<Input
									id={`designation${uniqueKey}`}
									placeholder="Designation"
								/>,
							)}
						</Form.Item>
					</Col>
					<Col span={8} style={{ padding: '10px' }} offset={4}>
						<Form.Item
							hasFeedback
							label="Department"
							style={{ marginBottom: 0 }}
						>
							{getFieldDecorator(
								groupType
									? `${groupType}.department${uniqueKey}`
									: `department${uniqueKey}`,
								{
									initialValue: details.department,
									rules: [
										{
											required: true,
											message:
												'Please enter a valid department',
										},
										{
											whitespace: true,
											message:
												'Please enter a valid department',
										},
										{
											type: 'string',
											message:
												'Please enter a valid department',
										},
									],
								},
							)(
								<Input
									id={`department${uniqueKey}`}
									placeholder="Department"
								/>,
							)}
						</Form.Item>
					</Col>
				</Row>
				<Row
					type="flex"
					justify="start"
					align="middle"
					style={{
						width: '100%',
					}}
				>
					<Col span={8} style={{ padding: '10px' }} offset={2}>
						<Form.Item
							hasFeedback
							label="Email ID"
							style={{ marginBottom: 0 }}
						>
							{getFieldDecorator(
								groupType
									? `${groupType}.email${uniqueKey}`
									: `email${uniqueKey}`,
								{
									initialValue: details.email,
									rules: [
										{
											required: true,
											message:
												'Please enter a valid email address',
										},
										{
											whitespace: true,
											message:
												'Please enter a valid email address',
										},
										{
											type: 'email',
											message:
												'Please enter a valid email address',
										},
									],
								},
							)(
								<Input
									id={`email${uniqueKey}`}
									placeholder="Email ID"
								/>,
							)}
						</Form.Item>
					</Col>
					<Col span={8} style={{ padding: '10px' }} offset={4}>
						<Form.Item
							hasFeedback
							label="Mobile Number"
							style={{ marginBottom: 0 }}
						>
							{getFieldDecorator(
								groupType
									? `${groupType}.mobile${uniqueKey}`
									: `mobile${uniqueKey}`,
								{
									initialValue: details.mobile,
									rules: [
										{
											required: true,
											message:
												'Please enter a valid mobile number',
										},
										{
											whitespace: true,
											message:
												'Please enter a valid mobile number',
										},
										{
											type: 'string',
											len: 10,
											message:
												'Please enter a valid mobile number',
										},
									],
								},
							)(
								<Input
									id={`mobile${uniqueKey}`}
									placeholder="Mobile Number"
								/>,
							)}
						</Form.Item>
					</Col>
				</Row>
			</Row>
		</div>
	);
};

export const StepFooter = ({
	isInOnboarding = false,
	isSkippable = false,
	isSubmittable = false,
	isLoading = false,
	onSkip = () => {},
}) => {
	return (
		<React.Fragment>
			{isSubmittable ? (
				<Row
					type="flex"
					justify="end"
					align="middle"
					style={{ width: '100%', padding: '20px 0' }}
				>
					<Col span={isInOnboarding && isSkippable ? 12 : 6}>
						<SubmitBtn loading={isLoading} />
						<SkipBtn
							visible={isInOnboarding && isSkippable}
							onStepSkip={onSkip}
						/>
					</Col>
				</Row>
			) : (
				isInOnboarding &&
				isSkippable && (
					<Row
						type="flex"
						justify="end"
						align="middle"
						style={{ width: '100%', padding: '20px 0' }}
					>
						<Col span={6}>
							<SkipBtn visible={true} onClick={onSkip} />
						</Col>
					</Row>
				)
			)}
		</React.Fragment>
	);
};

const stateCodes = new Array(37)
	.fill(0)
	.map((_, i) => (i + 1 < 10 ? `0${i + 1}` : `${i + 1}`));

export const validateGSTN = gstn => {
	const pattern = new RegExp(/^[a-zA-Z0-9]+$/g);
	if (!pattern.test(gstn)) {
		return {
			isValid: false,
			message: 'GST Number can only contain alphanumeric characters',
		};
	}
	if (gstn.length !== 15) {
		return {
			isValid: false,
			message: 'GST Number needs to be exactly 15 characters long',
		};
	}
	if (!stateCodes.includes(gstn.substr(0, 2))) {
		return {
			isValid: false,
			message: 'Invalid state code entered',
		};
	}
	// ! To be used when PAN number is available to us.
	// if (gstn.substr(2, 10) !== pan) {
	// 	return {
	// 		isValid: false,
	// 		message: 'Invalid GST Number'
	// 	};
	// }
	if (gstn[13].toLocaleLowerCase() !== 'z') {
		return {
			isValid: false,
			message: 'Invalid GST Number',
		};
	}
	return {
		isValid: true,
		message: '',
	};
};
