import React, { useEffect, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Api from '../../helpers/api';

import Loading from '../Loading';

const api = Api();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Callback = ({ domain, email, setCustomerDomain }) => {
  const [ loading, setLoading ] = useState(true);
  const query = useQuery();

  useEffect(() => {
    if (!email) {
      return;
    }
    const workspace = query.get('workspace');
    const customer_domain = query.get('customer_domain');
    const hostedpage_id = query.get('hostedpage_id');
    fetch(`${api.api}/payments`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'ngrok-skip-browser-warning': 40, 
      },
      body: JSON.stringify({
        Workspace: workspace,
        Domain: domain,
        Email: email,
        Active: true,
        hostedPageId: hostedpage_id,
        'Customer Domain': customer_domain,
      })
    })
      .then(res => res.json())
      .then(res => {
        console.log(res);
        if (domain !== customer_domain && setCustomerDomain) {
          setCustomerDomain(customer_domain)
        }
        setLoading(false);
      });
  }, [email]);

  if (loading) {
    return <Loading />;
  }

  return <Redirect to="/payments" />;

}

export default connect(({ user, customerDomain }) => ({ email: user.email, domain: customerDomain }))(Callback);
