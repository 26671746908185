import { FETCH_REPORTS_SUCCESS, SET_SUB_REPORT } from '../actions';

export default function reporting(state = [], action) {
	switch (action.type) {
		case FETCH_REPORTS_SUCCESS:
			return action.reports;
		case SET_SUB_REPORT:
			return action.subReport;
		default:
			return state;
	}
}
