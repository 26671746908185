import React, { Component } from 'react';
import { Row, Col, Upload, Icon, message } from 'antd';

import PropTypes from 'prop-types';

import { PlaceholderTextBlock, StepFooter } from '../common';

import Api from '../../../helpers/api';

const { Dragger } = Upload;

const api = Api();

const props = {
	name: 'file',
	accept: '.pdf, .pst, .zip',
	multiple: true,
	action: `${api.api}/onboarding/pst-invoices`,
};

class Step5 extends Component {
	constructor(props) {
		super(props);

		const { isInOnboarding = false, stepState = {} } = this.props;

		this.state = {
			isInOnboarding,
			isComplete: isInOnboarding ? stepState.isComplete : false,
			isSkipped: isInOnboarding ? stepState.isSkipped : false,
			isSkippable: isInOnboarding ? stepState.isSkippable : false,
		};
	}

	onChange = info => {
		const { status } = info.file;
		if (status === 'done') {
			message.success(`${info.file.name} file uploaded successfully.`);

			if (this.state.isInOnboarding) {
				this.props.onStepCompletion();
			}
		} else if (status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
		}
	};

	render() {
		const { isInOnboarding, isSkippable } = this.state;
		const { onStepSkip, domain, workspace, email } = this.props;

		return (
			<React.Fragment>
				<PlaceholderTextBlock>
					Please upload your old invoice data as .zip, .pst or .pdf
					below.
				</PlaceholderTextBlock>
				<Row
					type="flex"
					justify="center"
					align="middle"
					style={{ width: '100%', padding: '20px' }}
				>
					<Col span={22}>
						<Dragger
							{...props}
							data={{
								organizationId: 'Test-ID',
								lastUpdatedEmail: email,
								customerDomain: domain,
								customerWorkspace: workspace,
							}}
							onChange={this.onChange}
							style={{
								width: '100% !important',
							}}
						>
							<p className="ant-upload-drag-icon">
								<Icon type="inbox" />
							</p>
							<p className="ant-upload-text">
								Click or drag file to this area to upload
							</p>
						</Dragger>
					</Col>
				</Row>
				<StepFooter
					isInOnboarding={isInOnboarding}
					isSkippable={isSkippable}
					onSkip={onStepSkip}
				/>
			</React.Fragment>
		);
	}
}

Step5.propTypes = {
	isInOnboarding: PropTypes.bool,
	stepState: PropTypes.object,
	onStepSkip: PropTypes.func,
	onStepCompletion: PropTypes.func,
	domain: PropTypes.string.isRequired,
	workspace: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};

export default Step5;
