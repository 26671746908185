const states = [
	{
		stateName: 'Andaman and Nicobar Islands',
		stateCode: 'AN',
		tinNo: '35',
	},
	{
		stateName: 'Andhra Pradesh',
		stateCode: 'AD',
		tinNo: '37',
	},
	{
		stateName: 'Arunachal Pradesh',
		stateCode: 'AR',
		tinNo: '12',
	},
	{
		stateName: 'Assam',
		stateCode: 'AS',
		tinNo: '18',
	},
	{
		stateName: 'Bihar',
		stateCode: 'BR',
		tinNo: '10',
	},
	{
		stateName: 'Chandigarh',
		stateCode: 'CH',
		tinNo: '04',
	},
	{
		stateName: 'Chattisgarh',
		stateCode: 'CG',
		tinNo: '22',
	},
	{
		stateName: 'Dadra and Nagar Haveli',
		stateCode: 'DN',
		tinNo: '26',
	},
	{
		stateName: 'Daman and Diu',
		stateCode: 'DD',
		tinNo: '25',
	},
	{
		stateName: 'Delhi',
		stateCode: 'DL',
		tinNo: '07',
	},
	{
		stateName: 'GOA',
		stateCode: 'GA',
		tinNo: '30',
	},
	{
		stateName: 'Gujarat',
		stateCode: 'GJ',
		tinNo: '24',
	},
	{
		stateName: 'Haryana',
		stateCode: 'HR',
		tinNo: '06',
	},
	{
		stateName: 'Himachal Pradesh',
		stateCode: 'HP',
		tinNo: '02',
	},
	{
		stateName: 'Jammu and Kashmir',
		stateCode: 'JK',
		tinNo: '01',
	},
	{
		stateName: 'Jharkhand',
		stateCode: 'JH',
		tinNo: '20',
	},
	{
		stateName: 'Karnataka',
		stateCode: 'KA',
		tinNo: '29',
	},
	{
		stateName: 'Kerala',
		stateCode: 'KL',
		tinNo: '32',
	},
	{
		stateName: 'Lakshadweep Islands',
		stateCode: 'LD',
		tinNo: '31',
	},
	{
		stateName: 'Madhya Pradesh',
		stateCode: 'MP',
		tinNo: '23',
	},
	{
		stateName: 'Maharashtra',
		stateCode: 'MH',
		tinNo: '27',
	},
	{
		stateName: 'Manipur',
		stateCode: 'MN',
		tinNo: '14',
	},
	{
		stateName: 'Meghalaya',
		stateCode: 'ML',
		tinNo: '17',
	},
	{
		stateName: 'Mizoram',
		stateCode: 'MZ',
		tinNo: '15',
	},
	{
		stateName: 'Nagaland',
		stateCode: 'NL',
		tinNo: '13',
	},
	{
		stateName: 'Nagaland',
		stateCode: 'NL',
		tinNo: '13',
	},
	{
		stateName: 'Odisha',
		stateCode: 'OD',
		tinNo: '21',
	},
	{
		stateName: 'Pondicherry',
		stateCode: 'PY',
		tinNo: '34',
	},
	{
		stateName: 'Punjab',
		stateCode: 'PB',
		tinNo: '03',
	},
	{
		stateName: 'Rajasthan',
		stateCode: 'RJ',
		tinNo: '08',
	},
	{
		stateName: 'Sikkim',
		stateCode: 'SK',
		tinNo: '11',
	},
	{
		stateName: 'Tamil Nadu',
		stateCode: 'TN',
		tinNo: '33',
	},
	{
		stateName: 'Telangana',
		stateCode: 'TS',
		tinNo: '36',
	},
	{
		stateName: 'Tripura',
		stateCode: 'TR',
		tinNo: '16',
	},
	{
		stateName: 'Uttar Pradesh',
		stateCode: 'UP',
		tinNo: '09',
	},
	{
		stateName: 'Uttarakhand',
		stateCode: 'UK',
		tinNo: '05',
	},
	{
		stateName: 'West Bengal',
		stateCode: 'WB',
		tinNo: '09',
	},
];
export default states;
