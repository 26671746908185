import React, { Component } from 'react';
import axios from 'axios';
import { cloneDeep } from 'lodash';

import { Form, Input, Row, Col, message } from 'antd';

import PropTypes from 'prop-types';

import { AddButton, DeleteButton, StepFooter, rowStyle } from '../common';

import Api from '../../../helpers/api';

const api = Api();

const TmcPoCInfoGroup = ({
	uniqueKey,
	data,
	getFieldDecorator,
	onDeleteTmcPoC,
}) => {
	return (
		<div style={{ border: '1px dashed #eee', marginBottom: '5px' }}>
			<Row
				type="flex"
				justify="end"
				align="middle"
				style={{ ...rowStyle, padding: '5px' }}
			>
				<DeleteButton uniqueKey={uniqueKey} onDelete={onDeleteTmcPoC} />
			</Row>
			<Row
				type="flex"
				justify="start"
				align="middle"
				style={{ ...rowStyle, padding: '5px' }}
			>
				<Col span={8} offset={2}>
					<Form.Item
						hasFeedback
						label="TMC Name"
						style={{ marginBottom: 0 }}
					>
						{getFieldDecorator(`tmcName${uniqueKey}`, {
							initialValue: data.tmcName,
							rules: [
								{
									required: true,
									message: 'Please enter a valid TMC name',
								},
								{
									whitespace: true,
									message: 'Please enter a valid TMC name',
								},
								{
									type: 'string',
									message: 'Please enter a valid TMC name',
								},
							],
						})(
							<Input
								id={`tmcName${uniqueKey}`}
								placeholder="TMC Name"
							/>,
						)}
					</Form.Item>
				</Col>
			</Row>
			{data.managerDetails.length !== 0 &&
				data.managerDetails.map((detail, i) => (
					<div key={i}>
						<Row
							type="flex"
							justify="start"
							align="middle"
							style={{ ...rowStyle, padding: '5px' }}
						>
							<Col span={8} offset={2}>
								<Form.Item
									hasFeedback
									label="First Name"
									style={{ marginBottom: 0 }}
								>
									{getFieldDecorator(
										`firstName${uniqueKey}`,
										{
											initialValue: detail.firstName,
											rules: [
												{
													required: true,
													message:
														'Please enter a valid first name',
												},
												{
													whitespace: true,
													message:
														'Please enter a valid first name',
												},
												{
													type: 'string',
													message:
														'Please enter a valid first name',
												},
											],
										},
									)(
										<Input
											id={`firstName${uniqueKey}`}
											placeholder="First Name"
										/>,
									)}
								</Form.Item>
							</Col>
							<Col span={8} offset={4}>
								<Form.Item
									hasFeedback
									label="Last Name"
									style={{ marginBottom: 0 }}
								>
									{getFieldDecorator(`lastName${uniqueKey}`, {
										initialValue: detail.lastName,
										rules: [
											{
												required: true,
												message:
													'Please enter a valid last name',
											},
											{
												whitespace: true,
												message:
													'Please enter a valid last name',
											},
											{
												type: 'string',
												message:
													'Please enter a valid last name',
											},
										],
									})(
										<Input
											id={`lastName${uniqueKey}`}
											placeholder="Last Name"
										/>,
									)}
								</Form.Item>
							</Col>
						</Row>
						<Row
							type="flex"
							justify="start"
							align="middle"
							style={{ ...rowStyle, padding: '5px' }}
						>
							<Col span={8} offset={2}>
								<Form.Item
									hasFeedback
									label="PoC Contact Email"
									style={{ marginBottom: 0 }}
								>
									{getFieldDecorator(`email${uniqueKey}`, {
										initialValue: detail.email,
										rules: [
											{
												required: true,
												message:
													'Please enter a valid email address',
											},
											{
												whitespace: true,
												message:
													'Please enter a valid email address',
											},
											{
												type: 'email',
												message:
													'Please enter a valid email address',
											},
										],
									})(
										<Input
											id={`email${uniqueKey}`}
											placeholder="PoC Contact Email"
										/>,
									)}
								</Form.Item>
							</Col>
							<Col span={8} offset={4}>
								<Form.Item
									hasFeedback
									label="PoC Contact Number"
									style={{ marginBottom: 0 }}
								>
									{getFieldDecorator(`mobile${uniqueKey}`, {
										initialValue: detail.mobile,
										rules: [
											{
												required: true,
												message:
													'Please enter a valid mobile number',
											},
											{
												whitespace: true,
												message:
													'Please enter a valid mobile number',
											},
											{
												type: 'string',
												len: 10,
												message:
													'Please enter a valid mobile number',
											},
										],
									})(
										<Input
											id={`mobile${uniqueKey}`}
											placeholder="PoC Contact Number"
										/>,
									)}
								</Form.Item>
							</Col>
						</Row>
						<Row
							type="flex"
							justify="start"
							align="middle"
							style={{ ...rowStyle, padding: '5px' }}
						>
							<Col span={8} offset={2}>
								<Form.Item
									hasFeedback
									label="Location"
									style={{ marginBottom: 0 }}
								>
									{getFieldDecorator(`location${uniqueKey}`, {
										initialValue: detail.location,
										rules: [
											{
												required: true,
												message:
													'Please enter a valid location',
											},
											{
												whitespace: true,
												message:
													'Please enter a valid location',
											},
											{
												type: 'string',
												message:
													'Please enter a valid location',
											},
										],
									})(
										<Input
											id={`location${uniqueKey}`}
											placeholder="Location"
										/>,
									)}
								</Form.Item>
							</Col>
						</Row>
					</div>
				))}
		</div>
	);
};

class Step7 extends Component {
	constructor(props) {
		super(props);

		const { isInOnboarding = false, stepState = {} } = this.props;

		this.state = {
			isInOnboarding,
			isComplete: isInOnboarding ? stepState.isComplete : false,
			isSkipped: isInOnboarding ? stepState.isSkipped : false,
			isSkippable: isInOnboarding ? stepState.isSkippable : false,
			submittingForm: false,
			tmcPoCInfo: [],
			isDataFetched: false,
		};
	}

	componentDidMount() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;

		if (!isInOnboarding || (isInOnboarding && stepState.isActive)) {
			this.fetchTmcPoCInfo('Test-ID1234')
				.then(resp => {
					if (!resp.length) {
						this.setState({
							isDataFetched: true,
							tmcPoCInfo: [
								{
									tmcName: '',
									tmcNumber: 1,
									managerDetails: [
										{
											tmcManagerNumber: 1,
											firstName: '',
											lastName: '',
											email: '',
											mobile: '',
											location: '',
										},
									],
								},
							],
						});
					}
					this.setState(prevState => ({
						isDataFetched: true,
						tmcPoCInfo: [...prevState.tmcPoCInfo, ...resp],
					}));
					this.props.form.validateFields();
				})
				.catch(err => console.log(err));
		}
	}

	componentDidUpdate() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;
		const { isDataFetched } = this.state;

		if (isInOnboarding && stepState.isActive && !isDataFetched) {
			this.fetchTmcPoCInfo('Test-ID1234')
				.then(resp => {
					if (!resp.length) {
						this.setState({
							isDataFetched: true,
							tmcPoCInfo: [
								{
									tmcName: '',
									tmcNumber: 1,
									managerDetails: [
										{
											tmcManagerNumber: 1,
											firstName: '',
											lastName: '',
											email: '',
											mobile: '',
											location: '',
										},
									],
								},
							],
						});
					}
					this.setState(prevState => ({
						isDataFetched: true,
						tmcPoCInfo: [...prevState.tmcPoCInfo, ...resp],
					}));
					this.props.form.validateFields();
				})
				.catch(err => console.log(err));
		}
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.isInOnboarding && nextProps.stepState.isActive !== true) {
			return false;
		}

		return true;
	}

	fetchTmcPoCInfo = async organizationId => {
		const apiUrl = `${api.api}/onboarding/tmc-details?organizationId=${organizationId}`;

		const apiResponse = await axios.get(apiUrl);

		return apiResponse.data.data;
	};

	onAddTmcPoCInfoGroup = e => {
		this.setState(prevState => ({
			tmcPoCInfo: [
				...prevState.tmcPoCInfo,
				{
					tmcName: '',
					tmcNumber: prevState.tmcPoCInfo.length + 1,
					managerDetails: [
						{
							tmcManagerNumber: 1,
							firstName: '',
							lastName: '',
							email: '',
							mobile: '',
							location: '',
						},
					],
				},
			],
		}));
	};

	onDeleteTmcPoCInfoGroup = index => e => {
		this.setState(prevState => {
			const tmcPoCInfo = cloneDeep(prevState.tmcPoCInfo);
			tmcPoCInfo.splice(index, 1);

			return {
				tmcPoCInfo: [...tmcPoCInfo],
			};
		});
	};

	onSubmit = async e => {
		e.preventDefault();

		const { domain, workspace, email } = this.props;

		this.setState({ submittingForm: true });

		const apiUrl = `${api.api}/onboarding/tmc-details`;

		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
				const tmcDetails = this.state.tmcPoCInfo.map((_, i) => ({
					tmcName: values[`tmcName${i}`],
					tmcNumber: i + 1,
					managerDetails: [
						{
							tmcManagerNumber: 1,
							firstName: values[`firstName${i}`],
							lastName: values[`lastName${i}`],
							email: values[`email${i}`],
							mobile: values[`mobile${i}`],
							location: values[`location${i}`],
						},
					],
				}));

				console.log(tmcDetails);

				axios
					.put(apiUrl, {
						organizationId: 'Test-ID1234',
						lastUpdatedEmail: email,
						customerDomain: domain,
						customerWorkspace: workspace,
						tmcDetails,
					})
					.then(resp => {
						this.setState({ submittingForm: false });
						message.success('Data Successfully Updated!');

						if (this.state.isInOnboarding) {
							this.props.onStepCompletion();
						}
					})
					.catch(err => console.log(err));
			} else {
				this.setState({ submittingForm: false });
			}
		});
	};

	render() {
		const {
			tmcPoCInfo,
			submittingForm,
			isInOnboarding,
			isSkippable,
		} = this.state;
		const { onStepSkip } = this.props;

		const { getFieldDecorator } = this.props.form;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
			},
			wrapperCol: {
				xs: { span: 24 },
			},
		};

		return (
			<Form
				{...formItemLayout}
				layout="vertical"
				onSubmit={this.onSubmit}
			>
				{tmcPoCInfo.length !== 0 &&
					tmcPoCInfo.map((poc, i) => {
						return (
							<TmcPoCInfoGroup
								key={i}
								uniqueKey={i}
								data={poc}
								getFieldDecorator={getFieldDecorator}
								onDeleteTmcPoC={this.onDeleteTmcPoCInfoGroup}
							/>
						);
					})}
				<Row
					type="flex"
					justify="start"
					align="middle"
					style={{ width: '100%', marginTop: '5px' }}
				>
					<Col>
						<AddButton
							buttonText="Add TMC"
							onClick={this.onAddTmcPoCInfoGroup}
						/>
					</Col>
				</Row>
				<StepFooter
					isInOnboarding={isInOnboarding}
					isSkippable={isSkippable}
					isSubmittable={true}
					isLoading={submittingForm}
					onSkip={onStepSkip}
				/>
			</Form>
		);
	}
}

Step7.propTypes = {
	isInOnboarding: PropTypes.bool,
	stepState: PropTypes.object,
	onStepSkip: PropTypes.func,
	onStepCompletion: PropTypes.func,
	domain: PropTypes.string.isRequired,
	workspace: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};

export default Form.create({ name: 'tmc-ui' })(Step7);
