import { FETCH_ALL_CONTACTS_SUCCESS } from '../actions';

export default function contacts(state = [], action) {
	switch (action.type) {
		case FETCH_ALL_CONTACTS_SUCCESS:
			return action.contacts.contacts;
		default:
			return state;
	}
}
