import styled from 'styled-components';

const MainContainer = styled.section`
	display: flex;
	margin: 0;
	padding: 0;
	width: 100%;
`;

export default MainContainer;
