import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
	Row,
	Col,
	Select,
	Table,
	Input,
	Button,
	Radio,
	message,
	Spin,
} from 'antd';
import { isEmpty, pickBy } from 'lodash';
import Loading from '../Loading';
import FullPageMessage from '../FullPageMessage';
import Api from '../../helpers/api';
import Auth from '../../services/Auth';
import { formatData } from '../../helpers';

import './paymentspage.css';

const { Option } = Select;

export const getHeaders = () => {
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'ngrok-skip-browser-warning': 40, 
	};
	return headers;
};

const columns = [
	{
		title: 'Title',
		dataIndex: 'title',
		key: 'id',
	},
	{
		title: 'Value',
		dataIndex: 'value',
		key: 'value',
	},
];

const radioStyle = {
	display: 'block',
	height: '30px',
	lineHeight: '30px',
};

const api = Api();
const auth = new Auth();

const fetchInvoiceData = domain =>
	fetch(`${api.api}/customerDetails/invoiceData?domain=${domain}`, {
		method: 'GET',
		headers: new Headers({
			'Content-Type': 'application/json',
			'ngrok-skip-browser-warning': 40, 
		}),
	})
		.then(res => res.json())
		.catch(error => console.error('Error:', error));

const quarters = [
	'Apr 18 - Jun 18',
	'Jul 18 - Sep 18',
	'Oct 18 - Dec 18',
	'Jan 19 - Mar 19',
];

export class PaymentsPage extends Component {
	state = {
		referralDiscount: 0,
		referralCode: '',
		referralError: false,
		isPaid: false,
		data: [],
		availablePan: [],
		selectedPans: [],
		loading: true,
		paymentMode: null,
		selectedQuarters: quarters,
		quarters,
		dataSource: [],
		totalPayable: 0,
		monthlyPay: 0,
		response: {},
	};

	getSubStatus() {
		const { Workspace_Name } = this.props.workspace;
		return fetch(`${api.api}/payments?workspace=${Workspace_Name}`, {
			headers: getHeaders()
		  }).then(
			res => res.json(),
		);
	}

	componentDidMount() {
		this.getSubStatus().then(res => {
			if (res.status === 'ERROR') {
				this.getInvoiceData();
			} else {
				this.setState({ isPaid: true, loading: false });
			}
		});
	}

	getInvoiceData = () => {
		return fetchInvoiceData(this.props.customer_domain).then(({ data }) => {
			const obj = {};

			const body = formatData(data.column_order, data.rows);
			body.forEach(row => {
				if (!obj[row.Customer_Pan]) {
					obj[row.Customer_Pan] = {
						Customer_Pan: row.Customer_Pan,
						Domain: row.Domain,
						Total_GST: Number(row.Total_GST),
						Invoice_Date: moment(row.Invoice_Date, 'dd-MM-yy'),
					};
				} else {
					obj[row.Customer_Pan].Total_GST += Number(row.Total_GST);
				}
			});

			this.setState({ data: obj, loading: false }, () => {
				this.setState(
					{ selectedPans: Object.keys(this.availablePans) },
					() => {
						this.updateBilling();
					},
				);
			});
		});
	};

	onSelectChange = pans => {
		this.setState({ selectedPans: pans }, () => {
			this.updateBilling();
		});
	};

	paymentForm = async e => {
		if (e) {
			e.preventDefault();
		}
		this.setState({ loading: true });
		const { monthlyPay } = this.state;
		const { workspace, customer_domain } = this.props;

		const contact_id = auth.getContactId();

		const body = {
			redirect_url: `${window.location.origin}/payments/callback?workspace=${workspace.Workspace_Name}&customer_domain=${customer_domain}`,
			plan: {
				plan_code: 'test1',
			},
			addons: [
				{
					addon_code: 'Test',
					name: 'GSTINPUT',
					addon_description: 'Monthly addon.',
					quantity: Number(Number(monthlyPay).toFixed(0)),
					price: 1,
					discount: 0,
					total: Number(monthlyPay),
				},
			],
			customer_id: contact_id,
		};

		const response = await fetch(`${api.api}/subscription/create`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				'content-type': 'application/json',
				'ngrok-skip-browser-warning': 40, 
			},
		})
			.then(res => res.json())
			.then(res => {
				console.log('subscription creation response: ', res);
				return res;
			});

		this.setState({ response, loading: false }, () => {
			// callback();
		});
	};

	updateBilling = () => {
		const {
			selectedPans,
			data,
			referralDiscount,
			selectedQuarters,
		} = this.state;
		const quartersLength = selectedQuarters.length;
		let paymentMonths = 0;

		let totalPayable = selectedPans.reduce((acc, item) => {
			return acc + data[item].Total_GST;
		}, 0);

		switch (quartersLength) {
			case 0:
				paymentMonths = 0;
				break;
			case 1:
				paymentMonths = 2;
				totalPayable *= 0.2;
				break;
			case 2:
				paymentMonths = 3;
				totalPayable *= 0.15;
				break;
			default:
				paymentMonths = 6;
				totalPayable *= 0.1;
		}

		totalPayable *= 1 - referralDiscount / 100;
		totalPayable = totalPayable.toFixed(2);

		if (totalPayable >= 0 && totalPayable <= 600) {
			if (totalPayable >= 300) {
				paymentMonths = 3;
			} else if (totalPayable >= 200) {
				paymentMonths = 2;
			} else {
				paymentMonths = 1;
			}
		}

		let monthlyPay =
			totalPayable > 0 ? (totalPayable / paymentMonths).toFixed(2) : 0;

		this.setState({ totalPayable, monthlyPay, paymentMonths });
	};

	dataSource() {
		const {
			totalPayable,
			monthlyPay,
			referralDiscount,
			paymentMonths,
		} = this.state;
		return {
			dataSource: [
				{
					key: '1',
					title: 'Minimum Months Commitment',
					value: paymentMonths,
				},
				{
					key: '2',
					title: 'Gross',
					value: `${totalPayable} INR`,
				},
				{
					key: '3',
					title: 'Less Discount',
					value: referralDiscount,
				},
				{
					key: '4',
					title: 'Subscription Payable',
					value: `${totalPayable} INR`,
				},
				{
					key: '5',
					title: 'Monthly',
					value: `${monthlyPay} INR`,
				},
			],
		};
	}

	onChange = key => e => {
		this.setState({
			[key]: e.target.value,
		});
	};

	applyReferralCode = e => {
		if (e) e.preventDefault();
		const { referralCode, referralDiscount } = this.state;
		if (isEmpty(referralCode)) {
			if (referralDiscount !== 0) {
				this.setState({ referralDiscount: 0 }, this.updateBilling);
			}
			return;
		}

		fetch(`${api.api}/payments/referral?code=${referralCode}`, {
			headers: getHeaders()
		  })
			.then(res => res.json())
			.then(res => {
				if (res.discount) {
					message.success('Discount applied.');
					return this.setState(
						{ referralDiscount: res.discount || 0 },
						this.updateBilling,
					);
				}
				message.error('Invalid referral code.');
				this.setState(
					{ referralError: true, referralDiscount: 0 },
					this.updateBilling,
				);
			});
	};

	handleQuartersChange = quarters => {
		const { selectedPans } = this.state;
		const pans = Object.keys(this.availablePans);
		this.setState(
			{
				selectedQuarters: quarters,
				selectedPans: selectedPans.filter(
					pan => pans.indexOf(pan) !== -1,
				),
			},
			this.updateBilling,
		);
	};

	get paymentEnable() {
		const {
			paymentMode,
			selectedPans,
			monthlyPay,
			selectedQuarters,
		} = this.state;
		return (
			paymentMode === 1 &&
			selectedQuarters.length > 0 &&
			selectedPans.length > 0 &&
			monthlyPay >= 100
		);
	}

	get availablePans() {
		const { data, selectedQuarters } = this.state;
		return pickBy(data, pan => {
			for (const qtr of selectedQuarters) {
				const [qt1, qt2] = qtr.split(' - ');
				const startDate = moment(qt1, 'MMM yy');
				const endDate = moment(qt2, 'MMM yy');

				if (pan.Invoice_Date.isBetween(startDate, endDate)) {
					return true;
				}
			}
		});
	}

	render() {
		const {
			selectedPans,
			isPaid,
			loading,
			quarters,
			selectedQuarters,
			paymentMode,
			totalPayable,
			response,
		} = this.state;
		const { workspace } = this.props;

		const { dataSource } = this.dataSource();

		const pans = Object.keys(this.availablePans);

		if (isEmpty(workspace) || isEmpty(workspace.Workspace_Name)) {
			return this.props.isInStep ? (
				<div>Please select a workspace in the Navbar.</div>
			) : (
				<FullPageMessage>Please select workspace</FullPageMessage>
			);
		}

		if (loading) {
			return this.props.isInStep ? (
				<div
					style={{
						width: '100%',
						minHeight: '40vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Spin />
				</div>
			) : (
				<Loading />
			);
		}

		if (response.code === 0) {
			return (
				<iframe
					title="hosted payments page"
					src={response.hostedpage.url}
					width="100%"
					style={{ minHeight: '90vh', border: 0 }}
				/>
			);
		}

		if (isPaid) {
			return (
				<Row style={{ marginTop: '80px' }}>
					<Col>You have already subscribed</Col>
				</Row>
			);
		}

		return (
			<Fragment>
				<Row style={{ marginTop: '80px' }}>
					<Col span={6} className="heading">
						Pan Numbers
					</Col>
					<Col span={6} offset={1} className="heading">
						Quarters
					</Col>
					<Col span={6} offset={1} className="heading">
						Payment Details
					</Col>
				</Row>
				<Row>
					<Col span={6}>
						<div
							id="panSelectContainer"
							style={{ position: 'relative' }}
						>
							<Select
								open
								size="large"
								mode="multiple"
								style={{ width: '100%', height: 0 }}
								placeholder="Please select Pan"
								defaultValue={selectedPans}
								value={selectedPans}
								onChange={this.onSelectChange}
								getPopupContainer={() =>
									document.getElementById(
										'panSelectContainer',
									) || document.body
								}
							>
								{pans.map(row => (
									<Option key={row} value={row}>
										{row}
									</Option>
								))}
							</Select>
						</div>
					</Col>
					<Col span={6} offset={1} style={{ position: 'relative' }}>
						<div
							id="quarterSelectContainer"
							style={{ position: 'relative' }}
						>
							<Select
								open
								size="large"
								mode="multiple"
								style={{ width: '100%', height: 0 }}
								placeholder="Please select Pan"
								defaultValue={selectedQuarters}
								onChange={this.handleQuartersChange}
								getPopupContainer={() =>
									document.getElementById(
										'quarterSelectContainer',
									) || document.body
								}
							>
								{quarters.map(row => (
									<Option key={row} value={row}>
										{row}
									</Option>
								))}
							</Select>
						</div>
					</Col>
					<Col span={7} offset={1} className="dataTable">
						<Table
							dataSource={dataSource}
							columns={columns}
							bordered
							showHeader={false}
							pagination={false}
						/>
						<div
							style={{
								textAlign: 'left',
								fontWeight: 600,
								marginTop: 5,
							}}
						>
							*TDS not to be deducted.
						</div>
					</Col>
				</Row>
				<Row style={{ marginTop: 17 }}>
					<Col span={3} className="heading-sm">
						{/* Mobile Number */}
					</Col>
					<Col span={9} offset={1} className="heading-sm">
						Referral Code
					</Col>
					<Col span={6} offset={1} className="heading-sm">
						Subscription Amount Payable
					</Col>
				</Row>
				<Row>
					<Col span={3}>
						{/* <InputNumber
              placeholder="Mobile Number"
              style={{ width: '100%' }}
            /> */}
					</Col>
					<Col span={9} offset={1}>
						<Input
							placeholder="Referral Code"
							style={{ width: '100%' }}
							onBlur={this.applyReferralCode}
							onChange={this.onChange('referralCode')}
						/>
					</Col>
					<Col span={6} offset={1}>
						<Input
							placeholder="Referral Code"
							style={{ width: '100%' }}
							value={totalPayable}
							disabled
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: 17 }}>
					<Col span={3}></Col>
					<Col span={9} offset={1}>
						<div className="description">
							<strong>Basic Services</strong>
							<div>Airline Invoice retrieval from websites</div>
							<div>
								Airline Invoice retrieval from corporate emails
							</div>
							<div>
								Document Management System for Airline Invoices
							</div>
							<div>
								Invoice Reading with OCR to extract relevant
								data
							</div>
							<div>
								4 Way Reconciliation to enable input credit
								<ul>
									<li>Air Tickets</li>
									<li>Airline Invoices</li>
									<li>GSTR 2A data</li>
									<li>Travel Agency Invoices</li>
								</ul>
							</div>
							<div>
								Standard Dashboard and pre defined Reports
							</div>
							<div>Auto Email followup for Airline Invoices</div>
						</div>
					</Col>
					<Col span={6} offset={1}>
						<Radio.Group
							style={{ textAlign: 'left' }}
							onChange={this.onChange('paymentMode')}
							value={paymentMode}
						>
							<Radio style={radioStyle} value={1}>
								Pay By Credit Card
							</Radio>
							<Radio style={radioStyle} value={2}>
								Pay By NEFT/RTGS
							</Radio>
						</Radio.Group>
						<br />
						<Button
							type="primary"
							size="large"
							disabled={!this.paymentEnable}
							onClick={this.paymentForm}
						>
							Pay Now
						</Button>
						{paymentMode === 2 && (
							<div
								style={{ marginTop: 12 }}
								className="bank-details"
							>
								<span
									style={{ marginTop: 10, marginBottom: 10 }}
								>
									BANK DETAILS:-
								</span>
								<div>Account Name –</div> SHOP ELECT PVT LTD{' '}
								<br />
								<div>Bank Name -</div> ICICI Bank Ltd <br />
								<div>
									Bank Account Number -
								</div> 188705001124 <br />
								<div>IFSC Code -</div> ICIC0001887 <br />
								<div>SWIFT Code -</div> ICICINBBCTS <br />
							</div>
						)}
					</Col>
				</Row>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	workspace: state.workspace,
});

export default connect(mapStateToProps)(PaymentsPage);
