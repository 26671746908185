import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';

const StepContainer = styled.div`
	min-width: 60%;
	max-width: 60%;
	min-height: 500px;
	max-height: auto;

	background-color: white;

	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

	border: 3px solid #00000099;
	border-radius: 5px;
`;
const StepHeader = styled.header`
	border-bottom: 3px solid #00000033;

	background-color: white;

	z-index: 100;
`;

const StepTitle = styled.h2`
	padding: 20px;
	margin: 0;

	font-weight: bold;
	text-align: left;
`;

const StepBody = styled.div`
	padding: 20px;
`;

const Form = styled.div`
	padding: 20px;
`;

const OnboardingStepWrapper = ({ title, children }) => {
	return (
		<StepContainer>
			<StepHeader>
				<StepTitle>{title}</StepTitle>
			</StepHeader>
			<StepBody>
				<Form>{children}</Form>
			</StepBody>
		</StepContainer>
	);
};

OnboardingStepWrapper.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.element.isRequired,
};

export default OnboardingStepWrapper;
