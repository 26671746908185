import React, { Component } from 'react';
import axios from 'axios';
import { cloneDeep } from 'lodash';

import { Form, Row, Col, Input, message } from 'antd';

import PropTypes from 'prop-types';

import Api from '../../../helpers/api';

import {
	AddButton,
	DeleteButton,
	StepFooter,
	rowStyle,
	validateGSTN,
} from '../common';

const api = Api();

const EntityGroup = ({
	entityDetails,
	uniqueKey,
	onAddGstinClick,
	onDeleteGstinClick,
	onDeleteEntityClick,
	getFieldDecorator,
	validateGSTN,
}) => {
	return (
		<Row
			key={uniqueKey}
			id={'entityInfo' + uniqueKey}
			type="flex"
			justify="start"
			align="middle"
			style={{ ...rowStyle, border: '1px dashed #eee', margin: '10px' }}
		>
			<Col span={24}>
				<Row style={{ ...rowStyle, padding: '10px' }}>
					<Col span={9} offset={1}>
						<Form.Item
							label="Entity Name"
							style={{ marginBottom: 0 }}
							hasFeedback
						>
							{getFieldDecorator(
								`entityInfo.${uniqueKey}.entityName`,
								{
									initialValue: entityDetails.entityName,
									rules: [
										{
											type: 'string',
											message:
												'Please enter a valid entity name',
										},
										{
											required: true,
											message:
												'Please enter a valid entity name',
										},
										{
											whitespace: true,
											message:
												'Please enter a valid entity name',
										},
									],
								},
							)(
								<Input
									id={`entityInfo${uniqueKey}.${entityDetails.entityName}`}
									placeholder="Entity Name"
								/>,
							)}
						</Form.Item>
					</Col>
					<Col span={9} offset={2}>
						<Form.Item
							label="Domain Name"
							style={{ marginBottom: 0 }}
							hasFeedback
						>
							{getFieldDecorator(
								`entityInfo.${uniqueKey}.domainName`,
								{
									initialValue: entityDetails.domainName,
									rules: [
										{
											type: 'string',
											message:
												'Please enter a valid domain name',
										},
										{
											required: true,
											message:
												'Please enter a valid domain name',
										},
										{
											whitespace: true,
											message:
												'Please enter a valid domain name',
										},
									],
								},
							)(
								<Input
									id={`entityInfo${uniqueKey}.${entityDetails.domainName}`}
									placeholder="Domain Name"
								/>,
							)}
						</Form.Item>
					</Col>
					<Col span={2} offset={1}>
						<DeleteButton
							uniqueKey={uniqueKey}
							style={{
								display: 'inline-block',
							}}
							onDelete={onDeleteEntityClick}
						/>
					</Col>
				</Row>
			</Col>
			<Col span={16}>
				{entityDetails.gstinNumbers.length !== 0 &&
					entityDetails.gstinNumbers.map((gstin, i) => {
						return (
							<Row
								key={i}
								type="flex"
								justify="start"
								align="bottom"
								style={{ padding: '10px' }}
							>
								<Col span={10} offset={2}>
									<Form.Item
										label="GSTIN Number"
										style={{ marginBottom: 0 }}
										hasFeedback
									>
										{getFieldDecorator(
											`entityInfo.${uniqueKey}.gstinNumbers.${i}`,
											{
												initialValue: gstin,
												rules: [
													{
														type: 'string',
														message:
															'Please enter a valid GSTIN Number',
													},
													{
														required: true,
														message:
															'Please enter a valid GSTIN Number',
													},
													{
														whitespace: true,
														message:
															'Please enter a valid GSTIN Number',
													},
													{
														validator: validateGSTN,
													},
												],
											},
										)(
											<Input
												id={`entityInfo${uniqueKey}.${gstin}`}
												placeholder="GSTIN Number"
											/>,
										)}
									</Form.Item>
								</Col>
								<Col span={2} style={{ margin: '10px' }}>
									<DeleteButton
										style={{ marginLeft: '0' }}
										uniqueKey={i}
										additionalFuncArgument={uniqueKey}
										onDelete={onDeleteGstinClick}
									/>
								</Col>
							</Row>
						);
					})}
			</Col>
			<Col span={2}>
				<AddButton onClick={onAddGstinClick(uniqueKey)} />
			</Col>
		</Row>
	);
};

class Step1 extends Component {
	constructor(props) {
		super(props);

		const { isInOnboarding = false, stepState = {} } = this.props;

		this.state = {
			isInOnboarding,
			isComplete: isInOnboarding ? stepState.isComplete : false,
			isSkipped: isInOnboarding ? stepState.isSkipped : false,
			isSkippable: isInOnboarding ? stepState.isSkippable : false,
			submittingForm: false,
			organizationName: '',
			entityInfo: [],
			isDataFetched: false,
		};
	}

	get entityInfo() {
		return this.state.entityInfo;
	}

	componentDidMount() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;

		if (!isInOnboarding || (isInOnboarding && stepState.isActive)) {
			this.fetchEntityInfo('Test-ID').then(entities => {
				if (entities.length) {
					entities.map(obj => {
						obj.domainName = obj.domainName
							? obj.domainName
							: this.props.domain;
						return obj;
					});
					this.setState(prevState => ({
						isDataFetched: true,
						organizationName: entities[0].organizationName,
						entityInfo: [...prevState.entityInfo, ...entities],
					}));
					this.props.form.validateFields();
				} else {
					this.addEntityGroup();
				}
			});
		}
	}

	componentDidUpdate() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;
		const { isDataFetched } = this.state;

		if (isInOnboarding && stepState.isActive && !isDataFetched) {
			this.fetchEntityInfo('Test-ID').then(entities => {
				if (entities.length) {
					entities.map(obj => {
						obj.domainName = obj.domainName
							? obj.domainName
							: this.props.domain;
						return obj;
					});
					this.setState(prevState => ({
						isDataFetched: true,
						organizationName: entities[0].organizationName,
						entityInfo: [...prevState.entityInfo, ...entities],
					}));
				} else {
					this.addEntityGroup();
				}
			});
		}
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.isInOnboarding && nextProps.stepState.isActive !== true) {
			return false;
		}

		return true;
	}

	fetchEntityInfo = async organizationId => {
		const apiUrl = `${api.api}/onboarding/organization-gstin?organizationId=${organizationId}`;
		const apiResponse = await axios.get(apiUrl);

		return apiResponse.data.data;
	};

	addEntityGroup = e => {
		const entityInfo = cloneDeep(this.entityInfo);

		this.setState({
			entityInfo: [
				...entityInfo,
				{
					entityName: '',
					gstinNumbers: [''],
					domainName: this.props.domain,
				},
			],
		});
	};

	deleteEntityGroup = index => e => {
		const entityInfo = cloneDeep(this.entityInfo);

		entityInfo.splice(index, 1);
		this.setState({
			entityInfo: [...entityInfo],
		});
	};

	addGstinElement = index => e => {
		const entityInfo = cloneDeep(this.entityInfo);

		entityInfo[index].gstinNumbers.push('');
		this.setState({
			entityInfo: [...entityInfo],
		});
	};

	deleteGstinElement = (entityGroupIndex, GstinInputIndex) => e => {
		const entityInfo = cloneDeep(this.entityInfo);

		entityInfo[entityGroupIndex].gstinNumbers.splice(GstinInputIndex, 1);
		this.setState({
			entityInfo: [...entityInfo],
		});
	};

	isEntityNameValid = entityName => {
		if (/^[a-zA-Z0-9_-\s.]*$/.test(entityName)) {
			return true;
		}
		return false;
	};

	validateGSTN = (rule, value, callback) => {
		const { isValid, message } = validateGSTN(value);
		if (!isValid) {
			callback(message);
		} else {
			callback();
		}
	};

	onSubmit = async e => {
		e.preventDefault();

		this.setState({ submittingForm: true });

		const { domain, workspace, email } = this.props;

		const organizationId = `Test-ID`;

		const apiUrl = `http://localhost:8000/onboarding/organization-gstin`;

		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
				axios
					.put(apiUrl, {
						organizationId: organizationId,
						organizationName: values.orgName,
						entityInfo: values.entityInfo,
						customerDomain: domain,
						customerWorkspace: workspace,
						lastUpdatedEmail: email,
					})
					.then(resp => {
						this.setState({
							submittingForm: false,
							isComplete: true,
						});
						message.success('Data successfully updated!');

						if (this.state.isInOnboarding) {
							this.props.onStepCompletion();
						}
					})
					.catch(err => console.log(err));
			} else {
				this.setState({ submittingForm: false });
			}
		});
	};

	render() {
		const {
			entityInfo,
			organizationName,
			submittingForm,
			isInOnboarding,
			isSkippable,
		} = this.state;
		const { onStepSkip } = this.props;

		const { getFieldDecorator } = this.props.form;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
			},
			wrapperCol: {
				xs: { span: 24 },
			},
		};

		return (
			<Form
				{...formItemLayout}
				layout="vertical"
				onSubmit={this.onSubmit}
			>
				<Row
					style={{
						...rowStyle,
						padding: '10px',
						border: '1px dashed #eee',
						margin: '10px',
					}}
				>
					<Col span={9} offset={1}>
						<Form.Item
							label="Organization Name"
							style={{ marginBottom: 0 }}
							hasFeedback
						>
							{getFieldDecorator('orgName', {
								initialValue: organizationName,
								rules: [
									{
										type: 'string',
										message:
											'Please enter a valid organization name',
									},
									{
										required: true,
										message:
											'Please enter a valid organization name',
									},
									{
										whitespace: true,
										message:
											'Please enter a valid organization name',
									},
								],
							})(
								<Input
									id="orgName"
									placeholder="Organization Name"
								/>,
							)}
						</Form.Item>
					</Col>
				</Row>
				{entityInfo.length !== 0 &&
					entityInfo.map((entity, i) => {
						return (
							<EntityGroup
								key={i}
								uniqueKey={i}
								entityDetails={entity}
								onAddGstinClick={this.addGstinElement}
								onDeleteGstinClick={this.deleteGstinElement}
								onDeleteEntityClick={this.deleteEntityGroup}
								getFieldDecorator={getFieldDecorator}
								validateGSTN={this.validateGSTN}
							/>
						);
					})}
				<Row
					type="flex"
					justify="start"
					align="middle"
					style={{ width: '100%' }}
				>
					<Col>
						<AddButton
							buttonText="Add Entity Group"
							onClick={this.addEntityGroup}
						/>
					</Col>
				</Row>
				<StepFooter
					isInOnboarding={isInOnboarding}
					isSkippable={isSkippable}
					isSubmittable={true}
					isLoading={submittingForm}
					onSkip={onStepSkip}
				/>
			</Form>
		);
	}
}

Step1.propTypes = {
	isInOnboarding: PropTypes.bool,
	stepState: PropTypes.object,
	onStepSkip: PropTypes.func,
	onStepCompletion: PropTypes.func,
	domain: PropTypes.string.isRequired,
	workspace: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};

export default Form.create({ name: 'organization-gstins' })(Step1);
