import { SET_CWT_ROLE } from '../actions';

export default function cwtReducer(state = '', action) {
	switch (action.type) {
		case SET_CWT_ROLE:
			return action.cwt_role;
		default:
			return state;
	}
}
