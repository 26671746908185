import React, { Component } from 'react';

import AccountsUIContainer from './containers/AccountsUIContainer';

export class AccountsUI extends Component {
	constructor(props) {
		super(props);

		console.log(this.props);
	}

	render() {
		console.log(this.props.workspace);
		return (
			<React.Fragment>
				<AccountsUIContainer {...this.props} />
			</React.Fragment>
		);
	}
}

export default AccountsUI;
