import axios from 'axios';
import { get } from 'lodash';
import api from '../helpers/api';
import Cookies from 'universal-cookie';

export const getCurrentWorkspace = async domain => {
	const cookies = new Cookies();
	try {
		const res = await axios.get(`${api().api}/workspace`, {
			headers: {
				'x-domain': domain,
				'ngrok-skip-browser-warning': 40, 
			},
		});
		cookies.set('workspace', get(res, 'data._source.Filter_Value', ''), { path: '/' });
		return res.data;
	} catch (err) {
		throw err;
	}
};
