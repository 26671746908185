import styled from 'styled-components';

import theme from '../../helpers/theme';

const Row = styled.div`
	display: flex;
	justify-content: ${props => (props.spaceBetween ? 'space-between' : '')};
	justify-content: ${props => (props.horizontallyCenter ? 'center' : '')};
	align-items: ${props => (props.center ? 'center' : '')};
	background-color: ${props => (props.heading ? theme.color.bg : '')};
	margin: ${props => (props.heading || props.spaced ? '1.5em 0' : '')};
	height: ${props => (props.full ? '100vh' : '')};
	width: ${props => (props.full ? '100%' : '')};
	max-width: ${props => props.maxWidth};

	div {
		border: ${props => props.table && '1px solid #ccc'};
		padding: ${props => props.table && '1rem'};
		font-weight: ${props => props.tableHeader && 'bold'};
		background-color: ${props => props.tableHeader && '#efefef'};
	}

	a {
		padding-right: ${props => (props.heading ? '10px' : '')};
	}
`;

export default Row;
