const theme = {
	color: {
		primary: '#47C51D',
		secondary: '#53C1DE',
		white: '#FFF',
		bg: '#F7F7F7',
		black: '#222',
		border: 'rgba(0,0,0,0.1)',
		base: 'rgba(0,0,0,0.4)',
		alert: '#FF4258',
		warning: 'coral',
		success: '#468847',
		info: '#58ade5',
		link: '#41bbe1',
		headings: '#333',
		text: '#555',
		muted: '#999',
		navbg: '#2d373c',
		separator: '#777',
		navhover: '#2FA3E6',
		navactive: '#1f282d',
	},
	icon: {
		color: 'gray',
		size: '15px',
	},
	font: {
		family: `
		Proxima Nova,
		Helvetica,
		Arial,
		sans-serif`,
		base: '1em',
		small: '11px',
		xsmall: '9px',
		large: '2em',
		xlarge: '30px',
		xxlarge: '50px',
	},
	headings: {
		family: 'Proxima Nova',
	},
	gutter: '2em',
	transition: '300ms ease-in-out',
};

export default theme;
