import {
	FETCH_CONTACTS_SUCCESS,
	CREATE_CONTACTS_SUCCESS,
	FETCH_CONTACTS_REQUEST,
	FETCH_CONTACTS_REQUEST_COMPLETED,
} from '../actions';

export default function contacts(state = {}, action) {
	switch (action.type) {
		case FETCH_CONTACTS_SUCCESS:
			return {
				contactList: action.contacts,
				isLoading: false,
			};
		case CREATE_CONTACTS_SUCCESS:
			return action.contacts;
		case FETCH_CONTACTS_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_CONTACTS_REQUEST_COMPLETED:
			return {
				...state,
				isLoading: false,
			};
		default:
			return state;
	}
}
