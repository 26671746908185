import styled from 'styled-components';
import theme from '../../helpers/theme';

const MainView = styled.section`
	h1 {
		border-bottom: 1px solid #ddd;
		padding-bottom: 1em;
	}
	padding: 0;
	width: 100%;
	padding-top: 73px;

	a {
		text-decoration: none;
		color: ${theme.color.black};
	}
`;

export default MainView;
