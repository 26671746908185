import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';

import Api from '../helpers/api';
import { newUI } from '../helpers/urls';
import exportData from '../services/Scroll.js';

import FaSync from 'react-icons/lib/fa/refresh';
import FaDownload from 'react-icons/lib/fa/download';
import PlusSquare from 'react-icons/lib/fa/plus-square';

import DropzoneComponent from './dropzoneComponent';

import PropTypes from 'prop-types';

import { Row, Col, Button } from 'antd';
import './style.css';

const api = Api();

class RightColumn extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isDropped: false,
			imagePreview: [],
			onSyncOpacity: 1,
		};
	}

	onDrop = (files, rejected) => {
		this.setState({
			isDropped: true,
			imagePreview: files,
		});
	};

	onSync = () => {
		let body = {
			client_list: [],
		};
		body.client_list.push(this.props.customer_domain);
		fetch(`${api.api}/file/pdfmerger`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: new Headers({
				'Content-Type': 'application/json',
				'ngrok-skip-browser-warning': 40
			}),
		})
			.then(res =>
				toast.success('Syncing has started successfully.', {
					position: toast.POSITION.TOP_RIGHT,
				}),
			)
			.catch(error => console.error('Error:', error));
	};

	onAddNewDoc = () => {
		const win = window.open(newUI, '_blank');
		win.focus();
	};

	onLogDownload = () => {
		const app = 'shopelect-logging-sol';
		const type = '';
		// TODO: use read-only credentials
		const credentials = 'BDczh09lP:49430f16-b32f-422f-93da-d80b0146acb7';
		const query = {
			query: {
				bool: {
					must: [
						{
							exists: {
								field: 'uploader_email.keyword',
							},
						},
						{
							term: {
								'customer_domain.keyword': this.props
									.customer_domain,
							},
						},
					],
				},
			},
		};
		exportData(app, type, credentials, query);
	};

	onSyncEmbedClick = e => {
		e.preventDefault();
		const { onSyncOpacity } = this.state;
		if (onSyncOpacity !== 1) {
			return;
		}

		this.setState({ onSyncOpacity: 0.6 });

		axios
			.get(`${api.api}/sync/views`)
			.then(res => {
				this.setState({ onSyncOpacity: 1 });
				alert('Sync successful');
			})
			.catch(err => {
				console.error(err);
				this.setState({ onSyncOpacity: 1 });
			});
	};

	onSubmit = () => {
		let { imagePreview } = this.state;
		let imageResponse = [];
		let errorResponse = false;
		for (let i = 0; i < imagePreview.length; i++) {
			let formData = new FormData();
			formData.append('file', imagePreview[i]);

			formData.append('airline_name', '');
			formData.append('docparser_id', '');
			formData.append('AirlineName', '');

			formData.append('operationType', 'Airlines Invoice');

			formData.append('customer_domain', this.props.customer_domain);
			formData.append('uploader_email', this.props.loggedin_user);
			formData.append(
				'customer_folder_id',
				this.props.workspace.workspaceFolderId,
			);
			this.props.setLoader(true);
			imageResponse.push(
				axios
					.post(`${api.api}/s3/upload`, formData)
					.then(response => {
						console.log('response: ', response);
						this.setState({ isDropped: false, imagePreview: [] });
					})
					// eslint-disable-next-line no-loop-func
					.catch(error => {
						console.log('error: ', error.response);
						if (error && error.response && error.response.data) {
							toast.error(error.response.data.message, {
								position: toast.POSITION.TOP_RIGHT,
							});
						} else {
							toast.error('A server error has occurred', {
								position: toast.POSITION.TOP_RIGHT,
							});
						}
						errorResponse = true;
					}),
			);
		}
		Promise.all(imageResponse).then(() => {
			this.props.setLoader(false);
			if (!errorResponse) {
				toast.success('File(s) uploaded successfully!', {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		});
	};

	render() {
		const { isDropped } = this.state;
		const { isAdmin } = this.props.user;

		return (
			<div
				style={{
					borderLeft: '1px solid #ddd',
					height: 'calc(100vh - 100px)'
				}}
			>
				<Row style={{ padding: '10px 20px' }}>
					{isAdmin && (
						<Col span={8}>
							<div
								onClick={this.onAddNewDoc}
								style={{
									cursor: 'pointer',
									textAlign: 'left',
									padding: '10px 20px',
									fontWeight: 'bold',
									fontSize: '16px',
								}}
								title="This adds a new document."
							>
								<span
									style={{
										border: '1px dashed #ccc',
										padding: '10px',
									}}
								>
									<PlusSquare style={{ fontSize: '22px' }} />
									&nbsp;
									<span style={{ fontWeight: 'normal' }}>
										Add New Doc
									</span>
								</span>
							</div>
						</Col>
					)}
					<Col span={isAdmin ? 8 : 12}>
						<div
							onClick={this.onLogDownload}
							style={{
								cursor: 'pointer',
								textAlign: isAdmin ? 'center' : 'left',
								padding: '10px 20px',
								fontWeight: 'bold',
								fontSize: '16px',
							}}
							title="This downloads a log of your last uploaded files."
						>
							<span
								style={{
									border: '1px dashed #ccc',
									padding: '10px',
								}}
							>
								<FaDownload style={{ fontSize: '22px' }} />
								&nbsp;
								<span style={{ fontWeight: 'normal' }}>
									Get File Log
								</span>
							</span>
						</div>
					</Col>
					<Col span={isAdmin ? 8 : 12}>
						<div
							onClick={this.onLogDownload}
							style={{
								cursor: 'pointer',
								textAlign: 'right',
								padding: '10px 20px',
								fontWeight: 'bold',
								fontSize: '16px',
							}}
							title="This will trigger an instant sync of files to the PDF engine. Should be used with caution."
						>
							<span
								style={{
									border: '1px dashed #ff6961',
									backgroundColor: '#ff696105',
									padding: '10px',
								}}
							>
								<span style={{ fontWeight: 'normal' }}>
									Sync Files
								</span>
								&nbsp;
								<FaSync style={{ fontSize: '22px' }} />
							</span>
						</div>
					</Col>
				</Row>
				<Row style={{ padding: '20px' }}>
					<div ref={divElement => (this.divElement = divElement)}>
						<span className="dropzone">
							<Dropzone
								onDrop={this.onDrop}
								disablePreview={false}
								multiple={true}
								style={{
									background: '#00000008',
									minHeight: '50vh',
									border: '5px dotted #c2c2c2',
									borderRadius: '0.5rem',
									margin: '0 20px',
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<DropzoneComponent
									imagePreview={this.state.imagePreview}
								/>
							</Dropzone>
						</span>
					</div>
				</Row>
				<Row type="flex" justify="end" style={{ padding: '20px 50px' }}>
					<Col span={6}>
						{isDropped ? (
							<Button
								type="primary"
								icon="upload"
								size="large"
								onClick={this.onSubmit}
								block
							>
								Submit
							</Button>
						) : (
							<Button
								type="primary"
								icon="upload"
								size="large"
								style={{ cursor: 'no-drop' }}
								disabled={!isDropped}
								block
							>
								Submit
							</Button>
						)}
					</Col>
				</Row>
			</div>
		);
	}
}

// Adding propTypes for type checking.
RightColumn.propTypes = {
	setLoader: PropTypes.func.isRequired,
	customer_domain: PropTypes.string.isRequired,
	loggedin_user: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	user: state.user,
	workspace: state.workspace,
});
export default connect(mapStateToProps)(RightColumn);
