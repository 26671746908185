import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Icon } from 'antd';
import styled from 'styled-components';

import ProgressBar from '../components/ProgressBar';

const Section = styled.section`
	background-color: #fff;
	border: 2px solid #ccc;
	border-bottom: 0;
	border-radius: 5px;
	margin-top: 40px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	transition: all 0.15s linear;

	&:hover {
		border: 2px solid dodgerblue;
		border-bottom: 0;
		box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
			0 15px 12px rgba(0, 0, 0, 0.22);
	}
`;

const SectionHeader = styled.header`
	width: 100%;
	padding: 25px 20px 20px 20px;
	font-weight: bold;
	cursor: pointer;
`;

const SectionTitle = styled.h2`
	text-align: left;
	margin: 0;
	font-size: 18px;
	font-weight: bold;
`;

const SectionSubTitle = styled.p`
	text-align: right;
	margin: 0;
	font-size: 14px;
	font-weight: bold;
`;

const SectionBody = styled.div`
	padding: 20px;
	max-height: 100000px;

	transition: max-height 0.15s linear, visibility 0.15s linear,
		opacity 0.15s linear, padding 5s linear;
`;

const SectionContainer = ({
	iconType,
	sectionTitle,
	stepsPending,
	estimatedTime,
	progress,
	isActive,
	isComplete = false,
	onSectionClick,
	children,
}) => {
	const [displaySectionBody, setSectionBodyVisibility] = useState(false);

	const wasActiveRef = useRef();
	useEffect(() => {
		wasActiveRef.current = isActive;
	});
	const wasActive = wasActiveRef.current;

	if (wasActive && !isActive) {
		setTimeout(() => setSectionBodyVisibility(false), 150);
	} else if (undefined && !isActive) {
		setTimeout(() => setSectionBodyVisibility(false), 0);
	} else if (isActive) {
		setTimeout(() => setSectionBodyVisibility(true), 0);
	}

	return (
		<Section>
			<span
				style={{
					postion: 'fixed',
					left: 0,
					right: 0,
					display: 'block',
				}}
			></span>
			<SectionHeader onClick={onSectionClick}>
				<Row
					type="flex"
					justify="center"
					align="middle"
					style={{ width: '100%' }}
				>
					<Col span={2}>
						<Icon
							style={{
								fontSize: '40px',
								color: 'dodgerblue',
								display: 'block !important',
								position: 'relative !important',
								width: '40px',
								height: '40px',
								zIndex: '-100',
							}}
							type={iconType}
						/>
						{isComplete && (
							<Icon
								type="check-circle"
								theme="filled"
								style={{
									fontSize: '20px',
									color: '#7d7',
									position: 'absolute',
									top: '30px',
									right: '10px',
									zIndex: '100',
									backgroundColor: '#fff',
									borderRadius: '50%',
									border: '2px solid #fff',
									visibility: isComplete
										? 'visible'
										: 'hidden',
									opacity: isComplete ? 100 : 0,
									transistion: 'opacity 0.75s ease-out',
								}}
							/>
						)}
					</Col>
					<Col span={11}>
						<SectionTitle>{sectionTitle}</SectionTitle>
					</Col>
					<Col span={11}>
						{isComplete ? (
							<p
								style={{
									margin: 0,
									color: '#7d7',
									textAlign: 'right',
									visibility: isComplete
										? 'visible'
										: 'hidden',
									opacity: isComplete ? 100 : 0,
									transistion: 'opacity 0.75s ease-out',
								}}
							>
								All steps have been completed
							</p>
						) : (
							<SectionSubTitle>
								{stepsPending}{' '}
								{stepsPending > 1 ? 'steps' : 'step'}{' '}
								pending:&nbsp;
								<span
									style={{
										fontSize: '12px',
										fontWeight: 'normal',
									}}
								>
									About {estimatedTime} minutes
								</span>
							</SectionSubTitle>
						)}
					</Col>
				</Row>
			</SectionHeader>
			<ProgressBar progress={progress} isVisible={true} />
			<SectionBody
				style={{
					maxHeight: isActive ? '100000px' : 0,
					padding: isActive ? '20px' : 0,
					visibility: isActive ? 'visible' : 'hidden',
					opacity: isActive ? '100%' : 0,
					// display: isActive ? 'block' : 'none',
					display: displaySectionBody ? 'block' : 'none',
				}}
			>
				{children}
			</SectionBody>
		</Section>
	);
};

export default SectionContainer;
