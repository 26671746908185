import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

const ProgressBarContainer = styled.div`
	height: 5px;
	margin: 0px;
	z-index: -10;
	transition: all 0.5s ease-in-out;
`;

const Progress = styled.div`
	height: 5px;
	background-color: #77dd77;
	transition: width 1s ease-in-out;
`;

const ProgressBar = ({ progress, isVisible = 'true' }) => {
	if (
		progress &&
		(_.isNumber(progress) || _.isNumber(parseInt(progress, 10)))
	) {
		if (progress < 0) {
			progress = 0;
		} else if (progress > 100) {
			progress = 100;
		}
	} else {
		progress = 0;
	}

	return (
		<ProgressBarContainer
			style={{
				visibility: isVisible ? 'visible' : 'hidden',
				opacity: isVisible ? '100%' : 0,
				backgroundColor: '#eee',
			}}
		>
			<Progress style={{ width: `${progress}%` }}></Progress>
		</ProgressBarContainer>
	);
};

export default ProgressBar;
