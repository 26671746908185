import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
const TabPane = Tabs.TabPane;
class GlobalDashboard extends Component {
	render() {
        const { views } = this.props;
        let data = views.views_response_success;
        if(data && data.dashboard) {
            data = data.dashboard;
        }
		return (
            <React.Fragment>
                {data && data.length > 0 &&
                    <Tabs
                        defaultActiveKey="0"
                        tabPosition="top"
                    >
                    {data.map((o, i) => (
                        <TabPane
                        tab={o['Dashboard Name']}
                        key={i}
                    >
                        <iframe
                            title={o['Dashboard Name']}
                            className="iframeD"
                            onContextMenu={() => {
                                return false;
                            }}
                            frameBorder="0"
                            width="100%"
                            height="600px"
                            src={
                                `${o.embedurl}`
                            }
                        />
                    </TabPane>
                    ))}
                    </Tabs>
                }
            </React.Fragment>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	views: state.views
});

export default connect(mapStateToProps, null)(GlobalDashboard);
