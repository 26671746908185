import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { PaymentsPage } from '../../PaymentsPage/PaymentsPage';

class Step11 extends Component {
	constructor(props) {
		super(props);

		const { isInOnboarding = false, stepState = {} } = this.props;

		this.state = {
			isInOnboarding,
			isComplete: isInOnboarding ? stepState.isComplete : false,
			isSkipped: isInOnboarding ? stepState.isSkipped : false,
			isSkippable: isInOnboarding ? stepState.isSkippable : false,
			submittingForm: false,
		};
	}

	onSubmit = () => {
		if (this.state.isInOnboarding) {
			this.props.onStepCompletion();
		}
	};

	render() {
		return (
			<React.Fragment>
				{this.props.isInOnboarding && this.props.stepState.isActive && (
					<PaymentsPage
						isInStep={true}
						customer_domain={this.props.domain}
						workspace={{ Workspace_Name: this.props.workspace }}
					/>
				)}
				{!this.state.isInOnboarding && (
					<PaymentsPage
						isInStep={true}
						customer_domain={this.props.domain}
						workspace={{ Workspace_Name: this.props.workspace }}
					/>
				)}
			</React.Fragment>
		);
	}
}

Step11.propTypes = {
	isInOnboarding: PropTypes.bool,
	stepState: PropTypes.object,
	onStepSkip: PropTypes.func,
	onStepCompletion: PropTypes.func,
	domain: PropTypes.string.isRequired,
	workspace: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};

export default Step11;
