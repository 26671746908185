const api = (staging = false) => {
	const protocol = window.location.protocol;
	if (process.env.NODE_ENV === 'development') {
		return {
			api: 'http://localhost:8000',
			callback: 'http://localhost:3000/callback',
		};
	}
	if (staging || window.location.origin.search('netlify.app') !== -1) {
		return {
			api: `${protocol}//staging.gstinput.com`,
			callback: `${window.location.origin}/callback`,
		};
	}
	if (
		window.location.origin.search('yatra.finkraft.ai') !== -1
	) {
		return {
			api: `${protocol}//yatramiddleware.finkraft.ai`,
			callback: `${window.location.origin}/callback`,
		};
	}
	return {
		api: `${protocol}//yatramiddleware.finkraft.ai`,
		callback: `${window.location.origin}/callback`,
	};
};

export default api;
