import { createStore, applyMiddleware, compose } from 'redux';
import { throttle, isEmpty } from 'lodash';
import thunk from 'redux-thunk';
import { getViews } from '../actions';
import rootReducer from '../reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function configureStore() {
	try {
		let state = JSON.parse(localStorage.getItem('__state__'));
		if (!isEmpty(state.customerDomain)) {
			setTimeout(() => {
				store.dispatch(getViews(state.customerDomain));
			}, 500);
		}
		return createStore(rootReducer, state, composeEnhancers(applyMiddleware(thunk)));
	} catch (err) {
		console.log(err);
		return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
	}
}

const store = configureStore();

store.subscribe(throttle(() => {
		const updatedState = JSON.stringify(store.getState());
		localStorage.setItem('__state__', updatedState);
}));
export default store;
