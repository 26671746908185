import React, { Component } from 'react';
import { Row, Col } from 'antd';

export class ImageCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        
        return (
            <div>
                <Row style={{backgroundColor:'#1edaeb'}} className="img-card">
                    <Col><img src={this.props.value.img_url} className="img-shape" /></Col>
                    <Col style={{color:'white'}} className="img-card-text">{this.props.value.title}</Col>
                </Row>
            </div>
        )
    }
}

export default ImageCard;
