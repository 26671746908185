import React, { Component } from 'react';
import axios from 'axios';

import { Form, Divider, Checkbox, message } from 'antd';

import PropTypes from 'prop-types';

import { PlaceholderTextBlock, StepFooter } from '../common';

import Api from '../../../helpers/api';

const api = Api();

const AuthorizationGroup = ({ isChecked, getFieldDecorator }) => {
	return (
		<React.Fragment>
			<Form.Item
				style={{
					padding: '50px 50px 20px 50px',
				}}
			>
				{getFieldDecorator('authorizationReceived', {
					valuePropName: 'checked',
					initialValue: isChecked,
				})(
					<Checkbox style={{ padding: '30px 0 50px 0' }}>
						<b>
							I authorize Gstinput to followup with my TMC to
							update our tool's Email Id in the SSR.
						</b>
					</Checkbox>,
				)}
			</Form.Item>
			<Divider>Disclaimer</Divider>
			<PlaceholderTextBlock>
				You are authorizing Gstinput to followup with your TMC to update
				their tool's email id in the SSR.
			</PlaceholderTextBlock>
		</React.Fragment>
	);
};

class Step6 extends Component {
	constructor(props) {
		super(props);

		const { isInOnboarding = false, stepState = {} } = this.props;

		this.state = {
			isInOnboarding,
			isComplete: isInOnboarding ? stepState.isComplete : false,
			isSkipped: isInOnboarding ? stepState.isSkipped : false,
			isSkippable: isInOnboarding ? stepState.isSkippable : false,
			submittingForm: false,
			authorizationReceived: false,
			isDataFetched: false,
		};
	}

	componentDidMount() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;

		if (!isInOnboarding || (isInOnboarding && stepState.isActive)) {
			this.fetchAuthorizationStatus('Test-ID').then(resp => {
				if (Object.keys(resp).includes('authorizationReceived')) {
					this.setState({
						isDataFetched: true,
						authorizationReceived: resp.authorizationReceived,
					});
				}
			});
		}
	}

	componentDidUpdate() {
		const {
			isInOnboarding = false,
			stepState = { isActive: false },
		} = this.props;
		const { isDataFetched } = this.state;

		if (isInOnboarding && stepState.isActive && !isDataFetched) {
			this.fetchAuthorizationStatus('Test-ID').then(resp => {
				if (Object.keys(resp).includes('authorizationReceived')) {
					this.setState({
						isDataFetched: true,
						authorizationReceived: resp.authorizationReceived,
					});
				}
			});
		}
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.isInOnboarding && nextProps.stepState.isActive !== true) {
			return false;
		}

		return true;
	}

	fetchAuthorizationStatus = async organizationId => {
		const apiUrl = `${api.api}/onboarding/ssr-authorization?organizationId=${organizationId}`;

		const apiResponse = await axios.get(apiUrl);

		return apiResponse.data.data;
	};

	onSubmit = e => {
		e.preventDefault();

		const { domain, workspace, email } = this.props;

		this.setState({ submittingForm: true });

		const apiUrl = `${api.api}/onboarding/ssr-authorization`;

		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
				axios
					.put(apiUrl, {
						organizationId: 'Test-ID',
						authorizationReceived: values.authorizationReceived,
						lastUpdatedEmail: email,
						customerDomain: domain,
						customerWorkspace: workspace,
					})
					.then(resp => {
						this.setState({ submittingForm: false });
						message.success('Data Successfully updated');

						if (this.state.isInOnboarding) {
							this.props.onStepCompletion();
						}
					})
					.catch(err => {
						console.log(err);
					});
			} else {
				this.setState({ submittingForm: false });
			}
		});
	};

	render() {
		const {
			authorizationReceived,
			submittingForm,
			isInOnboarding,
			isSkippable,
		} = this.state;
		const { onStepSkip } = this.props;

		const { getFieldDecorator } = this.props.form;

		return (
			<Form onSubmit={this.onSubmit}>
				<AuthorizationGroup
					isChecked={authorizationReceived}
					getFieldDecorator={getFieldDecorator}
				/>
				<StepFooter
					isInOnboarding={isInOnboarding}
					isSkippable={isSkippable}
					isSubmittable={true}
					isLoading={submittingForm}
					onSkip={onStepSkip}
				/>
			</Form>
		);
	}
}

Step6.propTypes = {
	isInOnboarding: PropTypes.bool,
	stepState: PropTypes.object,
	onStepSkip: PropTypes.func,
	onStepCompletion: PropTypes.func,
	domain: PropTypes.string.isRequired,
	workspace: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};

export default Form.create({ name: 'ssr-update' })(Step6);
