import React from 'react';

import styled from 'styled-components';

import PasswordReset from '../components/PasswordReset';
// import Step1 from '../components/Step1';
// import Step2 from '../components/Step2';
// import Step3 from '../components/Step3';
// import Step4 from '../components/Step4';
// import Step5 from '../components/Step5';
// import Step6 from '../components/Step6';
// import Step7 from '../components/Step7';
// import Step8a from '../components/Step8a';
// import Step8b from '../components/Step8b';
// import Step9 from '../components/Step9';
// import Step10 from '../components/Step10';
// import Step11 from '../components/Step11';
// import Step12 from '../components/Step12';
// import Step13 from '../components/Step13';
// import Step14 from '../components/Step14';
// import Step15 from '../components/Step15';
// import Step16 from '../components/Step16';

import AccountsUIStepWrapper from './AccountsUIStepWrapper';

const Main = styled.section`
	min-height: 100vh;
	max-height: 100%;
	width: 100%;

	padding: 20px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: row nowrap;

	background-color: #eee;
`;

export const AccountsUIStepsContainer = ({ currentStep = 1, ...rest }) => {
	return (
		<Main>
			{currentStep === 1 && (
				<AccountsUIStepWrapper title="Password Reset">
					<PasswordReset {...rest} />
				</AccountsUIStepWrapper>
			)}
			{/* {currentStep === 1 && (
				<AccountsUIStepWrapper title="Organization GSTINs">
					<Step1 {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === 2 && (
				<AccountsUIStepWrapper title="Booking Data">
					<Step2 {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === 3 && (
				<AccountsUIStepWrapper title="GSTR2A">
					<Step3 {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === 4 && (
				<AccountsUIStepWrapper title="Claimed Credit">
					<Step4 {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === 5 && (
				<AccountsUIStepWrapper title="International Invoices PST">
					<Step5 {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === 6 && (
				<AccountsUIStepWrapper title="SSR Authorization">
					<Step6 {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === 7 && (
				<AccountsUIStepWrapper title="TMC PoC Info">
					<Step7 {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === '8a' && (
				<AccountsUIStepWrapper title="Airline Email">
					<Step8a {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === '8b' && (
				<AccountsUIStepWrapper title="Airline Contact">
					<Step8b {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === 9 && (
				<AccountsUIStepWrapper title="Air India Login">
					<Step9 {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === 10 && (
				<AccountsUIStepWrapper title="Payment Contact">
					<Step10 {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === 11 && (
				<AccountsUIStepWrapper title="Payments UI">
					<Step11 {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === 12 && (
				<AccountsUIStepWrapper title="Add Team">
					<Step12 {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === 13 && (
				<AccountsUIStepWrapper title="SPOC and Escalations">
					<Step13 {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === 14 && (
				<AccountsUIStepWrapper title="TMC SPOC Details">
					<Step14 {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === 15 && (
				<AccountsUIStepWrapper title="Travel Team Details">
					<Step15 {...rest} />
				</AccountsUIStepWrapper>
			)}
			{currentStep === 16 && (
				<AccountsUIStepWrapper title="Tax Contact Details">
					<Step16 {...rest} />
				</AccountsUIStepWrapper>
			)} */}
		</Main>
	);
};
