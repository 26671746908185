import React from 'react';
import { connect } from 'react-redux';

import { setContactId } from '../actions';
import Login from '../components/UIv2/login/Index.js?v3';

const LoginContainer = ({ dispatch }) => (
	<Login onSuccess={contactId => dispatch(setContactId(contactId))} />
);

export default connect()(LoginContainer);
