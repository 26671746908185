import React from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-spinkit';

import MyAccount from '../components/MyAccount.js?v3';
import Row from '../components/styled/Row';
import { fetchUserInfo } from '../actions';

const mapStateToProps = state => ({
	user: state.user,
});

const mapDispatchToProps = dispatch => ({
	fetchUserInfo: contactId => dispatch(fetchUserInfo(contactId)),
});

const HomeContainer = ({ user, fetchUserInfo }) => {
	if (!user.contact_name) {
		return (
			<Row full center horizontallyCenter>
				<Spinner name="three-bounce" />
			</Row>
		);
	}

	return (
		<MyAccount
			contactName={user.contact_name}
			user={user.contact_persons[0]}
			gst={user.gst_no}
			billingAddress={user.billing_address}
			shippingAddress={user.shipping_address}
			website={user.website}
			gstTreatment={user.gst_treatment}
			extra={user}
			fetchUserInfo={fetchUserInfo}
		/>
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(HomeContainer);
