import { combineReducers } from 'redux';
import purchaseOrder from './purchaseOrder';
import purchaseorders from './purchaseorders';
import user from './user';
import contacts from './contacts';
import contactList from './contactList';
import reports from './reporting';
import doctype from './doctype';
import customerDomain from './customer-domain';
import workspace from './workspace';
import aggregations from './aggregations';
import cwt_role from './cwt_role';
import authorization from './authorization';
import views from './views'

const rootReducer = combineReducers({
	purchaseOrder,
	purchaseorders,
	user,
	contacts,
	contactList,
	reports,
	doctype,
	customerDomain,
	workspace,
	aggregations,
	cwt_role,
	authorization,
	views
});

export default rootReducer;
