import React, { Component } from 'react';
import { Carousel } from 'antd';
import { RegistrationDetail } from './RegistrationDetail';

export class CarouselView extends Component {
    constructor(props) {
        super(props);
    }

    getCarouselChange(fromIndex, toIndex) {
        this.props.CarouselPosition(toIndex);
    }

    render() {
        return (
            <div>
                <Carousel vertical autoplay dots={true} beforeChange={this.getCarouselChange.bind(this)}>
                    <RegistrationDetail screenFlag={true} />
                    <RegistrationDetail screenFlag={false} />
                    <RegistrationDetail screenFlag={true} />
                    <RegistrationDetail screenFlag={false} />
                </Carousel>
            </div>

        )
    }
}

export default CarouselView;
