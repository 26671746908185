import styled from 'styled-components';

import theme from '../../helpers/theme';

const MainContainer = styled.nav`
	display: flex;
	background-color: ${theme.color.navbg};
	color: #fafafa;
	flex-basis: 220px;
	z-index: 1;

	h4,
	h5 {
		margin: 0.2em;
	}

	h5 {
		font-family: Proxima Nova Light;
	}

	a {
		text-decoration: none;
		color: ${props =>
			props.active ? theme.color.navhover : theme.color.bg};

		&:visited {
			color: ${props =>
				props.active ? theme.color.navhover : theme.color.bg};
		}
	}
`;

export default MainContainer;
