import React, { Component } from 'react';
import { Row, Col, Upload, Icon, Modal } from 'antd';

import PropTypes from 'prop-types';

import {
	PlaceholderTextBlock,
	DownloadFormatButton,
	StepFooter,
} from '../common';

import Api from '../../../helpers/api';

import hmprDataFormat from '../../../assets/yatra-hmpr-format.xlsx';

const { Dragger } = Upload;

const api = Api();

const props = {
	name: 'doc',
	accept: '.xls, .xlsx, .xlsb, .csv',
	multiple: false,
	action: `${api.api}/yatra/hmpr-data`,
};

class HmprUpload extends Component {
	constructor(props) {
		super(props);

		const { isInOnboarding = false, stepState = {} } = this.props;

		this.state = {
			isInOnboarding,
			isComplete: isInOnboarding ? stepState.isComplete : false,
			isSkipped: isInOnboarding ? stepState.isSkipped : false,
			isSkippable: isInOnboarding ? stepState.isSkippable : false,
			submittingForm: false,
		};
	}

	onChange = info => {
		const status = info.file.status;

		if (status === 'uploading') {
			Modal.destroyAll();
			Modal.info({
				title: 'Please wait while we upload and process your data.',
				maskClosable: false,
				centered: true,
				keyboard: false,
				okButtonProps: { disabled: true },
				onOk() {},
			});
		}
		if (status === 'done') {
			Modal.destroyAll();
			Modal.success({
				title: 'Your data was successfully uploaded.',
				maskClosable: false,
				centered: true,
				keyboard: false,
				content: (
					<div>
						<p>
							The contents of your file have been uploaded
							successfully into our system.
						</p>
					</div>
				),
				onOk() {},
			});

			if (this.state.isInOnboarding) {
				this.props.onStepCompletion();
			}
		} else if (status === 'error') {
			Modal.destroyAll();
			Modal.error({
				title: 'Your data could not be uploaded/processed.',
				maskClosable: false,
				centered: true,
				keyboard: false,
				content: (
					<div>
						<p>
							Please feel free to contact us at info@gstinput.com
						</p>
					</div>
				),
				onOk() {},
			});
		}
	};

	render() {
		const { isInOnboarding, isSkippable } = this.state;
		const { onStepSkip, email } = this.props;

		return (
			<React.Fragment>
				<PlaceholderTextBlock>
					Please upload your HMPR data below.
				</PlaceholderTextBlock>
				<DownloadFormatButton
					link={hmprDataFormat}
					fileName="hmpr_data_format.xlsx"
					btnText="Download the HMPR Data Format"
				/>
				<Row
					type="flex"
					justify="center"
					align="middle"
					style={{ width: '100%', padding: '20px' }}
				>
					<Col span={22}>
						<Dragger
							{...props}
							data={{
								email,
							}}
							onChange={this.onChange}
							style={{
								width: '100% !important',
							}}
						>
							<p className="ant-upload-drag-icon">
								<Icon type="inbox" />
							</p>
							<p className="ant-upload-text">
								Click or drag file to this area to upload
							</p>
						</Dragger>
					</Col>
				</Row>
				<StepFooter
					isInOnboarding={isInOnboarding}
					isSkippable={isSkippable}
					onSkip={onStepSkip}
				/>
			</React.Fragment>
		);
	}
}

HmprUpload.propTypes = {
	isInOnboarding: PropTypes.bool,
	stepState: PropTypes.object,
	onStepSkip: PropTypes.func,
	onStepCompletion: PropTypes.func,
	domain: PropTypes.string.isRequired,
	workspace: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};

export default HmprUpload;
