import React, { useState } from 'react';
import { Layout, Row, Col } from 'antd';

import { AccountsUIMenuContainer } from './AccountsUIMenuContainer';
import { AccountsUIStepsContainer } from './AccountsUIStepsContainer';

import logo from '../../../public/images/logo.png';
import aloneLogo from '../../../public/images/gst-input-logo-alone.png';

const { Sider, Content } = Layout;

const AccountsUIContainer = props => {
	const [collapsed, onCollapse] = useState(false);
	const [currentStep, updateCurrentStep] = useState(1);
	const [openKeys, subMenuClick] = useState(['']);

	const onSubMenuClick = subMenuId => e => {
		subMenuClick([subMenuId]);
	};

	const onMenuItemClick = stepId => e => {
		updateCurrentStep(stepId);
	};

	return (
		<Layout
			id="OnboardingLayout" // ! Do not remove. Essential for overriding the global style definition for Sub menu item in app.css
			style={{ width: '100vw', minHeight: '100vh' }}
		>
			<Sider
				width={256}
				theme="light"
				collapsible
				collapsed={collapsed}
				onCollapse={onCollapse}
			>
				<img
					src={!collapsed ? logo : aloneLogo}
					alt="logo.png"
					style={{
						backgroundColor: '#ddd9',
						margin: '5px',
						padding: 0,
						width: '90%',
					}}
				/>
				<AccountsUIMenuContainer
					openKeys={openKeys}
					onSubMenuClick={onSubMenuClick}
					onMenuItemClick={onMenuItemClick}
				/>
			</Sider>
			<Layout>
				<Content>
					<Row style={{ width: '100%' }}>
						<Col span={24}>
							<AccountsUIStepsContainer
								currentStep={currentStep}
								{...props}
							/>
						</Col>
					</Row>
				</Content>
			</Layout>
		</Layout>
	);
};

export default AccountsUIContainer;
