import {
	FETCH_PURCHASE_ORDER_SUCCESS,
	UPDATE_PURCHASE_ORDER,
	RESET_PURCHASE_ORDER,
} from '../actions';

export default function purchaseOrder(state = {}, action) {
	switch (action.type) {
		case FETCH_PURCHASE_ORDER_SUCCESS:
			return action.purchaseOrder.purchaseorder;
		case RESET_PURCHASE_ORDER:
			return {};
		case UPDATE_PURCHASE_ORDER:
			return action.purchaseorder;
		default:
			return state;
	}
}
