import React, { Component } from 'react';
import { Icon, Spin } from 'antd';
import axios from 'axios';
import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import { IntlProvider } from 'react-intl';
import { ContentPreview } from 'box-ui-elements';
import Cookies from 'universal-cookie';
import 'box-ui-elements/dist/preview.css';
import { message } from 'antd';

import Flex from '../styled/Flex';
import api from '../../helpers/api';
var Router = require("react-router");

const _api = api();
const cookies = new Cookies();

addLocaleData(enLocaleData);

export const getHeaders = () => {
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'ngrok-skip-browser-warning': 40, 
	};
	return headers;
};

class ExportRedirect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFetchingToken: false,
			token: null,
			error: null
		};

		// fetch(`${_api.api}/reports/export/${this.props.match.params.workspace}/${this.props.match.params.table}?CLAUSE= WHERE Workspace = '${this.props.workspace}'&EMAIL=${this.props.loggedin_user}`)

		// this.fetchToken = this.fetchToken.bind(this);
	}

	componentDidMount() {
		// this.fetchToken();
		if(this.props.match.params.workspace && this.props.match.params.table && cookies.get('workspace') && this.props.loggedin_user) {
			fetch(`${_api.api}/reports/export/${this.props.match.params.workspace}/${this.props.match.params.table}?CLAUSE= WHERE Workspace = '${cookies.get('workspace')}'&EMAIL=${this.props.loggedin_user}`, {
				headers: getHeaders()
			  })
			.then((res) => {

				message.success(`An Email will be sent once the file is ready. Please check your inbox`,1);
				// cookies.set('emailGen', true, { path: '/' });
				// window.open("about:blank", "_self");
				// window.close();
			})
		}
	}

	componentWillReceiveProps(){

	}

	componentWillUpdate() {

		// if (prevProps.customerDomain !== this.props.customerDomain) {
		// 	// this.fetchToken();
		// }
	}

	componentDidUpdate(prevProps) {
		console.log(prevProps)
		// if (prevProps.customerDomain !== this.props.customerDomain) {
		// 	// this.fetchToken();
		// }
	}

	render() {

		return (
			<div>
				<p>redirect callback...</p>
			</div>
		);
	}
}

export default ExportRedirect;
